import React, { useEffect, useState } from "react";
import { Form, Row, Col, FloatingLabel, Button } from "react-bootstrap";
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import useOrder from "../../../context/OrderContext";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useGetAccountListQuery } from "../../../features/account/accountListSlice";
import CountryProvince from "../../CountryProvince";
import phoneNumberMasking from "../../Customer/PhoneNumberMasking";
import postalCodeMasking from "../../Customer/PostalCodeMasking";
import PersionalInfoNotes from "./PersionalInfoNotes";
import { parse, parseISO, format } from 'date-fns';
import timeConverter from "./timeConverter";
import { TimePicker } from '@mui/x-date-pickers';


const Personalinfo = () => {
  const { accountInfo, setAccountInfo, Accounthandler, selected, fetchAccountInfo, accountInfoData, fetchServiceData, fetchVehicleData, errors, fetchAccessorial } = useOrder();
  const { country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity } = CountryProvince()
  const { data: accountdata } = useGetAccountListQuery();
  const accountList = accountdata?.data
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [postal, setPostal] = useState("")

  const getAccountInfo = async (accountInfo) => {
    fetchAccountInfo(accountInfo)
    fetchVehicleData(accountInfo)
    fetchAccessorial(accountInfo)
  }
  const handleOnSelect = async (item) => {
    const accountInfo = item?.account_No;
    const selectedAccount = await getAccountInfo(accountInfo)
    setAccountInfo(selectedAccount)
  };

  const handleOnClear = () => {
    setAccountInfo("")
  };

  const timeConverter = (time) => {
    if (!time) return "";
    let parsedTime;
  
    // Check if the time is in ISO format
    if (time.includes("T")) {
      parsedTime = parseISO(time); // Parse the ISO time string
    } else {
      // Parse the custom date-time format
      parsedTime = parse(time, 'yyyy-MM-dd hh:mm:ss a', new Date());
    }
    // Check if the parsed time is valid
    if (isNaN(parsedTime)) {
      return "";
    }

    // Format to 'hh:mm a' (AM/PM format)
    return format(parsedTime, 'hh:mm a');
  };

  const formatResult = (item) => {
    return (
      <div className="result-wrapper">
        <span className="result-span">{item.account_No} | </span>
        <span className="result-span">{item.customer}</span>
      </div>
    );
  };
  useEffect(() => {
    if (accountInfoData?.countryId) {
      let target = accountInfoData?.countryId
      handlecountry({ target: { value: target } })
    }
    if (accountInfoData?.provineStateID) {
      let target = accountInfoData?.provineStateID
      handleCity({ target: { value: target } })
    }
  }, [accountInfoData])



  useEffect(() => {
    // setPhoneNumber(accountInfoData?.phone)
    setContactNumber(accountInfoData?.contact)
    // setPostal(accountInfoData?.postalCode)
  }, [accountInfoData])


  const handleInput = e => {
    const formattedPhoneNumber = phoneNumberMasking(e.target.value);
    const formattedPostalCode = postalCodeMasking(e.target.value)
    if (e.target.name === "phone") {
      setPhoneNumber(formattedPhoneNumber);
    }
    else if (e.target.name === "contact") {
      setContactNumber(formattedPhoneNumber)
    }
    else if (e.target.name === "postalCode") {
      setPostal(formattedPostalCode)
    }
  };

  return (

    <div>
      <>
        <Row className="mb-3">
          <Col sm={4}>
            {/* <AccountInfoData/> */}
            <div className="account-list">
              <div className="form-floating">
                <ReactSearchAutocomplete
                  className="form-control11" placeholder={accountInfo?.account_No ? accountInfo?.account_No : "Select"} items={accountList} fuseOptions={{ keys: ["account_No", "customer"] }} // Search on both fields
                  resultStringKeyName="account_No" /*String to display in the results*/ onSelect={handleOnSelect} onFocus={formatResult} onClear={handleOnClear}
                  onInputChange={handleOnClear} onSearch={handleOnClear} formatResult={formatResult} showItemsOnFocus={formatResult} inputDebounce={100} showIcon={false} value={accountInfo}
                  styling={{
                    height: "48px",
                    border: "1px solid #cbcbcb",
                    borderRadius: "4px",
                    backgroundColor: "white",
                    boxShadow: "none",
                    hoverBackgroundColor: "#cbcbcb",
                    iconColor: "#000",
                    lineColor: "#cbcbcb",
                    placeholderColor: "#000",
                    clearIconMargin: "3px 8px 0 0",
                    zIndex: 2,
                  }}
                />
                <label htmlFor="contact">Account No./ Customer</label>
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="st">
              <FloatingLabel controlId="floatingInput" label="Customer" >
                <Form.Control type="text" placeholder="Customer" name="customer" value={accountInfo?.customer ? accountInfo?.customer : ""} onChange={(e) => { Accounthandler(e); }}
                  className={`input ${errors.customer && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {errors.customer && (<p className="help is-danger">{errors.customer}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="st">
              <FloatingLabel controlId="floatingInput" label="Street" >
                <Form.Control type="text" placeholder="Street No." name="street" value={accountInfo?.street ? accountInfo?.street : ""} onChange={(e) => { Accounthandler(e); }} className={`input ${errors.street && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {errors.street && (<p className="help is-danger">{errors.street}</p>)}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4}>
            <Form.Group controlId="Street">
              <FloatingLabel controlId="floatingInput" label="Street Name" >
                <Form.Control type="text" autoComplete="off" placeholder="Street Name" name="streetName" onChange={(e) => { Accounthandler(e); }} value={(accountInfo?.streetName && accountInfo?.streetName !== "null") ? accountInfo?.streetName : ""} className={`input ${errors.streetName && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {errors.streetName && (<p className="help is-danger">{errors.streetName}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="Unit">
              <FloatingLabel controlId="floatingInput" label="Unit" >
                <Form.Control type="text" autoComplete="off" placeholder="Unit" name="unit" onChange={(e) => { Accounthandler(e); }} value={(accountInfo?.unit && accountInfo?.unit !== "null") ? accountInfo?.unit : ""} className={`input ${errors.unit && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {/* {errors.unit && (<p className="help is-danger">{errors.unit}</p>)} */}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="city">
              <FloatingLabel controlId="floatingInput" label="City" >
                <Form.Select aria-label="Floating label select example" name="city" onChange={e => { Accounthandler(e); setSelectCity(e.target.value) }} value={accountInfo?.city || "Select"} className={`input ${errors.city && 'is-danger'}`}>
                  <option>{accountInfo?.cityName || "Select"}</option>:
                  <>
                    {
                      city?.data.map((data, index) => (
                        <option key={index} >{data.city}</option>
                      ))

                    }
                  </>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            {errors.city && (<p className="help is-danger">{errors.city}</p>)}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4}>
            <Form.Group controlId="pr">
              <FloatingLabel controlId="floatingInput" label="Province" >
                <Form.Select aria-label="Floating label select example" name="provineStateID" onChange={(e) => { setSelectState(e.target.value); Accounthandler(e); handleCity(e) }} value={accountInfo?.provineStateID || "Select"} className={`input ${errors.provineStateID && 'is-danger'}`}>
                  <option>{accountInfo?.provineSateName || "Select"}</option>:
                  <>
                    {
                      province?.data?.map((data, index) => (
                        <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                      ))

                    }
                  </>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            {errors.provineStateID && (<p className="help is-danger">{errors.provineStateID}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="Country">
              <FloatingLabel controlId="floatingInput" label="Country" >
                <Form.Select aria-label="Floating label select example" name="countryId" onChange={e => { handlecountry(e); Accounthandler(e); }} value={accountInfo?.countryId || "Select"} className={`input ${errors.countryId && 'is-danger'}`}>
                  <option>Select</option>:
                  <>
                    {
                      country?.data.map((data, index) => (
                        <option key={index} value={data?.countryId}>{data?.countryname}</option>
                      ))

                    }
                  </>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            {errors.countryId && (<p className="help is-danger">{errors.countryId}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="postal/zip">
              <FloatingLabel controlId="floatingInput" label="Postal Code" >
                <Form.Control type="text" autoComplete="off" placeholder="Postal code" name="postalCode" onChange={(e) => { Accounthandler(e); handleInput(e) }} value={accountInfo?.postalCode ? accountInfo?.postalCode : ""} className={`input ${errors.postalCode && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {errors.postalCode && (<p className="help is-danger">{errors.postalCode}</p>)}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4}>
            <Form.Group controlId="Contact">
              <FloatingLabel controlId="floatingInput" label="Contact" >
                <Form.Control type="text" autoComplete="off" placeholder="Contact" name="contact" value={accountInfo?.contact || ""} onChange={e => { Accounthandler(e) }} className={`input ${errors.contact && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {errors.contact && (<p className="help is-danger">{errors.contact}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="phone">
              <FloatingLabel controlId="floatingInput" label="Phone" >
                <Form.Control type="text" autoComplete="off" placeholder="Phone" name="phone" value={accountInfo?.phone ? accountInfo?.phone : ""} onChange={e => { Accounthandler(e); handleInput(e) }} className={`input ${errors.phone && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {errors.phone && (<p className="help is-danger">{errors.phone}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="ext.">
              <FloatingLabel controlId="floatingInput" label="Phone Extension" >
                <Form.Control type="text" autoComplete="off" placeholder="Phone Extension" name="phone_Ext" value={accountInfo?.phone_Ext || ""} onChange={e => { Accounthandler(e); }} className={`input ${errors.phone_Ext && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {errors.phone_Ext && (<p className="help is-danger">{errors.phone_Ext}</p>)}
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Form.Group controlId="email">
              <FloatingLabel controlId="floatingInput" label="Email" >
                <Form.Control type="text" autoComplete="off" placeholder="Email" name="emailAddress" onChange={(e) => { Accounthandler(e); }} value={accountInfo?.emailAddress || ""} className={`input ${errors.emailAddress && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {errors.emailAddress && (<p className="help is-danger">{errors.emailAddress}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="Open Time">
              <div className="timepicker">
                  <TimePickerComponent id="time1" placeholder="Open time" onChange={e => Accounthandler(e)} name="open" value={timeConverter(accountInfo?.open) || ""} className={`input ${errors.closeTime && 'is-danger'}`} format={'hh:mm a'} />
              </div>
            </Form.Group>
            {errors.open && (<p className="help is-danger">{errors.open}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="Close Time">
                <div className="timepicker">
                  <TimePickerComponent id="time" placeholder="Close time" onChange={e => Accounthandler(e)} name="closeTime" value={timeConverter(accountInfo?.closeTime) || ""} className={`input ${errors.closeTime && 'is-danger'}`} format={'hh:mm a'} />
                </div>
            </Form.Group>
            {errors.closeTime && (<p className="help is-danger">{errors.closeTime}</p>)}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={4}></Col><Col sm={4}></Col>
          <Col sm={4}>
            <div className="populate-btn d-flex">
              {/* <Button className="populate-account" onClick={e => { populateAccount(e) }} disabled={pickDisable} value="populatepick">Populate account <ArrowRightAlt /></Button> */}
              {/* <Button className="populate-account btn-secondary" value="pickdisable" onClick={e => { reloadHandler(e) }}>Restore Data<CachedIcon /></Button> */}
              <Button className={`${accountInfo && accountInfo.locationNotes !== "null" ? 'notes-data' : 'notes'}`}><PersionalInfoNotes /></Button>
            </div>
          </Col>
        </Row>
      </>
    </div>

  );
}
export default Personalinfo;