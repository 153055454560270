import { useState, useEffect } from "react";
import { Button, Col, Row, Form, FloatingLabel } from "react-bootstrap";
import { DataGrid } from '@mui/x-data-grid';
import useCustomer from "../../context/CustomerContext";
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { Typeahead } from "react-bootstrap-typeahead";
import CountryProvince from "../CountryProvince";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Edit, Delete } from "@material-ui/icons";
import { useDeleteSpecialAddressMutation, useEditSpecialAddressMutation } from "../../features/customer/customerSlice";

const Specialaddress = ()=>{
    const { addSpecialAddress, specialAddress, setSpecialAddress, vehicleSurchargeList, serviceSurchargeList, specialAddressList, singleAccount, handleAccount } = useCustomer();
    const { city, handleProvince, newHandleCountry, handleUniqueCity } = CountryProvince();
    const [deleteSpecialAddress] = useDeleteSpecialAddressMutation();
    const [editSpecialAddress] = useEditSpecialAddressMutation();
    const cityList = city?.data.map((a) => { return {name: a.city, id: a.id}});
    const toCityList = cityList;
    const fromCityList = cityList;
    const [toSelection, setToSelection] = useState([]);
    const [fromSelection, setFromSelection] = useState([]);
    const [toProvince, setToProvince] = useState([]);
    const [fromProvince, setFromProvince] = useState([]);
    const [toCountryList, setToCountryList] = useState([]);
    const [fromCountryList, setFromCountryList] = useState([]); 
    const { control, register } = useForm();
    const [fuelSurcharge, setFuelSurcharge] = useState(false);
    const [selectedData, setSelectedData] = useState(null)
    const [service, setService] = useState([]);
    const account= singleAccount && singleAccount[0];
    const [open, setOpen] = useState(false);
    const handleClose = async () => { setOpen(false) };
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
      if (selectedData) {
        setSpecialAddress({...specialAddress, vehicleType: selectedData.value});
      }
    }, [selectedData])

    useEffect(() => {
      setSpecialAddress((prevSpecialAddress) => ({
        ...prevSpecialAddress,
        fuelSurcharge: fuelSurcharge
      }));
    }, [fuelSurcharge])

    // hook for To City
    useEffect(async () => {
      if (toSelection.length > 0) {
          setSpecialAddress({...specialAddress, toCity: toSelection[0].id})
          const pList = await handleProvince(toSelection[0].id);
          setToProvince(pList.data);
      }
      else {
          setToProvince([]);
          setToCountryList([]);
      }
  }, [toSelection])
    // hook for From City
    useEffect(async () => {
        if (fromSelection.length > 0) {
            setSpecialAddress({...specialAddress, fromCity: fromSelection[0].id})
            const pList = await handleProvince(fromSelection[0].id);
            setFromProvince(pList.data);
        }
        else {
            setFromProvince([]);
            setFromCountryList([]);
        }
    }   , [fromSelection])
    const setCountry = async (e) => {
        if (e.target.value !== 'Select') {
            let cList;
            if (e.target.name === "fromProvince") {
                cList = await newHandleCountry(fromProvince.data[0].provineStateID);
                setFromCountryList(cList.data);
            }
            else if (e.target.name === "toProvince") {
                cList = await newHandleCountry(toProvince.data[0].provineStateID);
                setToCountryList(cList.data);
            }
        }
        else {
            if (e.target.name === "fromProvince") {
                setFromCountryList([]);
            }
            else {
                setToCountryList([]);
            }
        }
    }

    const servicesByVehicle = selectedData? serviceSurchargeList.filter((service) => service.vehicleTypes_String === selectedData.value) : [];

    var arr = []
    vehicleSurchargeList.map((user) => {
      return arr.push({ value: user.vehicleType, label: user.vehicleType });
    });

    const rows = specialAddressList?.map((data) => {
      return {
        id: data.rateCardServiceLocationOverrideId,
        flatRate: data.flatRate,
        fuelSurcharge: data.fuelSurcharge,
        shipper: data.shipper,
        fromStreetNo: data.fromStreetNo,
        fromStreet: data.fromStreet,
        fromUnit: data.fromUnit || "",
        fromCity: data.fromCity,
        fromProvince: data.fromProvince,
        fromCountry: data.fromCountry,
        fromPostalCode: data.fromPostalCode,
        deliver: data.deliver,
        toStreetNo: data.toStreetNo,
        toStreet: data.toStreet,
        toUnit: data.toUnit || "",
        toCity: data.toCity,
        toProvince: data.toProvince,
        toCountry: data.toCountry,
        toPostalCode: data.toPostalCode,
        vehicleType: data.vehicleType,
        serviceName: data.serviceName,
        pcs: data.pcs,
        weight: data.weight, 
        reference: data.reference,
      } 
    });

    const getCityID = async (cityName, provinceName) => {
      const result = await handleUniqueCity({cityName, provinceName});
      return result?.data?.data;
    }

    const columns = [    
        {
          field: "actions",
          headerName: "Actions",
          sortable: false,
          width: 120,
          disableClickEventBubbling: true,
          renderCell: (data) => {
              const onClick = async (e) => {
                  console.log(data);
                  e.stopPropagation();
                  setIsEdit(true);
                  const RateCardServiceLocationOverrideId = data.row.id
                  const fcity = await getCityID(data.row.fromCity, data.row.fromProvince);
                  const tcity = await getCityID(data.row.toCity, data.row.toProvince);
                  setFromSelection([{name: data.row.fromCity, id: fcity}]);
                  setToSelection([{name: data.row.toCity, id: tcity}]);
                  const flst = await handleProvince(fcity)
                  const tlst = await handleProvince(tcity)
                  const fcountry = data.row.fromCountry === "CAN"? 1: 2;
                  const tcountry = data.row.toCountry === "CAN"? 1: 2;
                  setSpecialAddress(data.row);
                  setToProvince(tlst.data);
                  setFromProvince(flst.data);
                  setFromCountryList({data: [{countryId: fcountry, countryname: data.row.fromCountry}]});
                  setToCountryList({data: [{countryId: tcountry, countryname: data.row.toCountry}]});
                  setSpecialAddress(specialAddress => ({...specialAddress, fromCountry: fcountry, toCountry: tcountry}));
                  setSpecialAddress(specialAddress => ({...specialAddress,RateCardServiceLocationOverrideId, fromProvince: flst.data.data[0].provineStateID, toProvince: tlst.data.data[0].provineStateID}));
                  //await setSelectedData({value: data.row.vehicleType, label: data.row.vehicleType});
                  //const serviceID = await servicesByVehicle.find((service) => service.serviceDescription === data.row.serviceName)?.rateCardServiceID;
                  const vehicleType = { value: data.row.vehicleType, label: data.row.vehicleType };
                  setSelectedData(vehicleType);
                  
                  // Update the servicesByVehicle array
                  const services = serviceSurchargeList.filter(service => service.vehicleTypes_String === data.row.vehicleType);
                  const serviceID = services.find(service => service.serviceDescription === data.row.serviceName)?.rateCardServiceID;
                  setService(serviceID);
                  setSpecialAddress(specialAddress => ({...specialAddress, fromCity: fcity, toCity: tcity,rateCardServiceId: serviceID }));
              }
              const onRemove = (e) => {
                  e.stopPropagation();
                  setDeleteId(data.row.id);
                  setOpen(true);
              };

              return (
                  <>
                      <>
                          <div className="action_btn">
                              <span className="edit_btn" onClick={onClick}><Edit /></span>
                              <span className="del_btn" onClick={onRemove}><Delete /></span>
                          </div>
                      </>
                      <>
                          <div>
                              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                  <DialogTitle id="alert-dialog-title">
                                      Delete Confirmation
                                  </DialogTitle>
                                  <DialogContent>
                                      <DialogContentText id="alert-dialog-description">
                                          Are you sure you want to delete this row?
                                      </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                      <Button className="btn-secondary" onClick={() => { setOpen(false); }}>Cancel</Button>
                                      <Button className="btn-primary" onClick={() => { handleClose(); deleteSpecialAddressRecord() }} autoFocus> Confirm</Button>
                                  </DialogActions>
                              </Dialog>
                          </div>
                      </>
                  </>
              )

          },
      },
      { field: 'flatRate', headerName: 'Quote Price', width: 100 },
      { field: 'fuelSurcharge', headerName: 'Fuel Surcharge', width: 130 },
      { field: 'shipper', headerName: 'Shipper Name', width: 130 },
      { field: 'fromStreetNo', headerName: 'Street No.', width: 100 },
      { field: 'fromStreet', headerName: 'Street Name', width: 110 },
      { field: 'fromUnit', headerName: 'Unit', width: 60 },
      { field: 'fromCity', headerName: 'City', type: 'number', width: 80 },
      { field: 'fromProvince', headerName: 'Province', type: 'number', width: 100 },
      { field: 'fromCountry', headerName: 'Country', type: 'number', width: 100 },
      { field: 'fromPostalCode', headerName: 'Postal Code', type: 'number', width: 100 },
      { field: 'deliver', headerName: 'Deliver To Name', type: 'number', width: 130 },
      { field: 'toStreetNo', headerName: 'Street No.', width: 100 },
      { field: 'toStreet', headerName: 'Street Name', width: 110 },
      { field: 'toUnit', headerName: 'Unit', width: 60 },
      { field: 'toCity', headerName: 'City', type: 'number', width: 80 },
      { field: 'toProvince', headerName: 'Province', type: 'number', width: 100 },
      { field: 'toCountry', headerName: 'Country', type: 'number', width: 100 },
      { field: 'toPostalCode', headerName: 'Postal Code', type: 'number', width: 100 },      
      { field: 'vehicleType', headerName: 'Vehicle', type: 'number', width: 100 },
      { field: 'serviceName', headerName: 'Services', type: 'number', width: 100 },
      { field: 'pcs', headerName: 'Pcs. Sched', type: 'number', width: 100 },
      { field: 'weight', headerName: 'Wgt. Sched', type: 'number', width: 100 },
    { field: 'reference', headerName: 'Reference', type: 'number', width: 100 },
    ];

    const updateSpecialAddress = (e) => {setSpecialAddress(specialAddress => ({...specialAddress, [e.target.name]: e.target.value}))}

    const addSpecialAddressRecord = async () => {
      const result = await addSpecialAddress();
      if (result?.data?.statusCode === 200) {
        handleAccount(account?.account_No);
        setSpecialAddress({});
        setToSelection([]);
        setFromSelection([]);
        setSelectedData(null);
      }
    }

    const editSpecialAddressRecord = async () => {
      // TODO: add way to check if at least 1 field is changed
      const result = await editSpecialAddress({body: specialAddress, accountNo: account?.account_No});
      if (result?.data?.statusCode === 200) {
        
        handleAccount(account?.account_No);
        setSpecialAddress({});
        setToSelection([]);
        setFromSelection([]);
        // setSelectedData(null);
        setSelectedData(null); 
        setService(''); 
        setIsEdit(false);
      }
    }

    const deleteSpecialAddressRecord = async () => {
      const result = await deleteSpecialAddress(deleteId);
      if (result?.data?.statusCode === 200) {
        
        handleAccount(account?.account_No);
        setDeleteId(null);
      }
    }

    return(
        <>
        {/* Special address */}
        <Row>
            <Col sm={6}>
                <Row>
                <Col sm={4}><h6 className="input-heading">Enter quote price</h6></Col>
  <Col sm={6}>
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Filler Code" >
  <Form.Control type="text" name="flatRate" placeholder="Filler code" value={specialAddress.flatRate || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
                </Row>
            </Col>
            <Col sm={6}>
            <Row>
                <Col sm={3}></Col>
                <Col sm={5}>
                <div className="form-check input-heading">
         <input type="checkbox" id="formBasicCheckbox" checked={fuelSurcharge} className="form-check-input" onChange={() => {setFuelSurcharge(!fuelSurcharge);}} />
         <label title="" htmlFor="formBasicCheckbox" className="form-check-label"> Do not add a fuel surcharge</label>
         </div>
                </Col>
  <Col sm={4}>
  { isEdit ? <Button variant="primary" onClick={() => editSpecialAddressRecord()}>Update</Button> :
  <Button variant="secondary" onClick={() => addSpecialAddressRecord()}>Add</Button> }
  </Col>
                </Row>
            </Col>
        </Row>
        {/* shipper and deliver info */}
        <Row>
            <Col sm={6}>
            <fieldset className="mt-3 mb-3">
 <label className="label-heading">Shipper</label>
      <>
  <Row className="mt-2 mb-2">
  <Col sm={12} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Shipper" >
  <Form.Control type="text" name="shipper" placeholder="Shipper" value={specialAddress.shipper || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={2} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="St#" >
  <Form.Control type="text" name="fromStreetNo" placeholder="St#" value={specialAddress.fromStreetNo || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Street" >
  <Form.Control type="text" name="fromStreet" placeholder="Street" value={specialAddress.fromStreet || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={2} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Unit" >
  <Form.Control type="text" name="fromUnit" placeholder="Unit" value={specialAddress.fromUnit || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
    <Typeahead 
      id='cityAutocomplete'
      labelKey="name"
      onChange={setFromSelection}
      options={fromCityList}
      placeholder="Choose a city..."
      selected={fromSelection}
    />
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Province" className="mb-2">
    <FloatingLabel controlId="floatingSelectGrid" label="From Province" className="province-selector">
      <Form.Select aria-label="Floating label select example" name="fromProvince" onChange={(e) => {updateSpecialAddress(e); setCountry(e)}} value={specialAddress.fromProvince || ""} required>
        <option>Select</option>:
        <>
          {
            fromProvince?.data?.map((data, index) => (
              <option key={data.provineStateID} value={data.provineStateID}>{data.provineSateName}</option>
            ))

          }
        </>
      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="country" className="mb-2">
    <FloatingLabel controlId="floatingSelectGrid" label="From Country">
      <Form.Select aria-label="Floating label select example" name="fromCountry" value={specialAddress.fromCountry || ''} onChange={(e) => {updateSpecialAddress(e)}} required>
        <option>Select</option>:
        <>
          {
              fromCountryList?.data?.map((data, index) => (
                <option key={data.countryId} value={data.countryId} >{data.countryname}</option>
              ))
          }
        </>
      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Postal Code" >
  <Form.Control type="text" name="fromPostalCode" placeholder="Postal Code" value={specialAddress.fromPostalCode || ""} onChange={(e) => updateSpecialAddress(e)} />
  </FloatingLabel>    
  </Form.Group>
  </Col>
  </Row>
  <Row>
  </Row>
</>
</fieldset>
{/* Deliver to */}
            </Col>
            <Col sm={6}>
            <fieldset className="mt-3 mb-3">
 <label className="label-heading">Deliver to</label>
      <>
  <Row className="mt-2 mb-2">
  <Col sm={12} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Deliver to" >
  <Form.Control type="text" name="deliver" placeholder="Deliver to" value={specialAddress.deliver || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={2} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="St#" >
  <Form.Control type="text" name="toStreetNo" placeholder="St#" value={specialAddress.toStreetNo || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Street" >
  <Form.Control type="text" name="toStreet" placeholder="Street" value={specialAddress.toStreet || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={2} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Unit" >
  <Form.Control type="text" name="toUnit" placeholder="Unit" value={specialAddress.toUnit || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
    <Typeahead 
      id='cityAutocomplete'
      labelKey="name"
      onChange={setToSelection}
      options={toCityList}
      placeholder="Choose a city..."
      selected={toSelection}
    />
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Province" className="mb-2">
    <FloatingLabel controlId="floatingSelectGrid" label="To Province">
      <Form.Select aria-label="Floating label select example" name="toProvince" onChange={(e) => {updateSpecialAddress(e); setCountry(e)}} value={specialAddress.toProvince || ""} required>
        <option>Select</option>:
        <>
          {
            toProvince?.data?.map((data, index) => (
              <option key={data.provineStateID} value={data.provineStateID}>{data.provineSateName}</option>
            ))

          }
        </>
      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="country" className="mb-2">
    <FloatingLabel controlId="floatingSelectGrid" label="To Country">
      <Form.Select aria-label="Floating label select example" name="toCountry" value={specialAddress.toCountry || ''} onChange={(e) => {updateSpecialAddress(e)}} required>
        <option>Select</option>:
        <>
          {
              toCountryList?.data?.map((data, index) => (
                <option key={data.countryId} value={data.countryId} >{data.countryname}</option>
              ))
          }
        </>
      </Form.Select>
    </FloatingLabel>
  </Form.Group>
  </Col>
  <Col sm={4} className="mb-2">
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Postal Code" >
  <Form.Control type="text" name="toPostalCode" placeholder="Postal Code" value={specialAddress.toPostalCode || ""} onChange={(e) => updateSpecialAddress(e)} />
  </FloatingLabel>    
  </Form.Group>
  </Col>
  </Row>
  <Row>
  </Row>
</>
</fieldset>
            </Col>
        </Row>
{/* Remote login info */}
<Row className="mb-4 mt-5">
     <Col sm={12}>
 <fieldset >
 <label className="label-heading">Vehicle Type</label>
      <>
  <Row className="mt-2 mb-2">
  <Col>
    <Controller
      control={control}
      name="VehicleTypes"
      defaultValue={selectedData || ''}
      {...register('VehicleTypes')}
      render={({ field: { onChange, value, name, ref } }) => (
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: "47px",
            }),
          }}
          placeholder="Select Vehicle"
          name={name}
          defaultValue={selectedData || ''}
          ref={ref}
          options={arr}
          // value = {serviceData.VehicleTypes?.map((d)=>d.value)?.join(' , ')}
          value={selectedData || ""}
          onChange={(e) => {setSelectedData(e);}}
        />
      )}
    />
  </Col>
  <Col>
    <Form.Group controlId="Service">
      <FloatingLabel controlId="floatingSelectGrid" label="Service">
        <Form.Select aria-label="Floating label select example" name="serviceID" className="input" value={service} onChange={(e) => {setService(e.target.value);setSpecialAddress({...specialAddress, rateCardServiceId: parseInt(e.target.value)})}}>
          <option>Select</option>:
          <>
            {
              servicesByVehicle?.map((data, index) => (
                <option key={index} value={data.rateCardServiceID}>{data.serviceDescription}</option>
              ))

            }
          </>
        </Form.Select>
      </FloatingLabel>
    </Form.Group>
  </Col>
  <Col>
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Pcs.Sched" >
  <Form.Control type="text" name="pcs" placeholder="Discount" value={specialAddress.pcs || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col>
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Wgt.Sched" >
  <Form.Control type="text" name="weight" placeholder="Remote login" value={specialAddress.weight || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  <Col>
  <Form.Group controlId="Remotelogin">
  <FloatingLabel controlId="floatingInput" label="Reference" >
  <Form.Control type="text" name="reference" placeholder="Remote login" value={specialAddress.reference || ""} onChange={(e) => updateSpecialAddress(e)}/>
  </FloatingLabel>    
  </Form.Group>
  </Col>
  </Row>
  <Row>
    <div className="datatable_section">
      <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} checkboxSelection/>
    </div>
  </Row>
</>
</fieldset>
</Col>
</Row>
</>
    );
}
export default Specialaddress; 