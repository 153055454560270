import { Col, Row, Form, FloatingLabel, Table, InputGroup, Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Createaccessorial from "./CreateAccessorial";
import useCustomer from "../../../../../context/CustomerContext"
import Select from 'react-select';
import { addAccessorial, editAccessorial, deleteAccessorial } from "../../../../../features/accessorial/accessorialDetailSlice";
import { useGetAccessorialQuery } from "../../../../../features/accessorial/accessorialDetailSlice";
import { useCustomData } from "../../../../Sidebar/Permission";
import { useForm, Controller } from 'react-hook-form';




const AccessorialSurcharge = () => {
  const [permission] = useCustomData()
  const { data: accessorialList } = useGetAccessorialQuery()
  const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
  const dispatch = useDispatch();
  const { accessorialLists, vehicleLists, setTempDisplay } = useCustomer(); // Modified - added setTempDisplay by Swetha
  const [isShow, setIsShow] = useState(false);
  const [showTime, setShowTime] = useState(false)
  const [show, setShow] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const [isUpdate, setIsUpdate] = useState(false);
  const [accessorialData, setAcessorialData] = useState({})
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [deleteItem, setDeleteItem] = useState()
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false)
  const [isEdit, setisEdit] = useState(false)
  const handleClickOpen = (data) => { setOpen(true);  setDeleteItem(data)};
  const handleClose = () => { setOpen(false); };
  const [statusr, setStatusr] = useState(0);
  const [statust, setStatust] = useState(0);

  const [selectedEditData, setSelectedEditData] = useState([])
  const fTime = watch('FromTime'); //..
  const tTime = watch('ToTime'); //..

  var arr = []
  vehicleLists.map((user) => {
    return arr.push({ value: user.VehicleType, label: user.VehicleType });
  });

  const Accessorialhandler = (e) => {
    setAcessorialData(accessorialData => ({ ...accessorialData, [e.target.name]: e.target.value }));
  }

  const handleSelect = (e) => {
    if (isEdit) {
      const mapData = e?.map((item) => item?.value
      )
      if (mapData && mapData?.length > 0) {
        setSelectedEditData(mapData)
      }
    }
    const mapData = e?.map((item) => item?.value
    )
    if (mapData && mapData?.length > 0) {
      setSelectedData(mapData)
    }
  }

  // Add and update vehicle list 
  const addAccessorialListHandler = (accessorialData) => {
    accessorialData.VehicleTypes = selectedData
    if (Object.keys(accessorialData).length === 1) {
      setIsShow(isShow => !isShow);
    }
    else if (editingTaskIndex === null) {
      if (!selectedData || selectedData.length === 0) {
        setIsError(true)
        return;
      }
      setTempDisplay(false)
      const data = dispatch(addAccessorial({ VehicleTypes: selectedData, Time: (accessorialData.RateType == "T"? accessorialData.Time: null), ...accessorialData }));
      setIsError(false)
      setShowTime(false)
      setShow(false)
    } else {

      if(accessorialData.RateType !== "T") accessorialData.Time = "";
      
      accessorialData.VehicleTypes = selectedEditData
      let editingItem = accessorialLists[editingTaskIndex];
      editingItem = accessorialData;
      dispatch(editAccessorial(editingItem));
      setIsError(false)
      setShowTime(false)   
      setShow(false)
    }
    // setAcessorialData("");
    setEditingTaskIndex(null);
    setIsUpdate(false)
    reset({ AccessorialDescription: "", FromTime: "", ToTime: "", TimeRateType: "", TimeRate: "", RateType: "", Rate: "", TimeRateActive: "", TimeRatePercent: "" });
    setSelectedData('');
  };

    // Edit vehicle list
    const editAccessorialListHandler = (id) => {
      setisEdit(true)
      const obj = []
      let newEditItem = accessorialLists.find((data) => {
        return data.id === id
      });
  
      newEditItem?.VehicleTypes?.forEach((elem, i) => {
        obj.push({ value: elem, label: elem })
      })
      setIsUpdate(true);
      setIsShow(isShow => !isShow);
      setSelectedData(obj)
      setSelectedEditData(newEditItem?.VehicleTypes)
      setAcessorialData(newEditItem)
      if (newEditItem.RateType=== "T") {
        setShowTime(true);
        setStatust(0);
      }
      else {
        setShowTime(false)
      }
      setShow(true)
      setEditingTaskIndex(id);
      reset(accessorialData)
    }

  //TimeRateSection Handler
  const radioHandlerr = (statusr) => { setStatusr(statusr) };
  //BaseRateSection Handler
  const radioHandlert = (statust) => { setStatust(statust); setShowTime(false) };

  const timeHandler = () => { 
    setShowTime(true);
    setStatust(0);
   }

  const disableHandler = () => {
    setShow(true)
  }

 /*  const getTimeRate = (data) =>{
    if(data.TimeRateType === "T"){
      return data.TimeRateActive
    }
    else if(data.TimeRateType === "A"){
      return data.TimeRate
    }
    else if(data.TimeRateType === "P"){
      return data.TimeRatePercent
    }
  } */
  const confirmDelete = () => {
    dispatch(deleteAccessorial(deleteItem))
    handleClose();
}

const formatTimeTo12Hour = (time) => {
  const [hour, minute] = time.split(':');
  const date = new Date();
  date.setHours(hour, minute);

  return date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

  return (
    <>
      {/* Accessorial Charge section*/}

      <Row className="mb-2 mt-2">
        <Col sm={12}>
          <fieldset className="pb-2">
            <div className="section_action_btn">
              <Createaccessorial />
              {!isUpdate ?
                <>
                  {permission.find(event => event.moduleId === 43)?.permissionType?.indexOf('Add') !== -1 ?
                    <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(addAccessorialListHandler)}><AddCircle /></button></div>
                    : <div className="add_btn disable"><button type="button" className="btn"><AddCircle /></button></div>}
                </>

                :
                <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(addAccessorialListHandler)}><Save /></button></div>
              }
            </div>
            {isShow ?
              <>
                <Row className="mt-2 mb-3">
                  <Col sm={3}>
                    <div className="form-floating">
                      <Form.Select aria-label="Floating label select example" name="AccessorialDescription" onChange={onChange}
                        {...register('AccessorialDescription', { required: 'Field is required' })}>
                        <option value="">Select</option>
                        <>
                          {
                            accessorialList?.data?.map((data, index) => (
                              <option key={index} value={data.AccessorialDescription}>{data.description}</option>
                            ))
                          }
                        </>
                      </Form.Select>
                      <label htmlFor="accessoial">Accessorial ID</label>
                    </div>
                    <p className="error-message">{errors.AccessorialDescription?.message}</p>
                  </Col>
                  <Col sm={3}>
                    <Controller
                      control={control}
                      name="VehicleTypes"
                      defaultValue={selectedData}
                      {...register('VehicleTypes')}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          isMulti
                          placeholder="Select Vehicle"
                          name={name}
                          defaultValue={selectedData}
                          ref={ref}
                          options={arr}
                          //value={arr.find((c) => c.value === value)}
                          value={selectedData ? selectedData?.find((e) => e.value === value) : ""}
                          onChange={(e) => handleSelect(e)}
                        />
                      )}
                    />
                    {isError && (<p className="error-message">Field is required !</p>)}
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="From Time">
                      <FloatingLabel controlId="floatingInput" label="From Time" >
                        <Form.Control type="time" placeholder="From Time" name="FromTime" onChange={onChange} onClick={disableHandler}
                          {...register('FromTime')} />
                      </FloatingLabel>
                      {/* <p className="error-message">{errors.FromTime?.message}</p> */}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="To Time">
                      <FloatingLabel controlId="floatingInput" label="To Time" >
                        <Form.Control type="time" placeholder="To Time" name="ToTime" onChange={onChange} onClick={disableHandler}
                          {...register('ToTime')} />
                      </FloatingLabel>
                      {/* <p className="error-message">{errors.ToTime?.message}</p> */}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4 mb-3">
                <Col sm={6}>
                    <fieldset className="mb-3">
                      <label className="label-heading">Base Rate Type Section</label>
                      <Row>
                        <Col sm={6}>
                          <div className="fieldset-inner" >
                            <label className="label-heading">Choose the rate type</label>
                            <div className="floating-checbox">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="RateType" {...register("RateType")} onChange={e => radioHandlert(1)} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="RateType" {...register("RateType")} onChange={e => radioHandlert(2)} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="T" name="RateType" {...register("RateType", { required: 'Field is required' })} onClick={() => timeHandler()} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Time</label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          {statust === 0 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                <Form.Control type="text" placeholder="Time Rate" name="Rate" {...register('Rate', { required: 'Please select the options' })} />
                                <label>Rate </label>
                              </div>
                              <p className="error-message">{errors.RateType?.message}</p>
                            </div>
                          }
                          {statust === 1 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text>$</InputGroup.Text>
                                <Form.Control type="number" placeholder="Time Rate" name="Rate" {...register('Rate', { required: 'Field is required' })} min='0' />
                                <label>Rate ($)</label>
                              </div>
                              <p className="error-message">{errors.RateType?.message}</p>
                            </div>
                          }
                          {statust === 2 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text>%</InputGroup.Text>
                                <Form.Control type="number" placeholder="Time Rate" name="Rate" {...register('Rate', { required: 'Field is required' })} min='0' />
                                <label>Rate (%)</label>
                              </div>
                              <p className="error-message">{errors.RateType?.message}</p>
                            </div>
                          }
                        </Col>
                        {showTime ?
                          <Col sm={6} className="mt-2">
                            <div className="form-floating">
                              <Form.Control type="number" placeholder="Time Rate" name="Time" /* defaultValue="0" */ {...register('Time', { required: 'Field is required'})} min='0' />
                              <label>Time (Minutes)</label>
                            </div>
                            {errors.Time?.message && (<p className="error-message">{" * " + errors.Time?.message}</p>)}
                          </Col>
                          : ""}
                      </Row>
                    </fieldset>
                  </Col>
                  {show && (fTime || tTime)? //..
                  <Col sm={6}>
                    <fieldset className="mb-3">
                      <label className="label-heading">Time Rate Type Section</label>
                      <Row>
                        <Col sm={6}>
                          <div className="fieldset-inner">
                            <label className="label-heading">Choose the Time Rate type</label>
                            <div className="floating-checbox" onChange={e => Accessorialhandler(e)}>
                              <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="TimeRateType" {...register("TimeRateType")} onChange={e => radioHandlerr(1)} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                              </div>
                              <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="TimeRateType" {...register("TimeRateType")} onChange={e => radioHandlerr(2)} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={6}>
                        {statusr === 0 &&
                                    <div className="btn-status">
                                      <div className="form-floating">
                                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                        <Form.Control type="number" placeholder="Rate Type" name="RateActive" {...register('RateActive', { required: 'Please select the options' })} disabled />
                                        <label> Time Rate ($)</label>                                       
                                      </div>
                                      <p className="error-message">{errors.TimeRateType?.message}</p>
                                    </div>
                                  }
                                  {statusr === 1 &&
                                    <div className="btn-status">
                                      <div className="form-floating">
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control type="number" placeholder="Weight Rate" name="TimeRate" {...register('TimeRate', { required: 'Field is required' })} min='0' />
                                        <label> Time Rate ($)</label>                                     
                                      </div>
                                      <p className="error-message">{errors.TimeRate?.message}</p>
                                    </div>
                                  }
                                  {statusr === 2 &&
                                    <div className="btn-status">
                                      <div className="form-floating">
                                        <InputGroup.Text>%</InputGroup.Text>
                                        <Form.Control type="number" placeholder=" Rate" name="TimeRate" {...register('TimeRate', { required: 'Field is required' })} min='0' />
                                        <label> Time Rate (%)</label>                                     
                                      </div>
                                      <p className="error-message">{errors.TimeRate?.message}</p>
                                    </div>
                                  }
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>
                  : ""}
                </Row>
              </>
              : null}
            {/* table */}
            <Row>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Accessorial ID</th>
                    <th>Vehicle Types</th>
                    <th>Rate Type</th>
                    <th>Rate</th>
                    <th>Time (Minutes)</th> 
                    <th>From Time</th>
                    <th>To Time</th>
                    <th>Time Rate Type</th>
                    <th>Time Rate</th>                                                                       
                  </tr>
                </thead>
                <tbody>
                  {accessorialLists?.map((data, index) => (
                    <tr key={data.id}>
                      <td>
                        <div className="action_btn">
                           {permission.find(event => event.moduleId === 43)?.permissionType?.indexOf('Update') !== -1 ?
                            <span className="edit_btn" onClick={() => editAccessorialListHandler(data.id)}><Edit /></span> : <span className="edit_btn disable"><Edit /></span>} 
                          {permission.find(event => event.moduleId === 43)?.permissionType?.indexOf('Delete') !== -1 ?
                            <span className="del_btn" onClick={() => handleClickOpen(data?.id)}><Delete /></span> : <span className="del_btn disable"><Delete /></span>}
                          <div>
                            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                              <DialogTitle id="alert-dialog-title">
                                Delete Confirmation
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure you want to delete this row?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                <Button className="btn-primary" onClick={() => { confirmDelete() }}  autoFocus> Confirm</Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </div>
                      </td>
                      <td>{data.AccessorialDescription}</td>
                      <td>{data?.VehicleTypes.join(" , ")}</td>
                      <td>{data.RateType}</td>
                      {/* <td>{data.TimeRate</td>*/}
                      <td>{data.Rate ? (data.RateType === 'A' ?  (parseFloat(data.Rate)).toFixed(2) : data.RateType === 'P'? Math.round(data.Rate) :data.RateType === 'T' ?  (parseFloat(data.Rate)).toFixed(2) : data.Rate):""}</td>
                      <td>{data.Time}</td>
                      <td>{data.FromTime? formatTimeTo12Hour(data.FromTime): data.FromTime}</td>
                      <td>{data.ToTime ? formatTimeTo12Hour(data.ToTime) : data.ToTime}</td>
                      <td>{data.TimeRateType}</td>
                      <td>{data.TimeRate ? (data.TimeRateType === 'A' ?  (parseFloat(data.TimeRate)).toFixed(2) : data.TimeRateType === 'P'? Math.round(data.TimeRate) : data.TimeRate):""}</td>
                      
                     
                      
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </fieldset>
        </Col>
      </Row>

    </>
  );
}
export default AccessorialSurcharge;