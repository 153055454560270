import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useState } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import useCustomer from "../../../context/CustomerContext";
import { addLessLoad, editLessLoad, deleteLessLoad } from "../../../features/lessLoad/lessLoadSlice";
import { addFullLoad, editFullLoad, deleteFullLoad } from "../../../features/fullLoad/fullLoadSlice";
import { addHeavyLoad, editHeavyLoad, deleteHeavyLoad } from "../../../features/heavyLoad/heavyLoadSlice";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


const RemoteLogin = () => {
  const dispatch = useDispatch();
  const { lessFuelLists, fullFuelLists, heavyFuelLists, handleAccount, singleAccount, setTempDisplay } = useCustomer();
  const [isShow, setIsShow] = useState(false);
  const [update, setUpdate] = useState("");
  const [lessFuel, setLessFuel] = useState({})
  const [fullFuel, setFullFuel] = useState({})
  const [heavyFuel, setHeavyFuel] = useState({})
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [fuelOpen, setFuelOpen] = useState(false);
  const [isDelete, setIsDelete] = useState()
  const [typeDeleting, setTypeDeleting] = useState("")
  const account = singleAccount && singleAccount[0];
  const handleFuelClose = () => { setFuelOpen(false); };
  const lessFuelhandler = (e) => { setLessFuel(lessFuel => ({ ...lessFuel, [e.target.name]: e.target.value })) }
  const fullFuelhandler = (e) => { setFullFuel(fullFuel => ({ ...fullFuel, [e.target.name]: e.target.value })) }
  const heavyFuelhandler = (e) => { setHeavyFuel(heavyFuel => ({ ...heavyFuel, [e.target.name]: e.target.value })) }
  // Add and update less fuel list
  const addLessFuelList = (e) => {
    e.preventDefault();
    if (Object.keys(lessFuel).length === 0) {
      toast.info("Please fill the form", {
        position: "top-center",
      })
    }
    else if (editingTaskIndex === null) {
      setTempDisplay(false)
      dispatch(addLessLoad(lessFuel));
    } else {
      let editingItem = lessFuelLists[editingTaskIndex];
      editingItem = lessFuel;
      dispatch(editLessLoad(editingItem));
    }
    setLessFuel("");
    setEditingTaskIndex(null);
    setUpdate("")
  };

  const addFullFuelList = () => {
    if (Object.keys(fullFuel).length === 0) {
      toast.info("Please fill the form", {
        position: "top-center",
      })
    }
    else if (editingTaskIndex === null) {
      setTempDisplay(false)
      dispatch(addFullLoad(fullFuel));
    } else {
      let editingItem = fullFuelLists[editingTaskIndex];
      editingItem = fullFuel;
      dispatch(editFullLoad(editingItem));
    }
    setFullFuel("");
    setEditingTaskIndex(null);
    setUpdate("")
  }

  const addheavyFuelList = () => {
    if (Object.keys(heavyFuel).length === 0) {
      toast.info("Please fill the form", {
        position: "top-center",
      })
    }
    else if (editingTaskIndex === null) {
      setTempDisplay(false)
      dispatch(addHeavyLoad(heavyFuel));
    } else {
      let editingItem = heavyFuelLists[editingTaskIndex];
      editingItem = heavyFuel;
      dispatch(editHeavyLoad(editingItem));
    }
    setHeavyFuel("");
    setEditingTaskIndex(null);
    setUpdate("")
  }
  // Edit vehicle list
  const editLessFuelList = (id) => {
    let newEditItem = lessFuelLists.find((data) => {
      return data.id === id
    });
    setUpdate("LTL");
    setLessFuel(newEditItem)
    setEditingTaskIndex(id);
  }

  const editFullFuelList = (id) => {
    let newEditItem = fullFuelLists.find((data) => {
      return data.id === id
    });
    setUpdate("FTL");
    setFullFuel(newEditItem)
    setEditingTaskIndex(id);
  }

  const editHeavyFuelList = (id) => {
    let newEditItem = heavyFuelLists.find((data) => {
      return data.id === id
    });
    setUpdate("HTL")
    setHeavyFuel(newEditItem)
    setEditingTaskIndex(id);
  }
  const deleteHandler = (data) => {
    //console.log("data-->>",data)
    setIsDelete(data)
    setFuelOpen(true)
  }

  const beforeEditDelete = (e) => {
    //console.log(e.target.name)
    if (e.target.name === "lessfuel-confirm") {
      dispatch(deleteLessLoad(isDelete))
      setFuelOpen(false)
      setLessFuel("")
    }

    else if (e.target.name === "fullfuel-confirm") {
      dispatch(deleteFullLoad(isDelete))
      setFuelOpen(false)
      setFullFuel("")
    }

    else if (e.target.name === "heavyfuel-confirm") {
      dispatch(deleteHeavyLoad(isDelete))
      setFuelOpen(false)
      setHeavyFuel("")
    }
  }



  return (
    <>

      {/* Remote login info */}
      <Row className="mb-4 mt-4">
        <Col sm={12}>
          <fieldset className="mb-3">
            <label className="label-heading">Remote Login Info</label>
            <Row>
              <Col sm={4}>
                <fieldset className="less_load_table">
                  <label className="label-heading">Effective date on less load (LTL)</label>
                  <>
                    <Row>
                      <Row>
                         <Col sm={5} className="justify-content-between">
                          <Form.Group controldId="MaxField">
                            <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                              <Form.Control type="MaxField" placeholder="Max Field" name="ltlMaxField" onChange={e => lessFuelhandler(e)} value={lessFuel?.ltlMaxField || ''} />
                              
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="CarbonTax" placeholder="Tax Field" name="ltlCarbonTax" onChange={e => lessFuelhandler(e)} value={lessFuel?.ltlCarbonTax || ''} />
                              
                            </FloatingLabel>
                          </Form.Group>
                        </Col> 
                        <Col sm={3} className="justify-content-between">
                          <div className="section_action_btn">
                            {update != "LTL" ?
                              <div className="add_btn"><button type="button" className="btn" onClick={e => addLessFuelList(e)}><AddCircle /></button></div>
                              :
                              <div className="save_btn"><button type="button" className="btn" onClick={e => addLessFuelList(e)}><Save /></button></div>
                            }
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-3">
                        <Col sm={6} className="mb-2">
                          <Form.Group controlId="Date">
                            <FloatingLabel controlId="floatingInput" label="Date" >
                              <Form.Control type="date" placeholder="Date" name="ltlEffectiveDate" onChange={e => lessFuelhandler(e)} value={lessFuel?.ltlEffectiveDate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group controlId="ToCost">
                            <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                              <Form.Control type="number" placeholder="Fuel Charge" name="ltlRate" onChange={e => lessFuelhandler(e)} value={lessFuel?.ltlRate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Col sm={12}>
                        <Row>
                          <div className="table-responsive">
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Date</th>
                                  <th>Fuel Charge</th>
                                  <th>Max Field</th>
                                  <th>Carbon Tax</th> 
                                </tr>
                              </thead>
                              <tbody>
                                {lessFuelLists?.map((data) => (
                                  <tr key={data?.id}>
                                    <td>
                                      <div className="action_btn">
                                        <span className="edit_btn" onClick={() => editLessFuelList(data?.id)}><Edit /></span>
                                        <span className="del_btn" onClick={() => {deleteHandler(data?.id); setTypeDeleting("LTL")}}><Delete /></span>
                                      </div>
                                      <Dialog open={fuelOpen && typeDeleting == "LTL"} onClose={handleFuelClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                        <DialogTitle id="alert-dialog-title">
                                          Delete Confirmation
                                        </DialogTitle>
                                        <DialogContent>
                                          <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this row?
                                          </DialogContentText>
                                        </DialogContent>
                                        <DialogActions >
                                          <Button className="btn-secondary" onClick={handleFuelClose}>Cancel</Button>
                                          <Button className="btn-primary" name="lessfuel-confirm" onClick={(e) =>{beforeEditDelete(e);}} autoFocus> Confirm</Button>
                                        </DialogActions>
                                      </Dialog>
                                    </td>
                                    <td>{data?.ltlEffectiveDate}</td>
                                    <td>{data?.ltlRate}</td>
                                    <td>{data?.ltlMaxField}</td>
                                    <td>{data?.ltlCarbonTax}</td>
                                  </tr>
                                ))} 
                              </tbody>
                            </Table>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
              <Col sm={4}>
                <fieldset className="full_load_table">
                  <label className="label-heading">Effective date on full load (FTL)</label>
                  <Row>
                     <Col sm={5} className="justify-content-between">
                      <Form.Group controldId="MaxField">
                        <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                          <Form.Control type="MaxField" placeholder="Max Field" name="ftlMaxField" onChange={e => fullFuelhandler(e)} value={fullFuel?.ftlMaxField || ''} />
                          
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="CarbonTax" placeholder="Tax Field" name="ftlCarbonTax" onChange={e => fullFuelhandler(e)} value={fullFuel?.ftlCarbonTax || ''} />
                              
                            </FloatingLabel>
                          </Form.Group>
                        </Col> 
                    <Col sm={3} className="justify-content-between">
                      <div className="section_action_btn">
                        {update != "FTL" ?
                          <div className="add_btn"><button type="button" className="btn" onClick={e => { addFullFuelList(e); }}><AddCircle /></button></div>
                          :
                          <div className="save_btn"><button type="button" className="btn" onClick={e => { addFullFuelList(e); }}><Save /></button></div>
                        }
                      </div>
                    </Col>
                  </Row>
                  <>
                    <Row>
                      
                      <Row className="mt-2 mb-3">
                        <Col sm={6} className="mb-2">
                          <Form.Group controlId="Date">
                            <FloatingLabel controlId="floatingInput" label="Date" >
                              <Form.Control type="date" placeholder="Date" name="ftlEffectiveDate" onChange={e => fullFuelhandler(e)} value={fullFuel?.ftlEffectiveDate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group controlId="ToCost">
                            <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                              <Form.Control type="number" placeholder="Fuel Charge" name="ftlRate" onChange={e => fullFuelhandler(e)} value={fullFuel?.ftlRate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Col sm={12}>
                        <Row>
                          <div className="table-responsive">
                            <Table striped bordered hover >
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Date</th>
                                  <th>Fuel Charge</th>
                                  <th>Max Field</th>
                                  <th>Carbon Tax</th> 
                                </tr>
                              </thead>
                              <tbody>
                                {fullFuelLists?.map((data) => (
                                  <tr key={data?.id}>
                                    <td>
                                      <div className="action_btn">
                                        <span className="edit_btn" onClick={() => editFullFuelList(data?.id)}><Edit /></span>
                                        <span className="del_btn" onClick={() => {deleteHandler(data?.id); setTypeDeleting("FTL")}}><Delete /></span>
                                      </div>
                                      <Dialog open={fuelOpen && typeDeleting == "FTL"} onClose={handleFuelClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                        <DialogTitle id="alert-dialog-title">
                                          Delete Confirmation
                                        </DialogTitle>
                                        <DialogContent>
                                          <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this row?
                                          </DialogContentText>
                                        </DialogContent>
                                        <DialogActions >
                                          <Button className="btn-secondary" onClick={handleFuelClose}>Cancel</Button>
                                          <Button className="btn-primary" name="fullfuel-confirm" onClick={beforeEditDelete} autoFocus> Confirm</Button>
                                        </DialogActions>
                                      </Dialog>
                                    </td>
                                    <td>{data?.ftlEffectiveDate}</td>
                                    <td>{data?.ftlRate}</td>
                                    <td>{data?.ftlMaxField}</td>
                                    <td>{data?.ftlCarbonTax}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
              <Col sm={4}>
                <fieldset className="heavy_load_table">
                  <label className="label-heading">Effective date on heavy equipment load (HTL)</label>
                  <Row>
                     <Col sm={5} className="justify-content-between">
                            <Form.Group controldId="MaxField">
                              <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                                <Form.Control type="MaxField" placeholder="Max Field" name="htlMaxField" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.htlMaxField || ''} />
                                
                              </FloatingLabel>
                            </Form.Group>
                          </Col>
                          <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="CarbonTax" placeholder="Tax Field" name="htlCarbonTax" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.htlCarbonTax || ''} />
                              
                            </FloatingLabel>
                          </Form.Group>
                        </Col> 
                    <Col sm={3} className="justify-content-between">
                      <div className="section_action_btn">
                        {update != "HTL" ?
                          <div className="add_btn"><button type="button" className="btn" onClick={e => { addheavyFuelList(e); }}><AddCircle /></button></div> :
                          <div className="save_btn"><button type="button" className="btn" onClick={e => { addheavyFuelList(e); }}><Save /></button></div>
                        }
                      </div>
                    </Col>
                  </Row>
                  <>
                    <Row>

                      <Row className="mt-2 mb-3">
                        <Col sm={6} className="mb-2">
                          <Form.Group controlId="Date">
                            <FloatingLabel controlId="floatingInput" label="Date" >
                              <Form.Control type="date" placeholder="Date" name="htlEffectiveDate" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.htlEffectiveDate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group controlId="ToCost">
                            <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                              <Form.Control type="number" placeholder="Fuel Charge" name="htlRate" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.htlRate || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Col sm={12}>
                        <Row>
                          <div className="table-responsive">
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Date</th>
                                  <th>Fuel Charge</th>
                                   <th>Max Field</th>
                                  <th>Carbon Tax</th> 
                                </tr>
                              </thead>
                              <tbody>
                                {heavyFuelLists?.map((data) => (
                                  <tr key={data?.id}>
                                    <td>
                                      <div className="action_btn">
                                        <span className="edit_btn" onClick={() => editHeavyFuelList(data?.id)}><Edit /></span>
                                        <span className="del_btn" onClick={() => {deleteHandler(data?.id); setTypeDeleting("HTL")}}><Delete /></span>
                                      </div>
                                      <Dialog open={fuelOpen && typeDeleting=="HTL"} onClose={handleFuelClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                        <DialogTitle id="alert-dialog-title">
                                          Delete Confirmation
                                        </DialogTitle>
                                        <DialogContent>
                                          <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this row?
                                          </DialogContentText>
                                        </DialogContent>
                                        <DialogActions >
                                          <Button className="btn-secondary" onClick={handleFuelClose}>Cancel</Button>
                                          <Button className="btn-primary" name="heavyfuel-confirm" onClick={(e) => {beforeEditDelete(e); console.log("heavy pressed")}} autoFocus> Confirm</Button>
                                        </DialogActions>
                                      </Dialog>
                                    </td>
                                    <td>{data.htlEffectiveDate}</td>
                                    <td>{data.htlRate}</td>
                                    <td>{data?.htlMaxField}</td>
                                    <td>{data?.htlCarbonTax}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
            </Row>
          </fieldset>
        </Col>
      </Row>
    </>

  );
}
export default RemoteLogin;