import { Col, Row, Form, FloatingLabel, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import { baseUrl } from "../../../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataGridDemo from "./getCwtData"
import { DataGrid } from '@mui/x-data-grid';
import { DataGridPremium, GridToolbar, useGridApiRef, } from '@mui/x-data-grid-premium';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { addCwtRateCard, editCwtRateCard, deleteCwtRateCard, addCwtRateCardMultiple } from "../../../features/cwtRateCard/cwtRateCardSlice";
import { CloudUpload } from "@material-ui/icons";
import FileUploader from "../Fileupload";
import useCustomer from "../../../context/CustomerContext";
import axios from "axios";
import { useGetCountryQuery, useLazyGetStateQuery, useLazyGetCityListQuery } from "../../../features/countryState/countryStateSlice";
import { useUpdateCwtDataMutation, useDeleteCwtDataMutation } from "../../../features/cwtRateCard/cwtRateCardSlice";
import { useModifyHistoryMutation } from "../../../features/customer/history/historySlice";
import { useRef } from "react";
import { useCustomData } from "../../Sidebar/Permission";
import CountryProvince from "../../CountryProvince";
import { useAddCwtDataAfterEditMutation } from "../../../features/cwtRateCard/cwtRateCardSlice";
import UploadRateCard from "../../Admin/DefaultPricing/DefaultPricing/CwtRateCard/UploadRateCard";
import * as XLSX from 'xlsx';
import { useAddCwtRateCardAfterCreationMutation } from "../../../features/cwtRateCard/cwtRateCardSlice";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const EditCwtRatecard = () => {
  const { country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity } = CountryProvince()
  const [permission] = useCustomData()
  const dispatch = useDispatch();
  const { data: toCountry } = useGetCountryQuery()
  const toProvince = province;
  const toCity = city;
  const fromCity = city;

  const [updateCwtData] = useUpdateCwtDataMutation()
  const [deleteCwtData] = useDeleteCwtDataMutation()
  const [modifyHistory] = useModifyHistoryMutation()
  const [addCWTRateCardData] = useAddCwtDataAfterEditMutation()
  const { handleAccount, singleAccount, cwtRatecardList, contractInfo, cwtRateCardLists, cwtRateCardCityList } = useCustomer();
  const ratecardaccount = contractInfo && contractInfo[0]
  const account = singleAccount && singleAccount[0];
  const [isEditItem, setIsEditItem] = useState(null);
  const [message, setMessage] = useState("Modified the CWT rate card section")
  const [cwtRateUpdate, setCwtRateUpdate] = useState({});
  const [editId, setEditId] = useState('')
  const [selectToState, setSelectToState] = useState([]);
  const [selectToCity, setSelectToCity] = useState("Select")
  const [showCwtratecard, setShowCwtratecard] = useState(false);
  const [cwtOpen, setCwtOpen] = useState(false);
  const [cwtStatus, setCwtStatus] = useState(0);
  const CwtstatusHandler = (cwtStatus) => { setCwtStatus(cwtStatus) };
  const handleCwtOpen = () => { setCwtOpen(true); };
  const handleCwtClose = () => { setCwtOpen(false); };
  const prevCount = usePrevious(cwtRateUpdate);
  const [open, setOpen] = useState(false);
  const [isDelete, setIsDelete] = useState()
  const [isShow, setIsShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [customeditfields, setCustomEditFields] = useState(false);
  const [cwtRatecardData, setCwtRatecardData] = useState({})
  const { tempDisplay, setTempDisplay } = useCustomer();
  const [finaldata, setFinalData] = useState([])
  const [addCwtRateCardAfterCreation] = useAddCwtRateCardAfterCreationMutation()
  let flag = false;
  const [newRecordCount, setNewRecordCount] = useState(0);
  const [tempidvalue, setTempIdValue] = useState({
    fromCityvalue: "",
    fromProvvalue: "",
  })
  const [tempidto, setTempIdto] = useState({
    toCityvalue: "",
    toProvValue: ""
  })

  const fixRateCardMap = () => {
    const tempRateCard = cwtRateCardLists.slice(-newRecordCount).map((rateCard) => {
      const fromCountry = rateCard.mLCwtRateCardCity.fromCity.fromcountry || null;
      const toCountry = rateCard.mLCwtRateCardCity.toCity.tocountry || null;
      return {
        ...rateCard, mLCwtRateCardCity:
        {
          fromCity:
          {
            fromcity: rateCard.mLCwtRateCardCity.fromCity.fromcity.id || 1,
            fromcityName: rateCard.mLCwtRateCardCity.fromCity.fromcity.city || "",
            fromcountry: rateCard.mLCwtRateCardCity.fromCity.fromcountry || "",
            fromcountryName: fromCountry && fromCountry === '1' ? 'CA' : fromCountry && fromCountry === '2' ? 'USA' : "",
            fromprov: rateCard.mLCwtRateCardCity.fromCity.fromprov.provineStateID || 1,
            fromprovName: rateCard.mLCwtRateCardCity.fromCity.fromprov.provineSateName || "",
          },
          toCity: {
            tocity: rateCard.mLCwtRateCardCity.toCity.tocity.id || 1,
            tocityName: rateCard.mLCwtRateCardCity.toCity.tocity.city || "",
            tocountry: rateCard.mLCwtRateCardCity.toCity.tocountry || "",
            tocountryName: toCountry && toCountry === '1' ? 'CA' : toCountry && toCountry === '2' ? 'USA' : "",
            toprov: rateCard.mLCwtRateCardCity.toCity.toprov.provineStateID || 1,
            toprovName: rateCard.mLCwtRateCardCity.toCity.toprov.provineSateName || "",
          }
        }
      }
    })
    return tempRateCard;
  }

  const cwtRatecardHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let toCities = toCity?.data;
    if (name === "fromprov") {
      let provinces = province?.data;
      let temp_cityname = provinces?.find(v => {
        if (v.provineStateID == value) {
          return v.provineSateName
        }
      })
      setTempIdValue((prevTempIdValue) => ({
        ...prevTempIdValue,
        fromProvvalue: temp_cityname.provineSateName,
      }));
    }
    if (name === "toprov") {
      let toprovince = toProvince?.data;
      let to_provname = toprovince?.find(v => {
        if (v.provineStateID == value) {
          return v.provineSateName
        }
      })
      setTempIdto({ ...tempidto, toProvValue: to_provname.provineSateName });
    }
    if (name === "fromcity") {
      let cities = fromCity?.data;
      let from_cityname = cities?.find(v => {
        if (v.id == value) {
          return v.city
        }
      })
      setTempIdValue((prevTempIdValue) => ({
        ...prevTempIdValue,
        fromCityvalue: from_cityname.city,
      }));
    }
    if (name === "tocity") {
      const to_cityname = toCities?.find(v => v.id == value)?.city ?? "";
      setTempIdto({ ...tempidto, toCityvalue: to_cityname });
    }
    setCwtRatecardData(cwtRatecardData => ({ ...cwtRatecardData, [e.target.name]: e.target.value }));
    //   setCwtRatecardData(cwtRatecardData => ({
    //     ...cwtRatecardData, mLCwtRateCardCity: {
    //         ...cwtRatecardData.mLCwtRateCardCity, fromCity: { ...cwtRatecardData.mLCwtRateCardCity?.fromCity, [e.target.name]: e.target.value },
    //         toCity: { ...cwtRatecardData.mLCwtRateCardCity?.toCity, [e.target.name]: e.target.value }
    //     },
    //     mLCWTRatecarditeam: { ...cwtRatecardData.mLCWTRatecarditeam, rateTypeCWT: { ...cwtRatecardData.mLCWTRatecarditeam?.rateTypeCWT, [e.target.name]: e.target.value },
    //      rateTypePallet: { ...cwtRatecardData.mLCWTRatecarditeam?.rateTypePallet, [e.target.name]: e.target.value } }, [e.target.name]: e.target.value
    // }));
  }

  useEffect(() => {
    if (cwtRatecardList.length > 0) {
      setShowCwtratecard(true);
      if (cwtRatecardList[0].mLCWTRatecarditeam?.rateType === 'PALLET' && flag) {
        setCwtStatus(2);
      }
      else if (cwtRatecardList[0].mLCWTRatecarditeam?.rateType === 'CWT' && flag) {
        setCwtStatus(1);
      }
    }
  }, [])
  useEffect(() => {
    if (tempDisplay === true) {
      if (cwtRateCardLists.length > 0) {
        let temp_value = {
          id: "",
          RateType: "",
          "MIN": "",
          AverageWeight: "",
          "FTL": "",
          "From Country": "",
          "From Province": "",
          "From City": "",
          "To City": "",
          "To Country": "",
          "To Province": "",
          "LTL": "",
          "500": "",
          "1M": "",
          "2M": "",
          "5M": "",
          "10M": "",
          "1": "",
          UnitRateP0TO1: "",
          UnitRateP1TO5: "",
          UnitRateP5TO10: "",
          UnitRateP10TO16: "",
          UnitRateP16TO21: "",
          UnitRateP21TO24: "",
          ChargeP0TO100: "",
          ChargeP100TO200: "",
          ChargeP200TO300: "",
          ChargeP300TO400: "",
          ChargeP400TO500: "",
          ChargeP500TO600: "",
        }
        cwtRateCardLists.map(element => {
          const tocountryval = element?.mLCwtRateCardCity?.toCity?.tocountry === '1' ? "CAN" : element?.mLCwtRateCardCity?.toCity?.tocountry === '2' ? "USA" : "";
          const fromcountryval = element?.mLCwtRateCardCity?.fromCity?.fromcountry === '1' ? "CAN" : element?.mLCwtRateCardCity?.fromCity?.fromcountry === '2' ? "USA" : "";
          return (
            temp_value = {
              id: element.id,
              RateType: element.mLCWTRatecarditeam?.RateType,
              "MIN": element?.MinimumRate,
              AverageWeight: element?.AverageWeight,
              "FTL": element?.FTLRate,
              "From Country": fromcountryval,
              "From Province": element?.mLCwtRateCardCity?.fromCity?.fromprov?.provineSateName,
              "From City": element?.mLCwtRateCardCity?.fromCity?.fromcity?.city,
              "To City": element?.mLCwtRateCardCity?.toCity?.tocity?.city,
              "To Country": tocountryval,
              "To Province": element?.mLCwtRateCardCity?.toCity?.toprov?.provineSateName,
              "LTL": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP0TOLTL,
              "500": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP500TO1000,
              "1M": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP1000TO2000,
              "2M": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP2000TO5000,
              "5M": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP5000TO10000,
              "10M": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP10000TO20000,
              UnitRateP0TO1: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP0TO1,
              UnitRate1: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate1,
              UnitRate2: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate2,
              UnitRate3: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate3,
              UnitRate4: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate4,
              UnitRate5: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate5,
              UnitRate6: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate6,
              UnitRate7: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate7,
              UnitRate8: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate8,
              UnitRate9: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate9,
              UnitRate10: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate10,
              UnitRate11: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate11,
              UnitRate12: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate12,
              UnitRate13: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate13,
              UnitRate14: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate14,
              UnitRate15: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate15,
              UnitRate16: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate16,
              UnitRate17: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate17,
              UnitRate18: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate18,
              UnitRate19: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate19,
              UnitRate20: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate20,
              UnitRate21: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate21,
              UnitRate22: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate22,
              UnitRate23: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate23,
              UnitRate24: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate24,
              UnitRateP5TO10: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP5TO10,
              UnitRateP10TO16: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP10TO16,
              UnitRateP16TO21: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP16TO21,
              UnitRateP21TO24: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP21TO24,
              ChargeP0TO100: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP0TO100,
              ChargeP100TO200: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP100TO200,
              ChargeP200TO300: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP200TO300,
              ChargeP300TO400: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP300TO400,
              ChargeP400TO500: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP400TO500,
              ChargeP500TO600: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP500TO600,
            }
          )
        })
        setFinalData([...finaldata, temp_value])
      }
    }
  }, [cwtRateCardLists])
  const getStateData = async (selectToState) => {
  }
  const handletocountry = async (e) => {
    const addressData = e.target.value;
  }
  const handleToCity = async (e) => {
    const addressData = e.target.value;
    setSelectToCity("Select")
  }

  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);

  const submitForm2 = async (event) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", selectedFile);
    await axios
      .post(`${baseUrl}/Customer/UploadExcelFile?RateCardID=${contractInfo[0].rateCardId}`, formData,
        {
          headers: {
            'authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
      .then((res) => {
        res.json().then((res) => {
          setTimeout(function () {
            toast.success(res.message, {
              position: "top-center",
            })
          }, 500);
        }
        )
      })
      .catch((err) => {
        setTimeout(function () {
          toast.error(err.message, {
            position: "top-center",
          })
        }, 500);
      })
    setSelectedFile("")
    handleAccount(account.account_No)
  };

  const Cwtratecardhandler = (e) => {
    setCwtRateUpdate(cwtRateUpdate => ({
      ...cwtRateUpdate, mLCwtRateCardCity: {
        ...cwtRateUpdate.mLCwtRateCardCity, fromCity: { ...cwtRateUpdate.mLCwtRateCardCity?.fromCity, [e.target.name]: e.target.value },
        toCity: { ...cwtRateUpdate.mLCwtRateCardCity?.toCity, [e.target.name]: e.target.value }
      },
      mLCWTRatecarditeam: {
        ...cwtRateUpdate.mLCWTRatecarditeam, rateTypeCWT: { ...cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT, [e.target.name]: e.target.value },
        rateTypePallet: { ...cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet, [e.target.name]: e.target.value }
      }, [e.target.name]: e.target.value
    }));
  }

  const EditCwtItem = (cwtRateCardId) => {
    setEditId(cwtRateCardId)
    let newEditItem = cwtRatecardList.find((data) => {
      return data.cwtRateCardId === cwtRateCardId
    });
    setCwtRateUpdate(newEditItem);
    setShowCwtratecard(showCwtratecard => !showCwtratecard)
    setIsEditItem(cwtRateCardId);
  }

  const editCwtList = async () => {
    if (JSON?.stringify(prevCount) === JSON?.stringify(cwtRateUpdate)) {
      setTimeout(function () {
        toast.info("No Changes found on this CWT rate card section !", {
          position: "top-right",
        })
      });
    }
    else {
      const updateCwt = await updateCwtData(cwtRateUpdate)
      setCwtRateUpdate("");
      handleAccount(account.account_No)
      if (updateCwt.data.statusCode === 200) {
        setTimeout(function () {
          toast.success("CWT rate card section updated successfully !", {
            position: "top-right",
          })
        });
      }
    }
    if (JSON?.stringify(prevCount) !== JSON?.stringify(cwtRateUpdate)) {
      const modifieddata = {
        AccountNo: account.account_No,
        CustomerName: account.customer,
        Modifiedsection: message
      }
      await modifyHistory(modifieddata)
    }
  }


  const testRows = cwtRateCardLists.map((element) => {
    const toCountry = element?.mLCwtRateCardCity?.toCity?.tocountry;
    const fromCountry = element?.mLCwtRateCardCity?.fromCity?.fromcountry;
    const tocountryval = toCountry === 1 || toCountry === '1' ? "CAN" : toCountry === 2 || toCountry === '2' ? "USA" : "";
    const fromcountryval = fromCountry === 1 || fromCountry === '1' ? "CAN" : fromCountry === 2 || fromCountry === '2' ? "USA" : "";
    return {
      id: element.id,
      cwtRateCardId: element.cwtRateCardId,
      RateType: element.mLCWTRatecarditeam?.RateType,
      min: element?.MinimumRate,
      AverageWeight: element?.AverageWeight,
      ftl: element?.FTLRate,
      originCountry: fromcountryval,
      originProvince: element?.mLCwtRateCardCity?.fromCity?.fromprov?.provineSateName,
      origincity: element?.mLCwtRateCardCity?.fromCity?.fromcity?.city,
      destinationcity: element?.mLCwtRateCardCity?.toCity?.tocity?.city,
      destinationCountry: tocountryval,
      destinationProvince: element?.mLCwtRateCardCity?.toCity?.toprov?.provineSateName,
      p0TOLTL: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP0TOLTL,
      p500TO1000: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP500TO1000,
      p1000TO2000: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP1000TO2000,
      p2000TO5000: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP2000TO5000,
      p5000TO10000: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP5000TO10000,
      p10000TO20000: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP10000TO20000,
      UnitRateP0TO1: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP0TO1,
      UnitRate1: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate1,
      UnitRate2: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate2,
      UnitRate3: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate3,
      UnitRate4: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate4,
      UnitRate5: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate5,
      UnitRate6: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate6,
      UnitRate7: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate7,
      UnitRate8: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate8,
      UnitRate9: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate9,
      UnitRate10: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate10,
      UnitRate11: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate11,
      UnitRate12: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate12,
      UnitRate13: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate13,
      UnitRate14: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate14,
      UnitRate15: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate15,
      UnitRate16: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate16,
      UnitRate17: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate17,
      UnitRate18: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate18,
      UnitRate19: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate19,
      UnitRate20: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate20,
      UnitRate21: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate21,
      UnitRate22: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate22,
      UnitRate23: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate23,
      UnitRate24: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate24,
      UnitRateP5TO10: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP5TO10,
      UnitRateP10TO16: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP10TO16,
      UnitRateP16TO21: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP16TO21,
      UnitRateP21TO24: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP21TO24,
      ChargeP0TO100: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP0TO100,
      ChargeP100TO200: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP100TO200,
      ChargeP200TO300: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP200TO300,
      ChargeP300TO400: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP300TO400,
      ChargeP400TO500: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP400TO500,
      ChargeP500TO600: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP500TO600,
    }
  }
  );

  const CWTColumns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation();
          editCwtratecardList(data.id)
        };
        const onRemove = (e) => {
          e.stopPropagation();
          handleClickOpen();
          setIsDelete(data?.row.cwtRateCardId)

        };
        return (
          <>
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                  Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                  <Button className="btn-primary" onClick={() => deleteHandler()} autoFocus> Confirm</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="action_btn">
              {/* <span className="edit_btn" onClick={onClick}><Edit /></span> */}
              <span className="del_btn" onClick={onRemove}><Delete /></span>
            </div>
          </>
        )
      },
    },
    { field: 'origincity', headerName: 'From City', width: 100 },
    { field: 'originProvince', headerName: 'From Province', width: 130 },
    { field: 'originCountry', headerName: 'From Country', width: 130 },
    { field: 'destinationcity', headerName: 'To City', width: 100 },
    { field: 'destinationProvince', headerName: 'To Province', width: 110 },
    { field: 'destinationCountry', headerName: 'To Country', width: 110 },
    { field: "min", headerName: 'Minimum Rate', type: 'number', width: 100 },
    { field: 'p0TOLTL', headerName: 'LTL', type: 'number', width: 100 },
    { field: 'p500TO1000', headerName: '500', type: 'number', width: 100 },
    { field: 'p1000TO2000', headerName: '1M', type: 'number', width: 100 },
    { field: 'p2000TO5000', headerName: '2M', type: 'number', width: 100 },
    { field: 'p5000TO10000', headerName: '5M', type: 'number', width: 100 },
    { field: 'p10000TO20000', headerName: '10M', type: 'number', width: 100 },
    { field: 'ftl', headerName: 'FTL', type: 'number', width: 100 },

  ];

  const palletColumns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation();
          editCwtratecardList(data.id)
        };
        const onRemove = (e) => {
          e.stopPropagation();
          handleClickOpen();
          setIsDelete(data?.row.cwtRateCardId)

        };
        return (
          <>
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                  Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                  <Button className="btn-primary" onClick={() => deleteHandler()} autoFocus> Confirm</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="action_btn">
              {/* <span className="edit_btn" onClick={onClick}><Edit /></span> */}
              <span className="del_btn" onClick={onRemove}><Delete /></span>
            </div>
          </>
        )
      },
    },
    { field: 'origincity', headerName: 'From City', width: 100 },
    { field: 'originProvince', headerName: 'From Province', width: 130 },
    { field: 'originCountry', headerName: 'From Country', width: 130 },
    { field: 'destinationcity', headerName: 'To City', width: 100 },
    { field: 'destinationProvince', headerName: 'To Province', width: 110 },
    { field: 'destinationCountry', headerName: 'To Country', width: 110 },
    { field: 'UnitRate1', headerName: '1', type: 'number', width: 40 },
    { field: 'UnitRate2', headerName: '2', type: 'number', width: 40 },
    { field: 'UnitRate3', headerName: '3', type: 'number', width: 40 },
    { field: 'UnitRate4', headerName: '4', type: 'number', width: 40 },
    { field: 'UnitRate5', headerName: '5', type: 'number', width: 40 },
    { field: 'UnitRate6', headerName: '6', type: 'number', width: 40 },
    { field: 'UnitRate7', headerName: '7', type: 'number', width: 40 },
    { field: 'UnitRate8', headerName: '8', type: 'number', width: 40 },
    { field: 'UnitRate9', headerName: '9', type: 'number', width: 40 },
    { field: 'UnitRate10', headerName: '10', type: 'number', width: 40 },
    { field: 'UnitRate11', headerName: '11', type: 'number', width: 40 },
    { field: 'UnitRate12', headerName: '12', type: 'number', width: 40 },
    { field: 'UnitRate13', headerName: '13', type: 'number', width: 40 },
    { field: 'UnitRate14', headerName: '14', type: 'number', width: 40 },
    { field: 'UnitRate15', headerName: '15', type: 'number', width: 40 },
    { field: 'UnitRate16', headerName: '16', type: 'number', width: 40 },
    { field: 'UnitRate17', headerName: '17', type: 'number', width: 40 },
    { field: 'UnitRate18', headerName: '18', type: 'number', width: 40 },
    { field: 'UnitRate19', headerName: '19', type: 'number', width: 40 },
    { field: 'UnitRate20', headerName: '20', type: 'number', width: 40 },
    { field: 'UnitRate21', headerName: '21', type: 'number', width: 40 },
    { field: 'UnitRate22', headerName: '22', type: 'number', width: 40 },
    { field: 'UnitRate23', headerName: '23', type: 'number', width: 40 },
    { field: 'UnitRate24', headerName: '24', type: 'number', width: 40 },
    { field: 'ChargeP0TO100', headerName: '100', type: 'number', width: 40 },
    { field: 'ChargeP100TO200', headerName: '200', type: 'number', width: 40 },
    { field: 'ChargeP200TO300', headerName: '300', type: 'number', width: 40 },
    { field: 'ChargeP300TO400', headerName: '400', type: 'number', width: 40 },
    { field: 'ChargeP400TO500', headerName: '500', type: 'number', width: 40 },
    { field: 'ChargeP500TO600', headerName: '600', type: 'number', width: 40 },
    { field: 'AverageWeight', headerName: 'Average Weight', type: 'number', width: 130 },
    { field: 'ftl', headerName: 'FTL', type: 'number', width: 100 },
  ];

  const handleClickOpen = (data) => {
    setOpen(true);
    setIsDelete(data)
  };


  const handleClose = () => { setOpen(false); };

  const deleteHandler = () => {
    // dispatch(deleteCwtRateCard(isDelete));
    deleteCwtData(isDelete);
    handleAccount(account.account_No)
    setIsDelete(null);
    handleClose();
  }

  // Edit vehicle list
  const editCwtratecardList = (id) => {
    let newEditItem = cwtRateCardLists.find((data) => {
      return data.id === id
    });
    setCustomEditFields(true);
    setIsUpdate(true);
    setIsShow(isShow => !isShow);
    setCwtRatecardData(newEditItem);
    setEditingTaskIndex(id);
  }

  // appending data from excel file
  const addExcelData = async (rows) => {
    const recordsList = [];
    for (let i = 0; i < rows.length; i++) {
      const row = { ...rows[i], id: i };
      const record = {
        "MinimumRate": row.min || 0,
        "FTLRate": row.ftl || 0,
        "file": "",
        "RateType": cwtStatus === 1 ? "CWT" : "Pallet",
        "UnitRateP0TOLTL": row.p0TOLTL || 0,
        "UnitRateP500TO1000": row.p500TO1000 || 0,
        "UnitRateP1000TO2000": row.p1000TO2000 || 0,
        "UnitRateP2000TO5000": row.p2000TO5000 || 0,
        "UnitRateP5000TO10000": row.p5000TO10000 || 0,
        "UnitRateP10000TO20000": row.p10000TO20000 || 0,
        "unitRateP0TO1": 0,
        "unitRateP1TO5": 0,
        "unitRateP5TO10": 0,
        "unitRateP10TO16": 0,
        "unitRateP16TO21": 0,
        "unitRateP21TO24": 0,
        "UnitRate1": row["p1"] || 0,
        "UnitRate2": row["p2"] || 0,
        "UnitRate3": row["p3"] || 0,
        "UnitRate4": row["p4"] || 0,
        "UnitRate5": row["p5"] || 0,
        "UnitRate6": row["p6"] || 0,
        "UnitRate7": row["p7"] || 0,
        "UnitRate8": row["p8"] || 0,
        "UnitRate9": row["p9"] || 0,
        "UnitRate10": row["p10"] || 0,
        "UnitRate11": row["p11"] || 0,
        "UnitRate12": row["p12"] || 0,
        "UnitRate13": row["p13"] || 0,
        "UnitRate14": row["p14"] || 0,
        "UnitRate15": row["p15"] || 0,
        "UnitRate16": row["p16"] || 0,
        "UnitRate17": row["p17"] || 0,
        "UnitRate18": row["p18"] || 0,
        "UnitRate19": row["p19"] || 0,
        "UnitRate20": row["p20"] || 0,
        "UnitRate21": row["p21"] || 0,
        "UnitRate22": row["p22"] || 0,
        "UnitRate23": row["p23"] || 0,
        "UnitRate24": row["p24"] || 0,
        "ChargeP0TO100": row["100"] || 0,
        "ChargeP100TO200": row["200"] || 0,
        "ChargeP200TO300": row["300"] || 0,
        "ChargeP300TO400": row["400"] || 0,
        "ChargeP400TO500": row["500"] || 0,
        "ChargeP500TO600": row["600"] || 0,
        "fromcity": { "id": row["originCityId"] || 1, "city": row["origincity"] },
        "fromcountry": row["originCountryId"],
        "fromprov": { "provineStateID": row["originProvinceId"], "provineSateName": row["originProvince"] },
        "tocity": { "id": row["destinationcityId"] || 1, "city": row["destinationcity"] },
        "tocountry": row["destinationCountryId"],
        "toprov": { "provineStateID": row["destinationProvinceId"], "provineSateName": row["destinationProvince"] },
      }
      recordsList.push(record);
    }
    setTempDisplay(true)
    dispatch(addCwtRateCardMultiple(recordsList));
    setNewRecordCount(newRecordCount => newRecordCount + recordsList.length);
    if (rows.length > 0) {
      toast.info(`Successfully validated ${rows.length} rows. Now proceed with Save button`, {
        position: "top-center",
      })
    }
    else {
      toast.error("Please check the file and try again", {
        position: "top-center",
      })
    }
  }

  // added spaces manually to align the columns in the correct order in excel sheet
  const columnHeaders = {
    min: 'Minimum Rate',
    ftl: 'FTL Rate',
    avgWeight: 'Average Weight',
    // originCityId: 'Origin City ID',
    origincity: 'Origin City',
    // originProvinceId: 'Origin Province ID',
    originProvince: 'Origin Province',
    // originCountryId: 'Origin Country ID',
    originCountry: 'Origin Country',
    destinationcity: '     Destination City',
    // destinationProvinceId: 'Destination Province ID',
    destinationProvince: 'Destination Province',
    // destinationcityId: 'Destination City ID',
    // destinationCountryId: 'Destination Country ID',
    destinationCountry: 'Destination Country',
    p0TOLTL: '0-500',
    p500TO1000: '500-1000 ',
    p1000TO2000: '1000-2000 ',
    p2000TO5000: '2000-5000 ',
    p5000TO10000: '5000-10000 ',
    p10000TO20000: '10000-20000 ',
    p1: '       1',
    p2: '       2',
    p3: '       3',
    p4: '       4',
    p5: '       5',
    p6: '       6',
    p7: '       7',
    p8: '       8',
    p9: '       9',
    p10: '        10',
    p11: '        11',
    p12: '        12',
    p13: '        13',
    p14: '        14',
    p15: '        15',
    p16: '        16',
    p17: '        17',
    p18: '        18',
    p19: '        19',
    p20: '        20',
    p21: '        21',
    p22: '        22',
    p23: '        23',
    p24: '        24',
    p100: 'Overweight Charge 0-100',
    p200: 'Overweight Charge 100-200',
    p300: 'Overweight Charge 200-300',
    p400: 'Overweight Charge 300-400',
    p500: 'Overweight Charge 400-500',
    p600: 'Overweight Charge 500-600',
  };

  const writeToExcel = (records, fileName) => {
    if (!records || records.length === 0) {
      return;
    }

    // List of keys to exclude from the export
    const excludeColumns = [
      'originCityId', 
      'originProvinceId', 
      'originCountryId',
      'destinationProvinceId',
      'destinationcityId',
      'destinationCountryId',
      'p20000TO999999'
    ];

    // Map the existing keys to meaningful headers
    const mappedRecords = records.map(record => {
      const mappedRecord = {};
      for (let key in record) {
        if (!excludeColumns.includes(key)) {
          const newKey = columnHeaders[key] || key; 
          mappedRecord[newKey] = record[key];
        }
      }
      return mappedRecord;
    });

    const worksheet = XLSX.utils.json_to_sheet(mappedRecords);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}-Invalid.xlsx`);
  }
  const manualCWTData = {
    rateCardId: ratecardaccount?.rateCardId,
    mLCWTRateCardsList: [{
      minimumRate: cwtRateUpdate?.minimumRate,
      ftlRate: cwtRateUpdate?.ftlRate,
      averageWeight: cwtRateUpdate?.averageWeight,
      mLCWTRatecarditeam: {
        rateType: cwtRateUpdate?.rateType,
        rateTypeCWT: {
          unitRateP0TOLTL: cwtRateUpdate?.unitRateP0TOLTL || 0,
          unitRateP500TO1000: cwtRateUpdate?.unitRateP500TO1000 || 0,
          unitRateP1000TO2000: cwtRateUpdate?.unitRateP1000TO2000 || 0,
          unitRateP2000TO5000: cwtRateUpdate?.unitRateP2000TO5000 || 0,
          unitRateP5000TO10000: cwtRateUpdate?.unitRateP5000TO10000 || 0,
          unitRateP10000TO20000: cwtRateUpdate?.unitRateP10000TO20000 || 0,
          unitRateP20000TO9999999: cwtRateUpdate?.unitRateP20000TO9999999 || 0
        },
        rateTypePallet: {
          UnitRate1: cwtRateUpdate?.UnitRate1 || 0,
          UnitRate2: cwtRateUpdate?.UnitRate2 || 0,
          UnitRate3: cwtRateUpdate?.UnitRate3 || 0,
          UnitRate4: cwtRateUpdate?.UnitRate4 || 0,
          UnitRate5: cwtRateUpdate?.UnitRate5 || 0,
          UnitRate6: cwtRateUpdate?.UnitRate6 || 0,
          UnitRate7: cwtRateUpdate?.UnitRate7 || 0,
          UnitRate8: cwtRateUpdate?.UnitRate8 || 0,
          UnitRate9: cwtRateUpdate?.UnitRate9 || 0,
          UnitRate10: cwtRateUpdate?.UnitRate10 || 0,
          UnitRate11: cwtRateUpdate?.UnitRate11 || 0,
          UnitRate12: cwtRateUpdate?.UnitRate12 || 0,
          UnitRate13: cwtRateUpdate?.UnitRate13 || 0,
          UnitRate14: cwtRateUpdate?.UnitRate14 || 0,
          UnitRate15: cwtRateUpdate?.UnitRate15 || 0,
          UnitRate16: cwtRateUpdate?.UnitRate16 || 0,
          UnitRate17: cwtRateUpdate?.UnitRate17 || 0,
          UnitRate18: cwtRateUpdate?.UnitRate18 || 0,
          UnitRate19: cwtRateUpdate?.UnitRate19 || 0,
          UnitRate20: cwtRateUpdate?.UnitRate20 || 0,
          UnitRate21: cwtRateUpdate?.UnitRate21 || 0,
          UnitRate22: cwtRateUpdate?.UnitRate22 || 0,
          UnitRate23: cwtRateUpdate?.UnitRate23 || 0,
          UnitRate24: cwtRateUpdate?.UnitRate24 || 0,
          // unitRateP0TO1: cwtRateUpdate?. unitRateP0TO1 || 0,
          // unitRateP1TO5: cwtRateUpdate?.unitRateP1TO5 || 0,
          // unitRateP5TO10: cwtRateUpdate?.unitRateP5TO10 || 0,
          // unitRateP10TO16: cwtRateUpdate?.unitRateP10TO16 || 0,
          // unitRateP16TO21: cwtRateUpdate?.unitRateP16TO21 || 0,
          // unitRateP21TO24: cwtRateUpdate?.unitRateP21TO24 || 0,
          chargeP0TO100: cwtRateUpdate?.chargeP0TO100 || 0,
          chargeP100TO200: cwtRateUpdate?.chargeP100TO200 || 0,
          chargeP200TO300: cwtRateUpdate?.chargeP200TO300 || 0,
          chargeP300TO400: cwtRateUpdate?.chargeP300TO400 || 0,
          chargeP400TO500: cwtRateUpdate?.chargeP400TO500 || 0,
          chargeP500TO600: cwtRateUpdate?.chargeP500TO600 || 0
        }
      },
      mLCwtRateCardCity: {
        fromCity: {
          fromcity: cwtRateUpdate?.fromcity,
          fromcountry: cwtRateUpdate?.fromcountry,
          fromprov: cwtRateUpdate?.fromprov
        },
        toCity: {
          tocity: cwtRateUpdate?.tocity,
          tocountry: cwtRateUpdate?.tocountry,
          toprov: cwtRateUpdate?.toprov
        }
      }
    }]
  }
  const addData = async () => {
    const rateCardMapped = fixRateCardMap();
    const result = await addCwtRateCardAfterCreation({ rateCardId: contractInfo[0].rateCardId, mLCWTRateCardsList: rateCardMapped });
    if (result.data.statusCode === 200) {
      setTimeout(function () {
        toast.success("File uploaded records saved successfully !", {
          position: "top-right",
        })
      });
      setNewRecordCount(0);
    }
  }

  const addNewSection = async () => {
    //setShowCwtratecard(showCwtratecard => !showCwtratecard)
    if (!showCwtratecard) {
      setShowCwtratecard(true)
    }
    await addCWTRateCardData(manualCWTData)
    handleAccount(account.account_No)
    setCwtRateUpdate("")
    // setCwtStatus(0)
  }
  const addCwtratecardList = async (e) => {
    if (isShow && !cwtStatus) {
      toast.error('Please select a rate type!', {
        position: "top-center",
      })
      return;
    }
    e.preventDefault();
    if (Object.keys(cwtRateUpdate).length === 0) {
      setIsShow(isShow => !isShow);
    }
    else if (newRecordCount > 0) {
      toast.error('Please save the changes before adding a new record!', {
        position: "top-center",
      })
      return;
    }
    else if (editingTaskIndex === null) {
      setTempDisplay(true)
      //setIdValue(!idvalue);
      //setAddValue(true)
      
      // dispatch(addCwtRateCard(cwtRatecardData));
      
      const result = await addCwtRateCardAfterCreation(manualCWTData);
      if (result.data.statusCode === 200) {
        setTimeout(function () {
          toast.success("Manually added records saved successfully!", {
            position: "top-right",
          })
        });
        handleAccount(account.account_No)
        setCwtRateUpdate({});
      }

    } else {
      let editingItem = cwtRateCardLists[editingTaskIndex];
      editingItem = cwtRatecardData;
      dispatch(editCwtRateCard(editingItem));
    }
    setCwtRatecardData("");
    // setToSelection([]);
    // setFromSelection([]);
    setEditingTaskIndex(null);
    setIsUpdate(false)
    // setCwtStatus(0)
  };

  return (
    <>
      {/* CWT Rate Card Section*/}
      <Row className="mb-2 mt-2">
        <Col sm={12}>
          <fieldset className="pb-2">
            <div className="btn_section">
              {/* {permission.find(event => event.moduleId === 44)?.permissionType?.indexOf('Update') !== -1 ?
                            <form className="upload_data">
                                <FileUploader onFileSelectSuccess={(file) => setSelectedFile(file)}
                                    onFileSelectError={({ error }) => alert(error)}>
                                </FileUploader>
                                <button type="button" className="upload-btn" onClick={submitForm2}>Upload <CloudUpload /></button>
                            </form>
                            :""} */}
              <div className="section_action_btn">
                {cwtRateUpdate?.cwtRateCardId ?
                  <div className="save_btn"><button type="button" className="btn" onClick={e => { editCwtList(e); }}>Update Record</button></div>
                  :
                  <>
                    {permission.find(event => event.moduleId === 44)?.permissionType?.indexOf('Update') !== -1 ?
                      <>{ !isShow ?
                      <div className="add_btn"><button type="button" className="btn" onClick={e => { addCwtratecardList(e); }}><AddCircle /></button></div>
                      : <div className="add_btn"><button type="button" className="btn" onClick={e => { addCwtratecardList(e); }}>Add New Record</button></div>
                      }</>
                      : <div className="save_btn disable"><button type="button" className="btn" ><Save /></button></div>
                    }

                            </>
}
                                
                            </div>
                           
                        </div>
                        {isShow ? 
                        <>
                        
                        {/* Rate card item */}
                        <Row className="mt-4">
                            <Col sm={12}>
                                <fieldset className="pb-2">
                                    <label className="label-heading">Rate Card Item</label>
                         
                                        <Row>
                                            <Col sm={12} className="mb-3">
                                                <Row>
                                                    <Col sm={3}><h6>Please select the rate card item type</h6></Col>
                                                    <Col sm={2}>
                                                        <div className="floating-checbox" name="RateType" onChange={(e) =>Cwtratecardhandler(e)}>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="CWT" name="rateType" checked={cwtRateUpdate?.mLCWTRatecarditeam?.rateType ? cwtRateUpdate?.mLCWTRatecarditeam?.rateType === "CWT" : cwtStatus === 1 || ""} onChange={e => {CwtstatusHandler(1);Cwtratecardhandler(e); flag=true}} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox1">CWT</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="PALLET" name="rateType" checked={cwtRateUpdate?.mLCWTRatecarditeam?.rateType ? cwtRateUpdate?.mLCWTRatecarditeam?.rateType === "PALLET" : cwtStatus === 2 || ""} onChange={e => {CwtstatusHandler(2);Cwtratecardhandler(e); flag=true}} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox2">PALLET</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={5}><UploadRateCard addExcelData={addExcelData} writeToExcel={writeToExcel} /></Col>
                                                    {(newRecordCount !== 0) &&
                                                    <Col sm={2}><button type="button" className="btn btn-primary" onClick={() => { addData() }}>Save uploaded records </button></Col> }
                                                </Row>
                                            </Col>
                                            {(cwtStatus !== 0) && <>
                                            <Row>
                                              <Col sm={12}>
                                                  <fieldset className="pb-2 mt-2">
                                                      <label className="label-heading">CWT Rate Card City</label>
                                                
                                                          <Row>
                                                              <Col sm={6}>
                                                                  <fieldset className="pb-2 mt-2">
                                                                      <label className="label-heading">From</label>
                                                                    
                                                                          <Row>
                                                                              <Col sm={12} className="mb-3">
                                                                                  <Form.Group controlId="from" className="mb-2">
                                                                                      <FloatingLabel controlId="floatingSelectGrid" label="From City">
                                                                                          <Form.Select aria-label="Floating label select example" name="fromcity" onChange={e => {Cwtratecardhandler(e); setSelectCity(e.target.value)}} value={cwtRateUpdate.mLCwtRateCardCity?.fromCity?.fromcity ||""} required>
                                                                                          <option value={cwtRateUpdate.mLCwtRateCardCity?.fromCity?.fromcityName || "Select"}>{cwtRateUpdate.mLCwtRateCardCity?.fromCity?.fromcityName || "Select"}</option>:
                                                                                              <>
                                                                                                  {
                                                                                                      city?.data?.map((data, index) => (
                                                                                                          <option key={index} value={data.id}>{data.city}</option>
                                                                                                      ))

                                                  }
                                                </>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group controlId="Province/State" className="mb-2">
                                            <FloatingLabel controlId="floatingSelectGrid" label="From Province/State">
                                              <Form.Select aria-label="Floating label select example" name="fromprov" onChange={(e) => { setSelectState(e.target.value); Cwtratecardhandler(e); handleCity(e) }} value={cwtRateUpdate.mLCwtRateCardCity?.fromCity?.fromprov || ""} required>

                                                <option value={cwtRateUpdate.mLCwtRateCardCity?.fromCity?.fromprovName || "Select"}>{cwtRateUpdate.mLCwtRateCardCity?.fromCity?.fromprovName || "Select"}</option>:
                                                <>
                                                  {
                                                    province?.data?.map((data, index) => (
                                                      <option key={index} value={data.provineStateID} selected={data.provineStateID === cwtRateUpdate.mLCwtRateCardCity?.fromCity?.fromprov ? true : false}>{data.provineSateName}</option>
                                                    ))

                                                  }
                                                </>

                                              </Form.Select>

                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group controlId="country" className="mb-2">
                                            <FloatingLabel controlId="floatingSelectGrid" label="From Country">
                                              <Form.Select aria-label="Floating label select example" name="fromcountry" onChange={e => { handlecountry(e); Cwtratecardhandler(e) }} value={cwtRateUpdate.mLCwtRateCardCity?.fromCity?.fromcountry || ""} required>

                                                <option>Select</option>:
                                                <>
                                                  {
                                                    country?.data?.map((data, index) => (
                                                      <option key={index} value={data.countryId}>{data.countryname}</option>
                                                    ))

                                                  }
                                                </>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                        </Col>
                                      </Row>

                                    </fieldset>
                                  </Col>
                                  <Col sm={6}>
                                    <fieldset className="pb-2 mt-2">
                                      <label className="label-heading">To</label>

                                      <Row>
                                        <Col sm={12} className="mb-3">
                                          <Form.Group controlId="to" className="mb-2">
                                            <FloatingLabel controlId="floatingSelectGrid" label="To City">
                                              <Form.Select aria-label="Floating label select example" name="tocity" onChange={e => { Cwtratecardhandler(e); setSelectCity(e.target.value) }} value={cwtRateUpdate.mLCwtRateCardCity?.toCity?.tocity || ""} required>
                                                <option value={cwtRateUpdate.mLCwtRateCardCity?.toCity?.tocityName || "Select"}>{cwtRateUpdate.mLCwtRateCardCity?.toCity?.tocityName || "Select"}</option>:
                                                <>
                                                  {
                                                    toCity?.data?.map((data, index) => (
                                                      <option key={index} value={data.id}>{data.city}</option>
                                                    ))

                                                  }
                                                </>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group controlId="Province/State" className="mb-2">
                                            <FloatingLabel controlId="floatingSelectGrid" label="To Province/State">
                                              <Form.Select aria-label="Floating label select example" name="toprov" onChange={(e) => { setSelectToState(e.target.value); Cwtratecardhandler(e); handleToCity(e) }} value={cwtRateUpdate.mLCwtRateCardCity?.toCity?.toprov || ""} required>

                                                <option value={cwtRateUpdate.mLCwtRateCardCity?.toCity?.toprovName || "Select"}>{cwtRateUpdate.mLCwtRateCardCity?.toCity?.toprovName || "Select"}</option>:
                                                <>
                                                  {
                                                    toProvince?.data?.map((data, index) => (
                                                      <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                                    ))

                                                  }
                                                </>

                                              </Form.Select>

                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group controlId="country" className="mb-2">
                                            <FloatingLabel controlId="floatingSelectGrid" label="To Country">
                                              <Form.Select aria-label="Floating label select example" name="tocountry" onChange={e => { handletocountry(e); Cwtratecardhandler(e) }} value={cwtRateUpdate.mLCwtRateCardCity?.toCity?.tocountry || ""} required>

                                                <option value="">Select</option>:
                                                <>
                                                  {
                                                    toCountry?.data?.map((data, index) => (
                                                      <option key={index} value={data.countryId}>{data.countryname}</option>
                                                    ))

                                                  }
                                                </>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                        </Col>
                                      </Row>

                                    </fieldset>
                                  </Col>
                                </Row>

                              </fieldset>
                            </Col>
                          </Row>
                        </>}
                        {cwtRateUpdate?.mLCWTRatecarditeam?.rateType === "CWT" ? (
                          <Form className="cwt_form">
                            <Row className="mt-4 mb-3">
                              <Col sm={4}>
                                <Form.Group controlId="minimumrate">
                                  <FloatingLabel controlId="floatingInput" label="CWT Minimum Rate" >
                                    <Form.Control type="number" placeholder="CWT Minimum Rate" name="minimumRate" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.minimumRate || ""} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={4}>
                                <Form.Group controlId="Average Weight">
                                  <FloatingLabel controlId="floatingInput" label="Average Weight" >
                                    <Form.Control type="number" placeholder="Average Weight" name="averageWeight" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.averageWeight || ""} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={4}>
                                <Form.Group controlId="FTL Rate">
                                  <FloatingLabel controlId="floatingInput" label="FTL Rate" >
                                    <Form.Control type="number" placeholder="FTL Rate" name="ftlRate" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.ftlRate || ""} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-2 range_label d-flex align-items-end">
                              <Col sm={2}>
                                <h6>Range from (0-500) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP0TOLTL" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP0TOLTL || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range from (500-1000) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP500TO1000" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP500TO1000 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range from (1000-2000) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP1000TO2000" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP1000TO2000 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range from (2000-5000) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP2000TO5000" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP2000TO5000 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range from (5000-10000) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP5000TO10000" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP5000TO10000 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range from (10000-20000) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP10000TO20000" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP10000TO20000 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        ) : cwtRateUpdate?.mLCWTRatecarditeam?.rateType === "PALLET" ? (
                          <Form className="cwt_form">
                            <Row>
                              {/* <Col sm={6}>
                                                            <Form.Group controlId="PalletMinimumRate">
                                                                <FloatingLabel controlId="floatingInput" label="Pallet Minimum Rate" >
                                                                    <Form.Control type="number" placeholder="Pallet Minimum Rate" name="minimumRate" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.minimumRate || ''} />
                                                                </FloatingLabel>
                                                            </Form.Group>
                                                        </Col> */}
                              <Col sm={6}>
                                <Form.Group controlId="palletaverageweight">
                                  <FloatingLabel controlId="floatingInput" label="Pallet Average Weight" >
                                    <Form.Control type="number" placeholder="Pallet Average Weight" name="averageWeight" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.averageWeight || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-3 range_label">
                              <Col sm={2}>
                                <h6>Range From (0-1)</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP0TO1" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.unitRateP0TO1 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range From (1-5)</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP1TO5" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.unitRateP1TO5 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range From (5-10)</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP5TO10" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.unitRateP5TO10 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range From (10-16)</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP10TO16" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.unitRateP10TO16 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range From (16-21)</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP16TO21" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.unitRateP16TO21 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range From (21-24)</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP21TO24" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.unitRateP21TO24 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col sm={12}>
                                <fieldset className="pb-2">
                                  <label className="label-heading">Pallet OverWeight Charge Section</label>
                                  <Row className="range_label">
                                    <Col sm={2}>
                                      <h6>Range From (0-100)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP0TO100" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP0TO100 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <h6>Range From (100-200)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP100TO200" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP100TO200 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <h6>Range From (200-300)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP200TO300" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP200TO300 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <h6>Range From (300-400)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP300TO400" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP300TO400 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <h6>Range From (400-500)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP400TO500" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP400TO500 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <h6>Range From (500-600)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP500TO600" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP500TO600 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </fieldset>
                              </Col>
                            </Row>
                          </Form>
                        ) : cwtStatus === 1 ? (
                          <Form className="cwt_form">
                            <Row className="mt-4 mb-3">
                              <Col sm={6}>
                                <Form.Group controlId="minimumrate">
                                  <FloatingLabel controlId="floatingInput" label="CWT Minimum Rate" >
                                    <Form.Control type="number" placeholder="CWT Minimum Rate" name="minimumRate" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.minimumRate || ""} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              {/* <Col sm={4}>
                                                        <Form.Group controlId="Average Weight">
                                                            <FloatingLabel controlId="floatingInput" label="Average Weight" >
                                                                <Form.Control type="number" placeholder="Average Weight" name="averageWeight" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.averageWeight || ""} />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                    </Col> */}
                              <Col sm={6}>
                                <Form.Group controlId="FTL Rate">
                                  <FloatingLabel controlId="floatingInput" label="FTL Rate" >
                                    <Form.Control type="number" placeholder="FTL Rate" name="ftlRate" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.ftlRate || ""} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-2 range_label">
                              <Col sm={2}>
                                <h6>Range from (0-500) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP0TOLTL" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP0TOLTL || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range from (500-1000) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP500TO1000" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP500TO1000 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range from (1000-2000) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP1000TO2000" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP1000TO2000 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range from (2000-5000) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP2000TO5000" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP2000TO5000 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range from (5000-10000) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP5000TO10000" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP5000TO10000 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <h6>Range from (10000-20000) </h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit rate" >
                                    <Form.Control type="number" placeholder="Unit Rate" name="unitRateP10000TO20000" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypeCWT?.unitRateP10000TO20000 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        ) : cwtStatus === 2 ? (
                          <Form className="cwt_form">
                            <Row>
                              {/* <Col sm={4}>
                                                      <Form.Group controlId="PalletMinimumRate">
                                                        <FloatingLabel controlId="floatingInput" label="Pallet Minimum Rate" >
                                                          <Form.Control type="number" placeholder="Pallet Minimum Rate" name="MinimumRate" onChange={e => {cwtRatecardHandler(e);setCustomEditFields(false)}} value={cwtRatecardData?.MinimumRate || ''} />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                    </Col> */}
                              <Col sm={6}>
                                <Form.Group controlId="palletaverageweight">
                                  <FloatingLabel controlId="floatingInput" label="Pallet Average Weight" >
                                    <Form.Control type="text" placeholder="Pallet Average Weight" name="AverageWeight" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.AverageWeight || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={6}>
                                <Form.Group controlId="ftlrate">
                                  <FloatingLabel controlId="floatingInput" label="FTL Rate" >
                                    <Form.Control type="number" placeholder="FTL Rate" name="FTLRate" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.FTLRate || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-3 range_label">
                              <Col sm={1}>
                                <h6>1</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate1" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate1 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>2</h6>
                                <Form.Group controlId="UnitRate2">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate2" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate2 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>3</h6>
                                <Form.Group controlId="UnitRate3">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate3" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate3 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>4</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate4" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate4 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>5</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate5" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate5 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>6</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate6" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate6 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>7</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate7" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate7 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>8</h6>
                                <Form.Group controlId="UnitRate8">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate8" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate8 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>9</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate9" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate9 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>10</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate10" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate10 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>11</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate11" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate11 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>12</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate12" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate12 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-3 range_label">
                              <Col sm={1}>
                                <h6>13</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate13" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate13 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>14</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate14" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate14 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>15</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate15" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate15 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>16</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate16" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate16 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>17</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate17" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate17 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>18</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate18" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate18 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>19</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate19" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate19 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>20</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate20" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate20 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>21</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate21" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate21 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>22</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate22" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate22 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>23</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate23" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate23 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col sm={1}>
                                <h6>24</h6>
                                <Form.Group controlId="RangeFrom">
                                  <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                    <Form.Control type="text" placeholder="Unit Rate" name="UnitRate24" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate24 || ''} />
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col sm={12}>
                                <fieldset className="pb-2">
                                  <label className="label-heading">Pallet OverWeight Charge Section</label>
                                  <Row className="range_label">
                                    <Col sm={2}>
                                      <h6>Range From (0-100)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP0TO100" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP0TO100 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <h6>Range From (100-200)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP100TO200" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP100TO200 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <h6>Range From (200-300)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP200TO300" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP200TO300 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <h6>Range From (300-400)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP300TO400" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP300TO400 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <h6>Range From (400-500)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP400TO500" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP400TO500 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <h6>Range From (500-600)</h6>
                                      <Form.Group controlId="RangeFrom">
                                        <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                          <Form.Control type="number" placeholder="Overweight Charge" name="chargeP500TO600" onChange={e => Cwtratecardhandler(e)} value={cwtRateUpdate.mLCWTRatecarditeam?.rateTypePallet?.chargeP500TO600 || ''} />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </fieldset>
                              </Col>
                            </Row>
                          </Form>
                        ) : (
                          null
                        )
                        }
                      </Row>

                    </fieldset>
                  </Col>
                </Row>
              </>
              : null}
            {/*  */}
            {/* table */}
            {/* <DataGridDemo cwtRatecardList={cwtRatecardList} EditCwtItem={EditCwtItem}  handleClose={handleCwtClose} handleCwtOpen={handleCwtOpen} open={cwtOpen} /> */}
            {(cwtRateCardLists[0]?.mLCWTRatecarditeam?.RateType === 'CWT') &&
              <>
                <div className="datatable_section">
                  <DataGrid rows={testRows} columns={CWTColumns} pageSize={10} pagination components={{ Toolbar: GridToolbar }}   disableVirtualization />
                </div> </>}
            {(cwtRateCardLists[0]?.mLCWTRatecarditeam.RateType === 'PALLET') &&
              <>
                <div className="datatable_section">
                  <DataGrid rows={testRows} columns={palletColumns} pageSize={10} pagination components={{ Toolbar: GridToolbar }}  disableVirtualization  />
                </div> </>}
          </fieldset>
        </Col>
      </Row>

      <ToastContainer />
    </>
  );
}
export default EditCwtRatecard;