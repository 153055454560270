import React from 'react';
import { createContext, useContext, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { useLazyGetCustomerByIDQuery } from '../features/customer/customerSlice';
import { useCreateCustomerMutation, useCreateCustomerNameAddressMutation, useCreateCustomerContractPricingMutation, useCreateSpecialAddressMutation } from '../features/customer/customerSlice';
import { resetCwtRateCard, useAddDefaultPricingRateCardMutation, useLazyGetDefaultPricingRateCardQuery, addCwtRateCardMultiple } from '../features/cwtRateCard/cwtRateCardSlice';
import { useGetCustomerQuery } from '../features/customer/customerSlice';
import Validatecustomer from '../Component/Customer/ValidateCustomer';
import Validatecontract from '../Component/Customer/ValidateContract';
import { useCallback } from 'react';
import { useDispatch } from "react-redux";
import { addVehicle, resetVehicle } from "../features/vehicle/vehicleDetailSlice";
import { addService, resetService } from '../features/service/serviceDetailSlice';
import { addAccessorial, resetAccessorial } from '../features/accessorial/accessorialDetailSlice';
import { addFullLoad, resetFullLoad } from "../features/fullLoad/fullLoadSlice";
import { addLessLoad, resetLessLoad } from "../features/lessLoad/lessLoadSlice";
import { addHeavyLoad, resetHeavyLoad } from "../features/heavyLoad/heavyLoadSlice";
import { addCityDistance, resetCityDistance } from '../features/cityDistance/cityDistanceSlice';
import { addDistanceFuel, resetDistanceFuel } from '../features/distanceFuel/distanceFuelSlice';
import { useGetCityWithoutProvinceQuery, useGetProvinceWithoutCountryQuery } from '../features/countryState/countryStateSlice';

const CustomerContext = createContext();


export const CreateCustomer = ({ children }) => {
  const { refetch } = useGetCustomerQuery()
  const [getCustomerDetails] = useLazyGetCustomerByIDQuery()
  const [getDefaultRateCard] = useLazyGetDefaultPricingRateCardQuery();
  const [createCustomer] = useCreateCustomerMutation()
  const [createCustomerNameAddress] = useCreateCustomerNameAddressMutation();
  const [createCustomerContractPricing] = useCreateCustomerContractPricingMutation();
  const [addDefaultCard] = useAddDefaultPricingRateCardMutation();
  const [createSpecialAddress] = useCreateSpecialAddressMutation();
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [singleAccount, setSingleAccount] = useState([]);
  const [contactDetail, setContactDetail] = useState({});
  const [contractInfo, setContractInfo] = useState({});
  const [vehicleSurchargeList, setVehicleSurchargeList] = useState([]);
  const [serviceSurchargeList, setServiceSurchargeList] = useState([]);
  const [accessorialList, setAccessorialList] = useState({});
  const [cwtRatecardList, setCwtRateCardList] = useState({});
  const [cityDistanceList, setCityDistanceList] = useState({})
  const [fuelDistanceList, setFuelDistanceList] = useState({});
  const [lessTruckLoad, setLessTruckLoad] = useState({});
  const [fullTruckLoad, setFullTruckLoad] = useState({});
  const [heavyTruckLoad, setHeavyTruckLoad] = useState({});
  const [fuelCostLists, setFuelCostLists] = useState([]);
  const [defaultCardData, setDefaultCardData] = useState({});
  const [specialAddressList, setSpecialAddressList] = useState([]);
  const [specialAddress, setSpecialAddress] = useState({});
  const [selected, setSelected] = useState(false)
  const [showNotes, setShowNotes] = useState(false);
  const [errors, setErrors] = useState({});
  const [addressData, setAddressData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [tempDisplay, setTempDisplay] = useState(false)
  const [multiplier, setMultiplier] = useState('');
  const { data: cityList } = useGetCityWithoutProvinceQuery();
  const { data: province } = useGetProvinceWithoutCountryQuery();
  const dispatch = useDispatch();

  const getCityID = (name) => { const cityObj = cityList?.data.find(d => d.city == name); return cityObj?.id; }

  const getProvinceID = (name) => { const provinceObj = province?.data.find(d => d.provineSateName == name); return provinceObj?.provineStateID; }
  
  const nameAddress = useSelector((state) => state.nameAddress.list)
  const contactLists = (useSelector((state) => state.contactDetails.list)).map((el) => {
    return ({ id: el.id, ...el.list })
  })
  const contractLists = useSelector((state) => state.contractData.list)
  const vehicleLists = (useSelector((state) => state.vehicleDetail.list)).map((el) => {
    return ({ id: el.id, ...el.list })
  })
  const serviceLists = (useSelector((state) => state.serviceDetail.list)).map((el) => {
    return ({ id: el.id, ...el.list })
  })
  const accessorialLists = (useSelector((state) => state.accessorialDetail.list)).map((el) => {
//     const newVehicleType = el.list.vehicleType
// const KeysToCheck = ["value","label"]
    // if(newVehicleType.every(obj=>KeysToCheck.every(key=>obj.hasOwnProperty((key))))){
    //   const newOzbject = newVehicleType.flatMap(obj => Object.values(obj))
    // const newList
     
    // }else{

    return ({ id: el.id, ...el.list })
    // }

  })
  const cwtRateCardLists = (useSelector((state) => state.cwtRateCardData.list)).map((el) => {
    return ({
      id: el.id, MinimumRate: el.list.MinimumRate, AverageWeight: el.list.AverageWeight, FTLRate: el.list.FTLRate, cwtRateCardId: el.list.cwtRateCardId,
      mLCwtRateCardCity: ({
        fromCity: ({
          fromcity: el.list.fromcity, fromcountry: el.list.fromcountry, fromprov: el.list.fromprov,
        }),
        toCity: ({ tocity: el.list.tocity, tocountry: el.list.tocountry, toprov: el.list.toprov })
      }), mLCWTRatecarditeam: ({
        RateType: el.list.RateType,
        rateTypeCWT: ({
          UnitRateP0TOLTL: el.list.UnitRateP0TOLTL, UnitRateP500TO1000: el.list.UnitRateP500TO1000, UnitRateP1000TO2000: el.list.UnitRateP1000TO2000,
          UnitRateP2000TO5000: el.list.UnitRateP2000TO5000, UnitRateP5000TO10000: el.list.UnitRateP5000TO10000, UnitRateP10000TO20000: el.list.UnitRateP10000TO20000,
        }),
        rateTypePallet: ({
          UnitRateP0TO1: el.list.UnitRateP0TO1, UnitRateP1TO5: el.list.UnitRateP1TO5, UnitRateP5TO10: el.list.UnitRateP5TO10,
          UnitRateP10TO16: el.list.UnitRateP10TO16, UnitRateP16TO21: el.list.UnitRateP16TO21, UnitRateP21TO24: el.list.UnitRateP21TO24,
          ChargeP0TO100: el.list.ChargeP0TO100, ChargeP100TO200: el.list.ChargeP100TO200, ChargeP200TO300: el.list.ChargeP200TO300,
          ChargeP300TO400: el.list.ChargeP300TO400, ChargeP400TO500: el.list.ChargeP400TO500, ChargeP500TO600: el.list.ChargeP500TO600, 
          UnitRate1: el.list.UnitRate1, UnitRate2: el.list.UnitRate2, UnitRate3: el.list.UnitRate3, UnitRate4: el.list.UnitRate4, UnitRate5: el.list.UnitRate5, UnitRate6: el.list.UnitRate6,
          UnitRate7: el.list.UnitRate7, UnitRate8: el.list.UnitRate8, UnitRate9: el.list.UnitRate9, UnitRate10: el.list.UnitRate10, UnitRate11: el.list.UnitRate11, UnitRate12: el.list.UnitRate12,
          UnitRate13: el.list.UnitRate13, UnitRate14: el.list.UnitRate14, UnitRate15: el.list.UnitRate15, UnitRate16: el.list.UnitRate16, UnitRate17: el.list.UnitRate17, UnitRate18: el.list.UnitRate18,
          UnitRate19: el.list.UnitRate19, UnitRate20: el.list.UnitRate20, UnitRate21: el.list.UnitRate21, UnitRate22: el.list.UnitRate22, UnitRate23: el.list.UnitRate23, UnitRate24: el.list.UnitRate24,
        })
      })
    })
  })
  const cityDistanceLists = (useSelector((state) => state.cityDistance.list)).map((el) => {
    return ({
      id: el.id, FromCountry: el.list.FromCountry, FromCountryName: el.list.FromCountryName, FromProvince: el.list.FromProvince, 
      FromProvinceName: el.list.FromProvinceName, FromCity: el.list.FromCity, FromCityName: el.list.FromCityName,
      ToCountry: el.list.ToCountry, ToCountryName: el.list.ToCountryName, ToProvince: el.list.ToProvince, 
      ToProvinceName: el.list.ToProvinceName, ToCity: el.list.ToCity, ToCityName: el.list.ToCityName, Miles: el.list.Miles, 
      TimeInMinutes: el.list.TimeInMinutes, CityDistanceId: el.list.CityDistanceID
    })
  })
  const distanceFuelLists = (useSelector((state) => state.distanceFuel.list)).map((el) => {
    return ({ id: el.id, ...el.list })
  })
  const lessFuelLists = (useSelector((state) => state.lessLoad.list)).map((el) => {
    return ({ id: el.id, LTLWeghtType: "LTL", ...el.list })
  })

  const fullFuelLists = (useSelector((state) => state.fullLoad.list)).map((el) => {
    return ({ id: el.id, FTLWeghtType: "FTL", ...el.list })
  })

  const heavyFuelLists = (useSelector((state) => state.heavyLoad.list)).map((el) => {
    return ({ id: el.id, HTLWeghtType: "HTL", ...el.list })
  })

  // const fuelCostLists = (useSelector((state) => state.fuelCost.list)).map((el) => {
  //   return ({  /* fuelCostId:  el.id, */ ...el.list })
  // })

  const fixRateCardMap = () => {
    const tempRateCard = cwtRateCardLists.map((rateCard) => 
      { 
        const fromCountry = rateCard.mLCwtRateCardCity.fromCity.fromcountry || null;
        const toCountry = rateCard.mLCwtRateCardCity.toCity.tocountry || null;
        return {...rateCard, mLCwtRateCardCity: 
              {fromCity: 
                {fromcity: rateCard.mLCwtRateCardCity.fromCity.fromcity.id || 1,
                  fromcityName: rateCard.mLCwtRateCardCity.fromCity.fromcity.city || "",
                  fromcountry: rateCard.mLCwtRateCardCity.fromCity.fromcountry || "",
                  fromcountryName: fromCountry && fromCountry === '1'? 'CA': fromCountry && fromCountry === '2'? 'USA': "",
                  fromprov: rateCard.mLCwtRateCardCity.fromCity.fromprov.provineStateID || 1,
                  fromprovName: rateCard.mLCwtRateCardCity.fromCity.fromprov.provineSateName || "",
                },
                toCity: {
                  tocity: rateCard.mLCwtRateCardCity.toCity.tocity.id || 1,
                  tocityName: rateCard.mLCwtRateCardCity.toCity.tocity.city || "",
                  tocountry: rateCard.mLCwtRateCardCity.toCity.tocountry || "",
                  tocountryName: toCountry && toCountry === '1'? 'CA': toCountry && toCountry === '2'? 'USA': "",
                  toprov: rateCard.mLCwtRateCardCity.toCity.toprov.provineStateID || 1,
                  toprovName: rateCard.mLCwtRateCardCity.toCity.toprov.provineSateName || "",
                }  }
        }})
    return tempRateCard;
  }

  const capitalizeObjectKeys = (obj) => {
    const capitalizedObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            capitalizedObj[capitalizedKey] = obj[key];
        }
    }
    return capitalizedObj;
}

  const multiplierVehicle = (vehicles) => {
    if (multiplier && multiplier !== "") {
    const mult = 1 + (multiplier / 100);
    const newVehicleSurcharge = vehicles.map((el) => {
      return ({ ...el, BaseRate: el.BaseRate * mult, CrossoverRate: el.CrossoverRate * mult, PieceRate: el.PieceRate * mult, WeightRate: el.WeightRate * mult, WaitingTimeUnitRate: el.WaitingTimeUnitRate * mult })
    })
    const vehicleData = newVehicleSurcharge.map((el) => {
      return ({ ...el, BaseRate: el.BaseRate && el.BaseRateType === 'A'? parseFloat(el.BaseRate).toFixed(2) : el.BaseRate,
        CrossoverRate: el.CrossoverRate && el.CrossoverRateType === 'A'? parseFloat(el.CrossoverRate).toFixed(2) : el.CrossoverRate,
        PieceRate: el.PieceRate && el.PieceRateType === 'A'? parseFloat(el.PieceRate).toFixed(2) : el.PieceRate,
        WeightRate: el.WeightRate && el.WeightRateType === 'A'? parseFloat(el.WeightRate).toFixed(2) : el.WeightRate,
        WaitingTimeUnitRate: el.WaitingTimeUnitRate && el.WaitingTimeUnitRateType === 'A'? parseFloat(el.WaitingTimeUnitRate).toFixed(2) : el.WaitingTimeUnitRate,
      })
    })
    return vehicleData;
    }
    else {
      const vehicleData = vehicles.map((el) => {
        return ({ ...el, BaseRate: el.BaseRate && el.BaseRateType === 'A'? parseFloat(el.BaseRate).toFixed(2) : el.BaseRate,
          CrossoverRate: el.CrossoverRate && el.CrossoverRateType === 'A'? parseFloat(el.CrossoverRate).toFixed(2) : el.CrossoverRate,
          PieceRate: el.PieceRate && el.PieceRateType === 'A'? parseFloat(el.PieceRate).toFixed(2) : el.PieceRate,
          WeightRate: el.WeightRate && el.WeightRateType === 'A'? parseFloat(el.WeightRate).toFixed(2) : el.WeightRate,
          WaitingTimeUnitRate: el.WaitingTimeUnitRate && el.WaitingTimeUnitRateType === 'A'? parseFloat(el.WaitingTimeUnitRate).toFixed(2) : el.WaitingTimeUnitRate,
        })
      })
      return vehicleData;
    }
  }

  const multiplerService = (services) => {
    if (multiplier && multiplier !== "") {
      const mult = 1 + (multiplier / 100);
      const newServiceSurcharge = services.map((el) => {
        return ({ ...el, BaseRate: el.BaseRate * mult, CrossoverRate: el.CrossoverRate * mult, PieceRate: el.PieceRate * mult, WeightRate: el.WeightRate * mult})
      })
      const serviceData = newServiceSurcharge.map((el) => {
        return ({ ...el, BaseRate: el.BaseRate && el.BaseRateType === 'A'? parseFloat(el.BaseRate).toFixed(2) : el.BaseRate,
          CrossoverRate: el.CrossoverRate && el.CrossoverRateType === 'A'? parseFloat(el.CrossoverRate).toFixed(2) : el.CrossoverRate,
          PieceRate: el.PieceRate && el.PieceRateType === 'A'? parseFloat(el.PieceRate).toFixed(2) : el.PieceRate,
          WeightRate: el.WeightRate && el.WeightRateType === 'A'? parseFloat(el.WeightRate).toFixed(2) : el.WeightRate,
        })
      })
      return serviceData;
    }
    else {
      const serviceData = services.map((el) => {
        return ({ ...el, BaseRate: el.BaseRate && el.BaseRateType === 'A'? parseFloat(el.BaseRate).toFixed(2) : el.BaseRate,
          CrossoverRate: el.CrossoverRate && el.CrossoverRateType === 'A'? parseFloat(el.CrossoverRate).toFixed(2) : el.CrossoverRate,
          PieceRate: el.PieceRate && el.PieceRateType === 'A'? parseFloat(el.PieceRate).toFixed(2) : el.PieceRate,
          WeightRate: el.WeightRate && el.WeightRateType === 'A'? parseFloat(el.WeightRate).toFixed(2) : el.WeightRate,
        })
      })
      return serviceData;
    }
  }

  const multiplierAccessorial = (accessorials) => {
    if (multiplier && multiplier !== "") {
      const mult = 1 + (multiplier / 100);
      const newAccessorialSurcharge = accessorials.map((el) => {
        return ({ ...el, TimeRate: el.TimeRate * mult, Rate: el.Rate * mult})
      })
      const accessorialData = newAccessorialSurcharge.map((el) => {
        return ({ ...el, TimeRate: el.TimeRate && el.TimeRateType === 'A'? parseFloat(el.TimeRate).toFixed(2) : el.TimeRate,
          Rate: el.Rate && el.RateType === 'A'? parseFloat(el.Rate).toFixed(2) : el.Rate,
        })
      })
      return accessorialData;
    }
    else {
      const accessorialData = accessorials.map((el) => {
        return ({ ...el, TimeRate: el.TimeRate && el.TimeRateType === 'A'? parseFloat(el.TimeRate).toFixed(2) : el.TimeRate,
          Rate: el.Rate && el.RateType === 'A'? parseFloat(el.Rate).toFixed(2) : el.Rate,
        })
      })
      return accessorialData;
    }
  }

  const validateAddSubmit = async () => {
    //setErrors(Validatecustomer(addressData));
    if (Object.keys(errors).length !== 0 && Object.keys(addressData).length === 0 && isSubmitting && Object.keys(contractInfo).length === 0) {
      setIsSubmitting(true);
      setErrors(Validatecustomer(addressData));
    }
    else if (Object.keys(errors).length !== 0) {
      setErrors(Validatecustomer(addressData));
      toast.info("Fill the name/address data !", {
        position: "top-center",
      })
    }
    else if (Object.keys(addressData).length === 0) {
      setErrors(Validatecustomer(addressData));
      toast.info("Please fill required field !", {
        position: "top-center",
      })
    }
    else if (Object.keys(contractInfo).length === 0) {
      setErrors(Validatecontract(contractInfo));
      toast.info("Fill contract Info data !", {
        position: "top-center",
      })
    }
    else if (Object.keys(contactLists).length === 0) {
      setErrors(Validatecustomer(addressData));
      setTimeout(function () {
        toast.info("Fill the contact details field !", {
          position: "top-center",
        })
      },);
    }
    else if (Object.keys(vehicleLists).length === 0) {
      setErrors(Validatecustomer(addressData));
      setTimeout(function () {
        toast.info("Fill the vehicle surcharge field !", {
          position: "top-center",
        })
      },);
    }
    else if (Object.keys(serviceLists).length === 0) {
      setErrors(Validatecustomer(addressData));
      setTimeout(function () {
        toast.info("Fill the service surcharge field !", {
          position: "top-center",
        })
      },);
    }
    // else if (Object.keys(accessorialLists).length === 0 ){
    //   setErrors(Validatecustomer(addressData));
    //   setTimeout(function () {
    //     toast.info("Fill the accessorial surcharge field !", {
    //       position: "top-center",
    //     })
    //   }, );
    // }
    // else if (Object.keys(cwtRateCardLists).length === 0 ){
    //   setErrors(Validatecustomer(addressData));
    //   setTimeout(function () {
    //     toast.info("Fill the CWT rate card section field !", {
    //       position: "top-center",
    //     })
    //   }, );
    // }
    // else if (Object.keys(cityDistanceLists).length === 0 ){
    //   setErrors(Validatecustomer(addressData));
    //   setTimeout(function () {
    //     toast.info("Fill the city distance field !", {
    //       position: "top-center",
    //     })
    //   }, );
    // }
    // else if (Object.keys(distanceFuelLists).length === 0 ){
    //   setErrors(Validatecustomer(addressData));
    //   setTimeout(function () {
    //     toast.info("Fill the distance fuel field !", {
    //       position: "top-center",
    //     })
    //   }, );
    // }
    // else if (Object.keys(lessFuelLists).length === 0 ){
    //   setErrors(Validatecustomer(addressData));
    //   setTimeout(function () {
    //     toast.info("Fill the less equipment field !", {
    //       position: "top-center",
    //     })
    //   }, );
    // }
    // else if (Object.keys(fullFuelLists).length === 0 ){
    //   setErrors(Validatecustomer(addressData));
    //   setTimeout(function () {
    //     toast.info("Fill the full equipment field !", {
    //       position: "top-center",
    //     })
    //   }, );
    // }
    // else if (Object.keys(heavyFuelLists).length === 0 ){
    //   setErrors(Validatecustomer(addressData));
    //   setTimeout(function () {
    //     toast.info("Fill the heavy equipment field !", {
    //       position: "top-center",
    //     })
    //   }, );
    // }
    else {
      setLoading(true)
      const addCustomer = {
        mLNameAddress: ({ ...nameAddress, mLDetailsList: contactLists }),
        mLContractInfo: ({
          ...contractLists, serviceSurchargeList: serviceLists, VehchileSurchargeList: vehicleLists,
          AccessorialChargeSurchargeList: accessorialLists, mLCWTRateCardsList: cwtRateCardLists, mLFuelsList: cityDistanceLists, mLFuelDistanceSurcharges: distanceFuelLists,
          FTLLiST: fullFuelLists, LTLList: lessFuelLists, HTLList: heavyFuelLists
        })
      }
      const customerData = await createCustomer(addCustomer)
      if (customerData?.data?.statusCode === 200) {
        toast.success("Customer created successfully !", {
          position: "top-center",
        })
        refetch()
        setTimeout(() => {
          window.location.reload(false)
        }, 5000)
      }
      else if (customerData?.data?.statusCode === 400) {
        toast.info("EmailID already exist !", {
          position: "top-center",
        })
      }
      else if (customerData?.error.data?.StatusCode === 0) {
        toast.info("Customer is created with missing section !", {
          position: "top-center",
        })
      }
      // else if (customerData?.data?.statusCode === 400) {
      //   toast.info("EmailID already exist !", {
      //     position: "top-center",
      //   })
      // }
      refetch()
      setLoading(false)
    }
  }

  const validateNameAddress = async () => {
    setErrors(Validatecustomer(addressData));
    if (Object.keys(errors).length !== 0 && Object.keys(addressData).length === 0 && isSubmitting && Object.keys(contractInfo).length === 0) {
      setIsSubmitting(true);
      setErrors(Validatecustomer(addressData));
    }
    // else if (Object.keys(errors).length !== 0) {
    //   setErrors(Validatecustomer(addressData));
    //   toast.info("Fill the name/addressss data !", {
    //     position: "top-center",
    //   })
    // }
    else if (Object.keys(addressData).length === 0) {
      setErrors(Validatecustomer(addressData));
      toast.info("Please fill required field !", {
        position: "top-center",
      })
    }
    else {
      setLoading(true);
      const addCustomer = { ...nameAddress, mLDetailsList: contactLists, isNotesAppearOnOrder: showNotes};
      const customerData = await createCustomerNameAddress(addCustomer);
      if (customerData?.data?.statusCode === 200) {
        toast.success("Customer created successfully !", {
          position: "top-center",
        })
        refetch()
        setTimeout(() => {
          window.location.reload(false)
        }, 5000)
      }
      else if (customerData?.data?.statusCode === 400) {
        toast.info("EmailID already exist !", {
          position: "top-center",
        })
      }
      else if (customerData?.error.data?.StatusCode === 0) {
        toast.info("Please fill in missing data fields !", {
          position: "top-center",
        })
      }
      else if (customerData?.error.data?.StatusCode === 500) {
        toast.info("Server error !", {
          position: "top-center",
        })
      }
      refetch();
      setLoading(false);
    }
  }

  const validateContractPricing = async (id) => {
    if (Object.keys(errors).length !== 0 && isSubmitting) {
      setIsSubmitting(true);
      setErrors(Validatecustomer(addressData));
    }
    else if (Object.keys(contractInfo).length === 0) {
      setErrors(Validatecontract(contractInfo));
      toast.info("Fill contract Info data !", {
        position: "top-center",
      })
    }
    else if (Object.keys(vehicleLists).length === 0) {
      setErrors(Validatecustomer(addressData));
      setTimeout(function () {
        toast.info("Fill the vehicle surcharge field !", {
          position: "top-center",
        })
      },);
    }
    else if (Object.keys(serviceLists).length === 0) {
      setErrors(Validatecustomer(addressData));
      setTimeout(function () {
        toast.info("Fill the service surcharge field !", {
          position: "top-center",
        })
      },);
    }
    else {
      setLoading(true);
      const rateCardList = fixRateCardMap();
      const description = contractInfo.Description;
      const longFreightAmt = contractInfo.longFeightAmt || "";
      const addCustomer = {
          ...contractLists, accNumber: singleAccount[0].account_No,
          mLContractInfo: { ...contractInfo, RateCardId: id, multiplier: multiplier, Description: description, VehchileSurchargeList: multiplierVehicle(vehicleLists), serviceSurchargeList: multiplerService(serviceLists), 
          AccessorialChargeSurchargeList: multiplierAccessorial(accessorialLists), mLCWTRateCardsList: rateCardList, mLFuelsList: cityDistanceLists, 
          mLFuelDistanceSurcharges: distanceFuelLists, HTLList: heavyFuelLists, LTLList: lessFuelLists, FTLLiST: fullFuelLists, mLFuelSurchargeCosts: fuelCostLists}, longFeightAmount: longFreightAmt,
      }
      const customerData = await createCustomerContractPricing(addCustomer);
      if (customerData?.data?.statusCode === 200) {
        toast.success("Rate card added successfully !", {
          position: "top-center",
        })
        refetch()
        setTimeout(() => {
          window.location.reload(false)
        }, 5000)
      }
      else if (customerData?.data?.statusCode === 400) {
        toast.info("Something went wrong !", {
          position: "top-center",
        })
      }
      else if (customerData?.error.data?.StatusCode === 0) {
        toast.info("Customer is created with missing section !", {
          position: "top-center",
        })
      }
      else if (customerData?.error.data?.StatusCode === 500) {
        toast.info("Server error !", {
          position: "top-center",
        })
      }
      refetch();
      setLoading(false);
    }
  }

  const addRateCardDefaultPricing = async () => {
    const rateCardList = fixRateCardMap();
    const description = contractInfo.Description;
    const longFreightAmt = contractInfo.longFeightAmt || "";
    const addCustomer = {
      mLContractInfo: { ...contractInfo, ...contractLists,
      multiplier: multiplier, Description: description, VehchileSurchargeList: vehicleLists, serviceSurchargeList: serviceLists, 
      AccessorialChargeSurchargeList: accessorialLists, mLCWTRateCardsList: rateCardList, mLFuelsList: cityDistanceLists, 
      mLFuelDistanceSurcharges: distanceFuelLists, HTLList: heavyFuelLists, LTLList: lessFuelLists, FTLLiST: fullFuelLists, mLFuelSurchargeCosts: fuelCostLists}, longFeightAmount: longFreightAmt,
  }
    const results = await addDefaultCard(addCustomer);
    if (results?.data?.result?.statusCode === 200) {
      toast.success("Default rate card added successfully!", {
        position: "top-center",
      })
      // setDefaultCardData(results?.data?.result?.data)
      refetch();
      setTimeout(() => {
        window.location.reload(false)
      }, 5000)
    }
    else if (results?.data?.result?.statusCode === 500) {
      toast.error(results.data.result.message, {
        position: "top-center",
      })
    }
  }

  const addSpecialAddress = async () => {
    if (!contractInfo[0] || !contractInfo[0].rateCardId) {
      toast.error("You do not have a rate card! Please create one.", {
        position: "top-center",
      })
      return;
    }
    const results = await createSpecialAddress(
      {...specialAddress, rateCardId: contractInfo[0].rateCardId, fuelSurcharge: specialAddress.fuelSurcharge || false, fromUnit: specialAddress.fromUnit || 0,
        toUnit: specialAddress.toUnit || 0, rateCardServiceLocationOverrideVehichleRateId: 0, rateCardServiceLocationOverrideId: 0,
      });
    if (results?.data?.statusCode === 200) {
      toast.success("Special address added sucessfully!", {
        position: "top-center",
      })
    }
    else if (results?.data?.statusCode === 400) {
      toast.error("Adding secial address failed!", {
        position: "top-center",
      })
    }
    return results;
  }

  const mapCWTRateCard = (cwtRateCardList) => {
    const mappedCwtRateCard = cwtRateCardList.map((rateCard) => {
      return { 
        cwtRateCardId: rateCard.cwtRateCardId, 
        FTLRate: rateCard.ftlRate,
        MinimumRate: rateCard.minimumRate,
        AverageWeight: rateCard.averageWeight,
        fromcity: { city: rateCard.mLCwtRateCardCity.fromCity.fromcityName, id: getCityID(rateCard.mLCwtRateCardCity.fromCity.fromcityName)}, 
        fromcountry: rateCard.mLCwtRateCardCity.fromCity.fromcountry, 
        fromprov: { provineSateName: rateCard.mLCwtRateCardCity.fromCity.fromprovName, provineStateID: getProvinceID(rateCard.mLCwtRateCardCity.fromCity.fromprovName) }, 
        tocity: { city: rateCard.mLCwtRateCardCity.toCity.tocityName, id: getCityID(rateCard.mLCwtRateCardCity.toCity.tocityName)}, 
        tocountry: rateCard.mLCwtRateCardCity.toCity.tocountry, 
        toprov: { provineSateName: rateCard.mLCwtRateCardCity.toCity.toprovName, provineStateID: getProvinceID(rateCard.mLCwtRateCardCity.toCity.toprovName)},
        RateType: rateCard.mLCWTRatecarditeam.rateType,
        UnitRateP0TOLTL: rateCard.mLCWTRatecarditeam.rateTypeCWT?.unitRateP0TOLTL,
        UnitRateP500TO1000: rateCard.mLCWTRatecarditeam.rateTypeCWT?.unitRateP500TO1000,
        UnitRateP1000TO2000: rateCard.mLCWTRatecarditeam.rateTypeCWT?.unitRateP1000TO2000,
        UnitRateP2000TO5000: rateCard.mLCWTRatecarditeam.rateTypeCWT?.unitRateP2000TO5000,
        UnitRateP5000TO10000: rateCard.mLCWTRatecarditeam.rateTypeCWT?.unitRateP5000TO10000,
        UnitRateP10000TO20000: rateCard.mLCWTRatecarditeam.rateTypeCWT?.unitRateP10000TO20000,
        UnitRate1: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate1,
        UnitRate2: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate2,
        UnitRate3: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate3,
        UnitRate4: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate4,
        UnitRate5: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate5,
        UnitRate6: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate6, 
        UnitRate7: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate7,
        UnitRate8: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate8,
        UnitRate9: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate9,
        UnitRate10: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate10,
        UnitRate11: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate11,
        UnitRate12: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate12,
        UnitRate13: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate13,
        UnitRate14: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate14,
        UnitRate15: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate15,
        UnitRate16: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate16,
        UnitRate17: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate17,
        UnitRate18: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate18,
        UnitRate19: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate19,
        UnitRate20: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate20,
        UnitRate21: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate21,
        UnitRate22: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate22,
        UnitRate23: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate23,
        UnitRate24: rateCard.mLCWTRatecarditeam.rateTypePallet?.unitRate24,
        ChargeP0TO100: rateCard.mLCWTRatecarditeam.rateTypePallet?.chargeP0TO100,
        ChargeP100TO200: rateCard.mLCWTRatecarditeam.rateTypePallet?.chargeP100TO200,
        ChargeP200TO300: rateCard.mLCWTRatecarditeam.rateTypePallet?.chargeP200TO300,
        ChargeP300TO400: rateCard.mLCWTRatecarditeam.rateTypePallet?.chargeP300TO400,
        ChargeP400TO500: rateCard.mLCWTRatecarditeam.rateTypePallet?.chargeP400TO500,
        ChargeP500TO600: rateCard.mLCWTRatecarditeam.rateTypePallet?.chargeP500TO600,         
      }
    });
    return mappedCwtRateCard;
  } 

  const getDefaultCard = async (id) => {
    const results = await getDefaultRateCard(id);
    const defaultContractInfo = capitalizeObjectKeys(results?.data?.data.mLContractInfos[0]);
    setContractInfo(defaultContractInfo);
    setDefaultCardData(results?.data?.data)
    dispatch(resetVehicle());
    const defaultvehiclelist = results?.data?.data?.mlVehchileSurchargeList || [];
    for (let i = 0; i < defaultvehiclelist.length; i++) {
      const newVehicle = capitalizeObjectKeys(defaultvehiclelist[i]);
      dispatch(addVehicle(newVehicle));
    }
    dispatch(resetService());
    const defaultservicelist = results?.data?.data?.mlserviceSurchargeList || [];
    for (let i = 0; i < defaultservicelist.length; i++) {
      const newService = capitalizeObjectKeys(defaultservicelist[i]);
      dispatch(addService(newService));
    }
    dispatch(resetAccessorial());
    const defaultAccessorialList = results?.data?.data?.mlAccessorialChargeSurchargeList || [];
    for (let i = 0; i < defaultAccessorialList.length; i++) {
      const newAccessorial = capitalizeObjectKeys(defaultAccessorialList[i]);
      dispatch(addAccessorial(newAccessorial));
    }
    dispatch(resetCwtRateCard());
    const defaultCwtRateCardList = results?.data?.data?.mLCWTRateCardList || [];
    dispatch(addCwtRateCardMultiple(mapCWTRateCard(defaultCwtRateCardList)));
    dispatch(resetFullLoad());
    const defaultftlList = results?.data?.data?.ftlList || [];
    for (let i = 0; i < defaultftlList.length; i++) {
      dispatch(addFullLoad(defaultftlList[i]));
    }
    dispatch(resetLessLoad());
    const defaultltlList = results?.data?.data?.ltlList || [];
    for (let i = 0; i < defaultltlList.length; i++) {
      dispatch(addLessLoad(defaultltlList[i]));
    }
    dispatch(resetHeavyLoad());
    const defaulthtlList = results?.data?.data?.htlList || [];
    for (let i = 0; i < defaulthtlList.length; i++) {
      dispatch(addHeavyLoad(defaulthtlList[i]));
    }
    dispatch(resetCityDistance());
    const defaultCityDistanceList = results?.data?.data?.mLFuelList || [];
    for (let i = 0; i < defaultCityDistanceList.length; i++) {
      const curr = defaultCityDistanceList[i];
      const mappedDistance = { FromCity: curr.fromCity, FromCityName: curr.fromCityName,
        FromCountry: curr.fromCountry, FromCountryName: curr.fromCountryName, FromProvince: curr.fromProvince, 
        FromProvinceName: curr.fromProvinceName, ToCity: curr.toCity, ToCityName: curr.toCityName, ToCountry: curr.toCountry, 
        ToCountryName: curr.toCountryName, ToProvince: curr.toProvince, ToProvinceName: curr.toProvinceName, 
        CityDistanceID : curr.citydistanceID, Miles: curr.miles, TimeInMinutes: curr.timeInMinutes, }
      dispatch(addCityDistance(mappedDistance));
    }
    dispatch(resetDistanceFuel());
    const defaultFuelDistanceList = results?.data?.data?.mLFuelDistanceSurchargesList || [];
    for (let i = 0; i < defaultFuelDistanceList.length; i++) {
      dispatch(addDistanceFuel(capitalizeObjectKeys(defaultFuelDistanceList[i])));
    }
  }
  
  const handleAccount = useCallback(async (account) => {
    setIsLoading(true)
    const results = await getCustomerDetails(account)
    setSingleAccount(results?.data?.data?.mLNameAddressList)
    setContactDetail(results?.data?.data?.mLNameAddressList?.mLDetailsList)
    setContractInfo(results?.data?.data?.mLContractInfos)
    setVehicleSurchargeList(results?.data?.data?.mlVehchileSurchargeList)
    setServiceSurchargeList(results?.data?.data?.mlserviceSurchargeList)
    setAccessorialList(results?.data?.data?.mlAccessorialChargeSurchargeList)
    dispatch(resetCwtRateCard());
    setCwtRateCardList(results?.data?.data?.mLCWTRateCardList)
    dispatch(addCwtRateCardMultiple(mapCWTRateCard(results?.data?.data?.mLCWTRateCardList)));
    setCityDistanceList(results?.data?.data?.mLFuelList)
    setFuelDistanceList(results?.data?.data?.mLFuelDistanceSurchargesList)
    setLessTruckLoad(results?.data?.data?.ltlList)
    setFullTruckLoad(results?.data?.data?.ftlList)
    setHeavyTruckLoad(results?.data?.data?.htlList)
    setFuelCostLists(results?.data?.data?.mLFuelSurchargeCostsList)
    setSpecialAddressList(results?.data?.data?.specialAddressLists)
    setMultiplier(results?.data?.data?.mLContractInfos[0]?.multiplier)
    setSelected(true)
    refetch()
    setIsLoading(false)
  }, [])



  const value = useMemo(() => {
    return {
      vehicleLists, serviceLists, accessorialLists, cityDistanceLists, distanceFuelLists, lessFuelLists, fullFuelLists, heavyFuelLists, fuelCostLists, setFuelCostLists, contactLists, tempDisplay, setTempDisplay,
      inputValue, cwtRateCardLists, showNotes, setShowNotes, validateAddSubmit, validateNameAddress, validateContractPricing, addRateCardDefaultPricing, addSpecialAddress, nameAddress, handleAccount, selected, singleAccount, contactDetail, contractInfo, setContractInfo, vehicleSurchargeList, serviceSurchargeList,
      accessorialList, cwtRatecardList, cityDistanceList, fuelDistanceList, lessTruckLoad, fullTruckLoad, heavyTruckLoad, defaultCardData, setDefaultCardData, getDefaultCard, multiplier, setMultiplier, specialAddress, setSpecialAddress, specialAddressList, isLoading, loading, addressData, setAddressData, errors, setErrors
    }
  }, [vehicleLists, serviceLists, accessorialLists, cityDistanceLists, distanceFuelLists, lessFuelLists, fullFuelLists, heavyFuelLists, fuelCostLists, setFuelCostLists, contactLists, tempDisplay, setTempDisplay,
    inputValue, cwtRateCardLists, validateAddSubmit, showNotes, setShowNotes, validateNameAddress, validateContractPricing, addRateCardDefaultPricing, addSpecialAddress, nameAddress, handleAccount, selected, singleAccount, contactDetail, contractInfo, setContractInfo, vehicleSurchargeList, serviceSurchargeList,
    accessorialList, cwtRatecardList, cityDistanceList, fuelDistanceList, lessTruckLoad, fullTruckLoad, heavyTruckLoad, defaultCardData, setDefaultCardData, getDefaultCard, multiplier, setMultiplier, specialAddress, setSpecialAddress, specialAddressList, isLoading, loading, addressData, setAddressData, errors, setErrors])
  return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>;
};



const useCustomer = () => {
  const context = useContext(CustomerContext);

  if (context === undefined) {
    throw new Error("useCustomer must be used within CustomerContext");
  }

  return context;
};

export default useCustomer;
