import React from 'react'
import { useState, useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import '../../../style.css'
import truck from '../../../assets/truck.jpg'
import { ToastContainer, toast } from 'react-toastify';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useGetAllUnitsQuery, useAddUnitToDriverMutation, useLazyGetAllSelectedDriverDetailsQuery, useUpdateDriverCellInBoardMutation, 
    useLazyGetTrailersForAssignedUnitQuery, useRemoveTrailerFromUnitMutation } from '../../../features/Dispatch/DispatchSlice';
import { Typeahead } from 'react-bootstrap-typeahead';
import CloseButton from 'react-bootstrap/CloseButton';

const DriverList = (props) => {
    const [drivers, setDrivers] = useState([]);
    const { data: units, refetch } = useGetAllUnitsQuery();
    const [addUnitToDriver] = useAddUnitToDriverMutation();
    const [updateDriverCell] = useUpdateDriverCellInBoardMutation();
    const [removeTrailer] = useRemoveTrailerFromUnitMutation();
    const [getSelectedDriverDetails] = useLazyGetAllSelectedDriverDetailsQuery();
    const [anchorEl, setAnchorEl] = useState({});
    const [getTrailersForAssignedUnit] = useLazyGetTrailersForAssignedUnitQuery();
    const [trailers, setTrailers] = useState([]);

    useEffect(async () => {
        if (props.divisionId) {
            await props.fetchDrivers(props.divisionId);
        }
        else {
            setDrivers([])
        }
    }, [props.divisionId]);

    useEffect(() => {
        if (props.result.data) {
            setDrivers(props.result.data);
        }
    }, [props.result]);

    const assignUnit = async (unit, driver) => {
        const result = await addUnitToDriver({ unitNumber: unit, driverNumber: driver });
        if (result?.data?.data?.statusCode === 412) {
            toast.info("Driver not on duty!", {
                position: "top-center",
            })
        }
        else {
            refetch();
            await props.fetchDrivers(props.divisionId);
            props.setSelectedDriver({...props.selectedDriver, unitBeingDriven: unit});
        }
    }

    const attachTrailer = async (driver, unit, trailer) => {
        await addUnitToDriver({ unitNumber: unit, driverNumber: driver, trailerNumber: trailer });
        await props.fetchDrivers(props.divisionId);
    }

    const detachTrailer = async (driver, unit, trailer) => {
        await removeTrailer({unitNumber: unit, driverNumber: driver, trailerNumber: trailer, divisionId: props.divisionId});
        await props.fetchDrivers(props.divisionId);
    }

    const options = [
        { id: 1, text: 'Detach unit from driver' },
        { id: 2, text: 'Transmission On/Off' },
        { id: 3, text: 'On/Off Clock' },
        { id: 4, text: 'Remove driver from Board' },
    ];

    const handleClick = (event, index) => {
        event.stopPropagation();
        setAnchorEl({ ...anchorEl, [index]: event.currentTarget });
    };

    const handleClose = (event, index) => { 
        event.stopPropagation();
        setAnchorEl({ ...anchorEl, [index]: null });
    };
console.log(drivers);
    return (
        <div className="main-container">
            <fieldset className='driverList-container'>
                <label htmlFor="">Driver List</label>
            </fieldset>
            <div className="driverList-menu">
                {drivers.data?.map((item, index) => (
                    <div /* style={{ backgroundColor: item === selectedDriver? 'blue': 'none'}} */style={{border: '1px solid black'}} key={index} 
                        onClick={async () => 
                            { 
                                const result = await getSelectedDriverDetails({divisionId: props.divisionId, driverNumber: item.driverNumber});
                                await props.setSelectedDriver(result.data.data)
                                props.setSelectedDriverOrders(result?.data?.data?.dispatchedOrderDetails);
                                props.setOnBoardOrders(result?.data?.data?.onBoardedOrderDetails);
                                const orders = await props.getAssignedOrdersToDriver({divisionId: props.divisionId, driverNumber: item.driverNumber});
                                props.setAssignedOrders(orders.data.data);
                            }
                        }>
                        <div className="driverList-Items">
                            <div className="image-menu">
                                <img src={item.vehicleImage} alt="" />
                            </div>
                            <div className="dropdown">
                                    <div>
                                        <MoreVertIcon onClick={(event) => handleClick(event, index)} />
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl[index]} 
                                            keepMounted
                                            open={Boolean(anchorEl[index])} 
                                            onClose={(event) => handleClose(event, index)} 
                                            PaperProps={{
                                            style: {
                                                width: '25ch',
                                            },
                                            }}
                                        >
                                        {options.map((option) => (
                                        <MenuItem key={option.id} 
                                            onClick={async (e) => 
                                                {e.stopPropagation();
                                                const result = await updateDriverCell({divisionId: props.divisionId, driverNumber: item.driverNumber, updatedFieldId: option.id})
                                                if (result.data.data.statusCode === 400 || result.data.statusCode === 400) {
                                                    if (option.id === 4) {
                                                        toast.info("Could not remove driver from board", {
                                                            position: "top-center",
                                                        })
                                                    }
                                                
                                                }
                                                else if (result.data.data.statusCode === 200 || result.data.statusCode === 200) {
                                                    if (option.id === 1) {
                                                        await props.fetchDrivers(props.divisionId);
                                                        await props.setSelectedDriver({...props.selectedDriver, assignedUnit: null});
                                                        refetch();
                                                    }
                                                    if (option.id === 4) {
                                                        await props.fetchDrivers(props.divisionId);
                                                        await props.getDriverList(props.divisionId)
                                                        await props.setSelectedDriver(null);
                                                        refetch();
                                                    }
                                                    if (option.id === 3) {
                                                        await props.fetchDrivers(props.divisionId);

                                                    }
                                                }
                                                handleClose(e, index); 
                                                }
                                            }
                                        >
                                            {option.text}
                                        </MenuItem>
                                        ))}
                                    </Menu>
                                    </div>                                
                            </div>
                        </div>
                        <div className="input-menu">
                            {!item.unitBeingDriven?
                                <Typeahead
                                    id="unit-list"
                                    labelKey={option => `${option.unitNumber}`}
                                    options={units?.data}
                                    onChange={(selected) => assignUnit(selected[0]?.unitNumber, item.driverNumber)}
                                    disabled={item.driverType === "Agent" || item.driverType === "Interliner"}
                                />
                                :
                                <label style={{color: 'black'}}htmlFor="">{item.unitBeingDriven}</label>
                            }
                        </div>
                        <div className="input-menu">
                            <label style={{width: '30px', color: item.onClock ? 'green' : 'red', fontWeight: 'bold'}} htmlFor="">{item.driverNumber}</label>
                            <span style={{display: 'inline-block', width: '60px',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis'}} title={item.currentLocation || ''}>{item.currentLocation || ''}</span>
                        </div>
                        <div className="input-menu">
                         {item.vehicleType === 'TRACTOR'? 
                            item.trailerUnit === null?
                            <Typeahead
                                id="trailer-list" 
                                options={trailers}
                                labelKey={option => `${option.unitNumber}`}
                                onFocus={async () => {
                                    const result = await getTrailersForAssignedUnit(item.unitBeingDriven);
                                    setTrailers(result.data.data);
                                    }}
                                onChange={(selected) => attachTrailer(item.driverNumber, item.unitBeingDriven, selected[0].unitNumber)}
                            /> : <><label htmlFor=""> {item.trailerUnit}</label><span />
                            <CloseButton style={{ fontSize: '0.7em' }} onClick={() => detachTrailer(item.driverNumber, item.unitBeingDriven, item.trailerUnit)} /></>
                               
                            : <div style={{ height: '20px' }}></div>
                         }
                         </div>
                    </div>
                ))}
            </div>
        </div>


    )
}

export default DriverList
