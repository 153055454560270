import { Col, Row, Form, FloatingLabel, Button, } from "react-bootstrap";
import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid } from '@mui/x-data-grid';
import { DataGridPremium, GridToolbar, useGridApiRef, } from '@mui/x-data-grid-premium';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../../../context/CustomerContext";
import { addCwtRateCard, editCwtRateCard, deleteCwtRateCard, addCwtRateCardMultiple } from "../../../../../features/cwtRateCard/cwtRateCardSlice";
import { useGetCityQuery } from "../../../../../features/city/citySlice";
import { useGetCountryQuery, useGetProvinceWithoutCountryQuery, useGetCityWithoutProvinceQuery } from "../../../../../features/countryState/countryStateSlice";
import { useCustomData } from "../../../../Sidebar/Permission";
import { Typeahead } from 'react-bootstrap-typeahead';
import CountryProvince from "../../../../CountryProvince";
import UploadRateCard from "../../../../Admin/DefaultPricing/DefaultPricing/CwtRateCard/UploadRateCard";
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

const CwtRatecard = () => {
  const [permission] = useCustomData()
  const { city, handleProvince, newHandleCountry } = CountryProvince();
  const { data: fromCity} = useGetCityWithoutProvinceQuery()
  const { data: toCity} = useGetCityWithoutProvinceQuery()
  const dispatch = useDispatch();
  const { cwtRateCardLists, setTempDisplay } = useCustomer();
  const [open, setOpen] = useState(false);
  const [selectState, setSelectState] = useState([]);
  const [selectToState, setSelectToState] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [cwtRatecardData, setCwtRatecardData] = useState({})
  const [cwtStatus, setCwtStatus] = useState(0);
  //const CwtstatusHandler = (cwtStatus) => { setCwtStatus(cwtStatus) };
  const CwtstatusHandler = (status) => {
    setCwtStatus(status);
    setCwtRatecardData((prevData) => ({
        ...prevData,
        RateType: status === 1 ? "CWT" : "Pallet"
    }));
};
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  const cityList = city?.data.map((a) => { return {name: a.city, id: a.id}});
  const toCityList = cityList;
  const fromCityList = cityList;
  const [toSelection, setToSelection] = useState([]);
  const [fromSelection, setFromSelection] = useState([]);
  const [toProvince, setToProvince] = useState([]);
  const [fromProvince, setFromProvince] = useState([]);
  const [toCountry, setToCountry] = useState([]);
  const [fromCountry, setFromCountry] = useState([]);
  const [isDelete, setIsDelete] = useState()

  useEffect(async () => {
    if (fromSelection.length > 0) {
      setCwtRatecardData(cwtRatecardData => ({ ...cwtRatecardData, fromcity: {city: fromSelection[0].name, id: fromSelection[0].id} }));
      const pList = await handleProvince(fromSelection[0].id);
      setFromProvince(pList.data.data);
    }
    else {
      let rateCardData = cwtRatecardData;
      delete rateCardData.fromCity;
      setCwtRatecardData(rateCardData);
      setFromProvince([]);    
      setFromCountry([]);
    }
  }, [fromSelection])
  useEffect(async () => {
    if (toSelection.length > 0) {
      setCwtRatecardData(cwtRatecardData => ({ ...cwtRatecardData, tocity: {city: toSelection[0].name, id: toSelection[0].id} }));
      const pList = await handleProvince(toSelection[0].id);
      setToProvince(pList.data.data);
    }
    else {
      let rateCardData = cwtRatecardData;
      delete rateCardData.toCity;
      setCwtRatecardData(rateCardData);
      setToProvince([]);
      setToCountry([]); 
    }
  }, [toSelection])

  const setCountry = async (e) => {
    if (e.target.value !== 'Select') {
      if (e.target.name === 'fromprov') {
        const prov = fromProvince.find((a) => a.provineSateName === e.target.value)
        setCwtRatecardData(cwtRatecardData => ({ ...cwtRatecardData, fromprov: prov }));
        const cList = await newHandleCountry(prov.provineStateID);
        setFromCountry(cList.data.data)
      }
      else {
        const prov = toProvince.find((a) => a.provineSateName === e.target.value)
        setCwtRatecardData(cwtRatecardData => ({ ...cwtRatecardData, toprov: prov }));
        const cList = await newHandleCountry(prov.provineStateID);
        setToCountry(cList.data.data)
      }
    } 
    else {
      if (e.target.name === 'fromprov') {
        setFromCountry([])
      }
      else {
        setToCountry([])
      }
    }
  }

  // added spaces manually to align the columns in the correct order in excel sheet
  const columnHeaders = {
    min: 'Minimum Rate',
    ftl: 'FTL Rate',
    avgWeight: 'Average Weight',
    // originCityId: 'Origin City ID',
    origincity: 'Origin City',
    // originProvinceId: 'Origin Province ID',
    originProvince: 'Origin Province',
    // originCountryId: 'Origin Country ID',
    originCountry: 'Origin Country',
    destinationcity: '     Destination City',
    // destinationProvinceId: 'Destination Province ID',
    destinationProvince: 'Destination Province',
    // destinationcityId: 'Destination City ID',
    // destinationCountryId: 'Destination Country ID',
    destinationCountry: 'Destination Country',
    p0TOLTL: '0-500',
    p500TO1000: '500-1000 ',
    p1000TO2000: '1000-2000 ',
    p2000TO5000: '2000-5000 ',
    p5000TO10000: '5000-10000 ',
    p10000TO20000: '10000-20000 ',
    p1: '       1',
    p2: '       2',
    p3: '       3',
    p4: '       4',
    p5: '       5',
    p6: '       6',
    p7: '       7',
    p8: '       8',
    p9: '       9',
    p10: '        10',
    p11: '        11',
    p12: '        12',
    p13: '        13',
    p14: '        14',
    p15: '        15',
    p16: '        16',
    p17: '        17',
    p18: '        18',
    p19: '        19',
    p20: '        20',
    p21: '        21',
    p22: '        22',
    p23: '        23',
    p24: '        24',
    p100: 'Overweight Charge 0-100',
    p200: 'Overweight Charge 100-200',
    p300: 'Overweight Charge 200-300',
    p400: 'Overweight Charge 300-400',
    p500: 'Overweight Charge 400-500',
    p600: 'Overweight Charge 500-600',
  };

  const writeToExcel = (records, fileName) => {
    if (!records || records.length === 0) {
      return;
    }

    // List of keys to exclude from the export
    const excludeColumns = [
      'originCityId', 
      'originProvinceId', 
      'originCountryId',
      'destinationProvinceId',
      'destinationcityId',
      'destinationCountryId',
      'p20000TO999999'
    ];

    // Map the existing keys to meaningful headers
    const mappedRecords = records.map(record => {
      const mappedRecord = {};
      for (let key in record) {
        if (!excludeColumns.includes(key)) {
          const newKey = columnHeaders[key] || key; 
          mappedRecord[newKey] = record[key];
        }
      }
      return mappedRecord;
    });

    const worksheet = XLSX.utils.json_to_sheet(mappedRecords);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}-Invalid.xlsx`);
  }

  // table data
  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          editCwtratecardList(data.id)
        };
        const onRemove = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          handleClickOpen();
        };
        return (
          <>
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                  Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                  <Button className="btn-primary" onClick={() => {handleClose(); }} autoFocus> Confirm</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="action_btn">
              <span className="edit_btn" onClick={onClick}><Edit /></span>
              <span className="del_btn" onClick={onRemove}><Delete /></span>
            </div>
          </>
        )
      },
    },
    { field: 'fromcountry', headerName: 'From Country', width: 130 },
    { field: 'fromprov', headerName: 'From Province', width: 130 },
    { field: 'fromcity', headerName: 'From City', width: 100 },
    { field: 'tocountry', headerName: 'To Country', width: 110 },
    { field: 'toprov', headerName: 'To Province', width: 110 },
    { field: 'tocity', headerName: 'To City', width: 80 },
    { field: 'MinimumRate', headerName: 'Minimum Rate', type: 'number', width: 130 },
    { field: 'AverageWeight', headerName: 'Average Weight', type: 'number', width: 130 },
    { field: 'FTLRate', headerName: 'FTL Rate', type: 'number', width: 100 },
    { field: 'RateType', headerName: 'Rate Type', width: 100 },
    { field: 'UnitRateP0TOLTL', headerName: 'Weight range(0-500)lbs', type: 'number', width: 160 },
    { field: 'UnitRateP500TO1000', headerName: 'Weight range(500-1000)lbs', type: 'number', width: 180 },
    { field: 'UnitRateP1000TO2000', headerName: 'Weight range(1000-2000)lbs', type: 'number', width: 180 },
    { field: 'UnitRateP2000TO5000', headerName: 'Weight range(2000-5000)lbs', type: 'number', width: 180 },
    { field: 'UnitRateP5000TO10000', headerName: 'Weight range(5000-10000)lbs', type: 'number', width: 180 },
    { field: 'UnitRateP10000TO20000', headerName: 'Weight range(10000-20000)lbs', type: 'number', width: 180 },
    { field: 'UnitRateP0TO1', headerName: 'Pallet range(0-1)', type: 'number', width: 150 },
    { field: 'UnitRateP1TO5', headerName: 'Pallet range(1-5)', type: 'number', width: 150 },
    { field: 'UnitRateP5TO10', headerName: 'Pallet range(5-10)', type: 'number', width: 150 },
    { field: 'UnitRateP10TO16', headerName: 'Pallet range(10-16)', type: 'number', width: 150 },
    { field: 'UnitRateP16TO21', headerName: 'Pallet range(16-21)', type: 'number', width: 150 },
    { field: 'UnitRateP21TO24', headerName: 'Pallet range(21-24)', type: 'number', width: 150 },
    { field: 'ChargeP0TO100', headerName: 'Pallet range(0-100)', type: 'number', width: 160 },
    { field: 'ChargeP100TO200', headerName: 'Pallet range(100-200)', type: 'number', width: 160 },
    { field: 'ChargeP200TO300', headerName: 'Pallet range(200-300)', type: 'number', width: 160 },
    { field: 'ChargeP300TO400', headerName: 'Pallet range(300-400)', type: 'number', width: 160 },
    { field: 'ChargeP400TO500', headerName: 'Pallet range(400-500)', type: 'number', width: 160 },
    { field: 'ChargeP500TO600', headerName: 'Pallet range(500-600)', type: 'number', width: 160 },

  ];

  const palletColumns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation(); 
          editCwtratecardList(data.id)
        };
        const onRemove = (e) => {
          e.stopPropagation();
          handleClickOpen();
          setIsDelete(data?.id)
         
        };
        return (
          <>
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                  Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                  <Button className="btn-primary" onClick={() => deleteHandler()} autoFocus> Confirm</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="action_btn">
              {/* <span className="edit_btn" onClick={onClick}><Edit /></span> */}
              <span className="del_btn" onClick={onRemove}><Delete /></span>
            </div>
          </>
        )
      },
    },
    { field: 'origincity', headerName: 'From City', width: 100 },
    { field: 'originProvince', headerName: 'From Province', width: 130 },
    { field: 'originCountry', headerName: 'From Country', width: 130 },
    { field: 'destinationcity', headerName: 'To City', width: 100 },
    { field: 'destinationProvince', headerName: 'To Province', width: 110 },
    { field: 'destinationCountry', headerName: 'To Country', width: 110 },
    { field: 'UnitRate1', headerName: '1', type: 'number', width: 40 },
    { field: 'UnitRate2', headerName: '2', type: 'number', width: 40 },
    { field: 'UnitRate3', headerName: '3', type: 'number', width: 40 },
    { field: 'UnitRate4', headerName: '4', type: 'number', width: 40 },
    { field: 'UnitRate5', headerName: '5', type: 'number', width: 40 },
    { field: 'UnitRate6', headerName: '6', type: 'number', width: 40 },
    { field: 'UnitRate7', headerName: '7', type: 'number', width: 40 },
    { field: 'UnitRate8', headerName: '8', type: 'number', width: 40 },
    { field: 'UnitRate9', headerName: '9', type: 'number', width: 40 },
    { field: 'UnitRate10', headerName: '10', type: 'number', width: 40 },
    { field: 'UnitRate11', headerName: '11', type: 'number', width: 40 },
    { field: 'UnitRate12', headerName: '12', type: 'number', width: 40 },
    { field: 'UnitRate13', headerName: '13', type: 'number', width: 40 },
    { field: 'UnitRate14', headerName: '14', type: 'number', width: 40 },
    { field: 'UnitRate15', headerName: '15', type: 'number', width: 40 },
    { field: 'UnitRate16', headerName: '16', type: 'number', width: 40 },
    { field: 'UnitRate17', headerName: '17', type: 'number', width: 40 },
    { field: 'UnitRate18', headerName: '18', type: 'number', width: 40 },
    { field: 'UnitRate19', headerName: '19', type: 'number', width: 40 },
    { field: 'UnitRate20', headerName: '20', type: 'number', width: 40 },
    { field: 'UnitRate21', headerName: '21', type: 'number', width: 40 },
    { field: 'UnitRate22', headerName: '22', type: 'number', width: 40 },
    { field: 'UnitRate23', headerName: '23', type: 'number', width: 40 },
    { field: 'UnitRate24', headerName: '24', type: 'number', width: 40 },
    { field: 'ChargeP0TO100', headerName: '100', type: 'number', width: 40 },
    { field: 'ChargeP100TO200', headerName: '200', type: 'number', width: 40 },
    { field: 'ChargeP200TO300', headerName: '300', type: 'number', width: 40 },
    { field: 'ChargeP300TO400', headerName: '400', type: 'number', width: 40 },
    { field: 'ChargeP400TO500', headerName: '500', type: 'number', width: 40 },
    { field: 'ChargeP500TO600', headerName: '600', type: 'number', width: 40 },
    { field: 'AverageWeight', headerName: 'Average Weight', type: 'number', width: 130 },
    { field: 'ftl', headerName: 'FTL', type: 'number', width: 100 },
  ];

  const CWTColumns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation(); 
          editCwtratecardList(data.id)
        };
        const onRemove = (e) => {
          e.stopPropagation();
          handleClickOpen();
          setIsDelete(data?.id)
         
        };
        return (
          <>
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                  Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                  <Button className="btn-primary" onClick={() => deleteHandler()} autoFocus> Confirm</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="action_btn">
              {/* <span className="edit_btn" onClick={onClick}><Edit /></span> */}
              <span className="del_btn" onClick={onRemove}><Delete /></span>
            </div>
          </>
        )
      },
    },
    { field: 'origincity', headerName: 'From City', width: 100 },
    { field: 'originProvince', headerName: 'From Province', width: 130 },
    { field: 'originCountry', headerName: 'From Country', width: 130 },
    { field: 'destinationcity', headerName: 'To City', width: 100 },
    { field: 'destinationProvince', headerName: 'To Province', width: 110 },
    { field: 'destinationCountry', headerName: 'To Country', width: 110 },
    { field: "min", headerName: 'Minimum Rate', type: 'number', width: 100 },
    { field: 'p0TOLTL', headerName: 'LTL', type: 'number', width: 100 },
    { field: 'p500TO1000', headerName: '500', type: 'number', width: 100 },
    { field: 'p1000TO2000', headerName: '1M', type: 'number', width: 100 },
    { field: 'p2000TO5000', headerName: '2M', type: 'number', width: 100 },
    { field: 'p5000TO10000', headerName: '5M', type: 'number', width: 100 },
    { field: 'p10000TO20000', headerName: '10M', type: 'number', width: 100 },
    { field: 'ftl', headerName: 'FTL', type: 'number', width: 100 },

  ];

  const rows = cwtRateCardLists.map((data) => {
    return {
      id: data.id,
      MinimumRate: data.MinimumRate,
      AverageWeight: data.AverageWeight,
      FTLRate: data.FTLRate,
      fromcity: data.mLCwtRateCardCity?.fromCity?.fromcity,
      fromcountry: data.mLCwtRateCardCity?.fromCity?.fromcountry,
      fromprov: data.mLCwtRateCardCity?.fromCity?.fromprov,
      tocity: data.mLCwtRateCardCity?.toCity?.tocity,
      tocountry: data.mLCwtRateCardCity?.toCity?.tocountry,
      toprov: data.mLCwtRateCardCity?.toCity?.toprov,
      RateType: data.mLCWTRatecarditeam?.RateType,
      UnitRateP0TOLTL: data.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP0TOLTL,
      UnitRateP500TO1000: data.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP500TO1000,
      UnitRateP1000TO2000: data.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP1000TO2000,
      UnitRateP2000TO5000: data.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP2000TO5000,
      UnitRateP5000TO10000: data.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP5000TO10000,
      UnitRateP10000TO20000: data.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP10000TO20000,
      UnitRateP0TO1: data.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP0TO1,
      UnitRateP1TO5: data.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP1TO5,
      UnitRateP5TO10: data.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP5TO10,
      UnitRateP10TO16: data.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP10TO16,
      UnitRateP16TO21: data.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP16TO21,
      UnitRateP21TO24: data.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP21TO24,
      ChargeP0TO100: data.mLCWTRatecarditeam?.rateTypePallet?.ChargeP0TO100,
      ChargeP100TO200: data.mLCWTRatecarditeam?.rateTypePallet?.ChargeP100TO200,
      ChargeP200TO300: data.mLCWTRatecarditeam?.rateTypePallet?.ChargeP200TO300,
      ChargeP300TO400: data.mLCWTRatecarditeam?.rateTypePallet?.ChargeP300TO400,
      ChargeP400TO500: data.mLCWTRatecarditeam?.rateTypePallet?.ChargeP400TO500,
      ChargeP500TO600: data.mLCWTRatecarditeam?.rateTypePallet?.ChargeP500TO600

    }
  })

  const testRows = cwtRateCardLists.map((element) => {
    const toCountry = element?.mLCwtRateCardCity?.toCity?.tocountry;
    const fromCountry = element?.mLCwtRateCardCity?.fromCity?.fromcountry;
    const tocountryval = toCountry === 1 || toCountry === '1'? "CAN": toCountry === 2 || toCountry === '2'? "USA" : "";
    const fromcountryval = fromCountry === 1 || fromCountry === '1'? "CAN": fromCountry === 2 || fromCountry === '2'? "USA" : "";
    return {
        id: element.id,
        RateType: element.mLCWTRatecarditeam?.RateType,
        min: element?.MinimumRate,
        AverageWeight: element?.AverageWeight,
        ftl: element?.FTLRate,
        originCountry: fromcountryval,
        originProvince: element?.mLCwtRateCardCity?.fromCity?.fromprov?.provineSateName,
        origincity: element?.mLCwtRateCardCity?.fromCity?.fromcity?.city,
        destinationcity: element?.mLCwtRateCardCity?.toCity?.tocity?.city,
        destinationCountry: tocountryval,
        destinationProvince: element?.mLCwtRateCardCity?.toCity?.toprov?.provineSateName,
        p0TOLTL:element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP0TOLTL,
        p500TO1000: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP500TO1000,
        p1000TO2000: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP1000TO2000,
        p2000TO5000: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP2000TO5000,
        p5000TO10000: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP5000TO10000,
        p10000TO20000: element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP10000TO20000,
        UnitRateP0TO1:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP0TO1,
        UnitRate1:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate1,
        UnitRate2:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate2,
        UnitRate3:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate3,
        UnitRate4:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate4,
        UnitRate5:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate5,
        UnitRate6:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate6,
        UnitRate7:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate7,
        UnitRate8:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate8,
        UnitRate9:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate9,
        UnitRate10:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate10,
        UnitRate11:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate11,
        UnitRate12:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate12,
        UnitRate13:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate13,
        UnitRate14:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate14,
        UnitRate15:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate15,
        UnitRate16:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate16,
        UnitRate17:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate17,
        UnitRate18:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate18,
        UnitRate19:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate19,
        UnitRate20:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate20,
        UnitRate21:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate21,
        UnitRate22:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate22,
        UnitRate23:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate23,
        UnitRate24:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate24,
        UnitRateP5TO10:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP5TO10,
        UnitRateP10TO16:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP10TO16,
        UnitRateP16TO21:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP16TO21,
        UnitRateP21TO24:element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP21TO24,
        ChargeP0TO100:element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP0TO100,
        ChargeP100TO200:element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP100TO200,
        ChargeP200TO300:element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP200TO300,
        ChargeP300TO400:element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP300TO400,
        ChargeP400TO500:element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP400TO500,
        ChargeP500TO600:element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP500TO600,
      }
    }
  );

  const cwtRatecardHandler = (e) => { 
    //setCwtRatecardData(cwtRatecardData => ({ ...cwtRatecardData, [e.target.name]: e.target.value }));
    setCwtRatecardData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
      RateType: cwtStatus === 1 ? "CWT" : "Pallet"  // Ensure RateType is always set
  }));
 }

  const addCwtratecardList = (e) => {
    console.log(cwtRatecardData);
    e.preventDefault();

     // Ensure RateType is set based on the current cwtStatus
     const updatedRatecardData = { 
      ...cwtRatecardData,
      RateType: cwtStatus === 1 ? "CWT" : "PALLET" 
    };

    if (Object.keys(cwtRatecardData).length === 0) {
      setIsShow(isShow => !isShow);
    }
    else if (editingTaskIndex === null) {
      dispatch(addCwtRateCard(updatedRatecardData));
    } 
    else {
      let editingItem = cwtRateCardLists[editingTaskIndex];
      editingItem = updatedRatecardData;
      dispatch(editCwtRateCard(editingItem));
    }
    setCwtRatecardData("");
    setEditingTaskIndex(null);
    setIsUpdate(false)
    setToSelection([]);
    setFromSelection([])
  };

    // appending data from excel file
    const addExcelData = async (rows) => {
      const recordsList = [];
      for (let i = 0; i < rows.length; i++)  {
        const row = {...rows[i], id: i};
        const record = {
          "MinimumRate": row.min || 0,
          "FTLRate": row.ftl || 0,
          "file": "",
          "RateType": cwtStatus === 1? "CWT": "Pallet",
          "AverageWeight": row.avgWeight,
          "UnitRateP0TOLTL": row.p0TOLTL || 0,
          "UnitRateP500TO1000": row.p500TO1000 || 0,
          "UnitRateP1000TO2000": row.p1000TO2000 || 0,
          "UnitRateP2000TO5000": row.p2000TO5000 || 0,
          "UnitRateP5000TO10000": row.p5000TO10000 || 0,
          "UnitRateP10000TO20000": row.p10000TO20000 || 0,
          "unitRateP0TO1": 0,
          "unitRateP1TO5": 0,
          "unitRateP5TO10": 0,
          "unitRateP10TO16": 0,
          "unitRateP16TO21": 0,
          "unitRateP21TO24": 0,
          "UnitRate1": row["p1"] || 0,
          "UnitRate2": row["p2"] || 0,
          "UnitRate3": row["p3"] || 0,
          "UnitRate4": row["p4"] || 0,
          "UnitRate5": row["p5"] || 0,
          "UnitRate6": row["p6"] || 0,
          "UnitRate7": row["p7"] || 0,
          "UnitRate8": row["p8"] || 0,
          "UnitRate9": row["p9"] || 0,
          "UnitRate10": row["p10"] || 0,
          "UnitRate11": row["p11"] || 0,
          "UnitRate12": row["p12"] || 0,
          "UnitRate13": row["p13"] || 0,
          "UnitRate14": row["p14"] || 0,
          "UnitRate15": row["p15"] || 0,
          "UnitRate16": row["p16"] || 0,
          "UnitRate17": row["p17"] || 0,
          "UnitRate18": row["p18"] || 0,
          "UnitRate19": row["p19"] || 0,
          "UnitRate20": row["p20"] || 0,
          "UnitRate21": row["p21"] || 0,
          "UnitRate22": row["p22"] || 0,
          "UnitRate23": row["p23"] || 0,
          "UnitRate24": row["p24"] || 0,
          "ChargeP0TO100": row["p100"] || 0,
          "ChargeP100TO200": row["p200"] || 0,
          "ChargeP200TO300": row["p300"] || 0,
          "ChargeP300TO400": row["p400"] || 0,
          "ChargeP400TO500": row["p500"] || 0,
          "ChargeP500TO600": row["p600"] || 0,
          "fromcity": {"id": row["originCityId"] || 1, "city": row["origincity"]},
          "fromcountry": row["originCountryId"],
          "fromprov": {"provineStateID": row["originProvinceId"], "provineSateName": row["originProvince"]},
          "tocity": {"id": row["destinationcityId"] || 1, "city": row["destinationcity"]},
          "tocountry": row["destinationCountryId"],
          "toprov": {"provineStateID": row["destinationProvinceId"], "provineSateName": row["destinationProvince"]},
        }
        recordsList.push(record);
      }
      setTempDisplay(true)
      dispatch(addCwtRateCardMultiple(recordsList));
      toast.info(`Successfully added ${rows.length} rows`, {
        position: "top-center",
      })
    }

  // Edit vehicle list
  const editCwtratecardList = (id) => {
    let newEditItem = cwtRateCardLists.find((data) => {
      return data.id === id
    });
    setIsUpdate(true);
    setIsShow(isShow => !isShow);
    setCwtRatecardData(newEditItem)
    setEditingTaskIndex(id);
  }

  const deleteHandler = () => {
    dispatch(deleteCwtRateCard(isDelete));
    setIsDelete(null);
    handleClose();
  }


  const getData = fromProvince;
  const getStateData = toProvince;

  const handlecountry = async (e) => {
    const addressData = e.target.value;
    const selectedState = await getData(addressData)
    setSelectState(selectedState)
  }

  const handletocountry = async (e) => {
    const addressData = e.target.value;
    const selectedState = await getStateData(addressData)
    setSelectToState(selectedState)
  }





  return (
    <>
      <Row className="mb-2 mt-2">
        <Col sm={12}>
          <fieldset className="pb-2">
            <div className="btn_section">
              <div className="section_action_btn">
                {!isUpdate ?
                  <>
                    {permission.find(event => event.moduleId === 44)?.permissionType?.indexOf('Add') !== -1 ?
                      <div className="add_btn"><button type="button" className="btn" onClick={e => { addCwtratecardList(e); }}><AddCircle /></button></div>
                      : <div className="add_btn disable"><button type="button" className="btn"><AddCircle /></button></div>}
                  </>

                  :
                  <div className="save_btn"><button type="button" className="btn" onClick={e => { addCwtratecardList(e); }}><Save /></button></div>
                }
              </div>
            </div>
            {isShow ?
              <>
                {/* Rate card item */}
                <Row className="mt-4">
                  <Col sm={12}>
                    <fieldset className="pb-2">
                      <label className="label-heading">Rate Card Item</label>
                      <Form>
                        <Row>
                          <Col sm={12} className="mb-3">
                            <Row>
                              <Col sm={4}><h6>Please select the rate card item type</h6></Col>
                              <Col sm={2}>
                                <div className="floating-checbox" onChange={e => cwtRatecardHandler(e)} name="RateType">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineCheckbox1" value="CWT" name="RateType" checked={cwtStatus === 1} onChange={e => CwtstatusHandler(1)} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">CWT</label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineCheckbox2" value="PALLET" name="RateType" checked={cwtStatus === 2} onChange={e => CwtstatusHandler(2)} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox2">PALLET</label>
                                  </div>
                                </div>
                              </Col>
                          {(cwtStatus !== 0) && <> 
                            <Col sm={6}><UploadRateCard addExcelData={addExcelData} writeToExcel={writeToExcel} /></Col> 
                            <Row>
                            <Col sm={12}>
                              <fieldset className="pb-2 mt-2">
                                <label className="label-heading">CWT Rate Card City</label>
                                <Form>
                                  <Row>
                                    <Col sm={6}>
                                      <fieldset className="pb-2 mt-2">
                                        <label className="label-heading">From</label>
                                        <Form>
                                          <Row>
                                            <Col sm={12} className="mb-3">
                                            <Form.Group controlId="from" className="mb-2">
                                                {/* <FloatingLabel controlId="floatingSelectGrid" label="From City">
                                                  <Form.Select aria-label="Floating label select example" name="fromcity" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.fromcity || ''} required> */}
                                                    <Typeahead 
                                                      id='cityAutocomplete'
                                                      labelKey="name"
                                                      onChange={setFromSelection}
                                                      options={fromCityList}
                                                      placeholder="Choose from city..."
                                                      selected={fromSelection}
                                                    />
                                                  {/* </Form.Select>
                                                </FloatingLabel> */}
                                              </Form.Group>
                                              <Form.Group controlId="Province/State" className="mb-2">
                                                <FloatingLabel controlId="floatingSelectGrid" label="From Province/State">
                                                  <Form.Select aria-label="Floating label select example" name="fromprov" onChange={(e) => { setSelectState(e.target.value); cwtRatecardHandler(e); setCountry(e) }} value={cwtRatecardData?.fromprov?.provineSateName || ''} required>
          
                                                    <option>Select</option>:
                                                    <>
                                                      {
                                                        fromProvince?.map((data, index) => (
                                                          <option key={data.provineStateID} value={data.provineSateName}>{data.provineSateName}</option>
                                                        ))
          
                                                      }
                                                    </>
          
                                                  </Form.Select>
          
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group controlId="country" className="mb-2">
                                                <FloatingLabel controlId="floatingSelectGrid" label="From Country">
                                                  <Form.Select aria-label="Floating label select example" name="fromcountry" onChange={e => { handlecountry(e); cwtRatecardHandler(e) }} value={cwtRatecardData?.fromcountry || ''} required>
          
                                                    <option>Select</option>:
                                                    <>
                                                      {
                                                        fromCountry?.map((data, index) => (
                                                          <option key={data.countryId} value={data.countryId}>{data.countryname}</option>
                                                        ))
          
                                                      }
                                                    </>
                                                  </Form.Select>
                                                </FloatingLabel>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        </Form>
                                      </fieldset>
                                    </Col>
                                    <Col sm={6}>
                                      <fieldset className="pb-2 mt-2">
                                        <label className="label-heading">To</label>
                                        <Form>
                                          <Row>
                                            <Col sm={12} className="mb-3">
                                            <Form.Group controlId="to" className="mb-2">
                                                {/* <FloatingLabel controlId="floatingSelectGrid" label="To City">
                                                  <Form.Select aria-label="Floating label select example" name="tocity" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.tocity || ''} required> */}
                                                    <Typeahead 
                                                      id='cityAutocomplete'
                                                      labelKey="name"
                                                      onChange={setToSelection}
                                                      options={toCityList}
                                                      placeholder="Choose from city..."
                                                      selected={toSelection}
                                                    />
                                                  {/* </Form.Select>
                                                </FloatingLabel> */}
                                              </Form.Group>
                                              <Form.Group controlId="Province/State" className="mb-2">
                                                <FloatingLabel controlId="floatingSelectGrid" label="To Province/State">
                                                  <Form.Select aria-label="Floating label select example" name="toprov" onChange={(e) => { setSelectState(e.target.value); cwtRatecardHandler(e); setCountry(e) }} value={cwtRatecardData?.toprov?.provineSateName || ''} required>
          
                                                    <option>Select</option>:
                                                    <>
                                                      {
                                                        toProvince?.map((data, index) => (
                                                          <option key={index} value={data.provineSateName}>{data.provineSateName}</option>
                                                        ))
          
                                                      }
                                                    </>
          
                                                  </Form.Select>
          
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group controlId="country" className="mb-2">
                                                <FloatingLabel controlId="floatingSelectGrid" label="To Country">
                                                  <Form.Select aria-label="Floating label select example" name="tocountry" onChange={e => { handletocountry(e); cwtRatecardHandler(e) }} value={cwtRatecardData?.tocountry || ''} required>
          
                                                    <option>Select</option>:
                                                    <>
                                                      {
                                                        toCountry?.map((data, index) => (
                                                          <option key={index} value={data.countryId}>{data.countryname}</option>
                                                        ))
          
                                                      }
                                                    </>
                                                  </Form.Select>
                                                </FloatingLabel>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        </Form>
                                      </fieldset>
                                    </Col>
                                  </Row>
                                </Form>
                              </fieldset>
                            </Col>
                          </Row>
                          </>
                          }
                          {cwtStatus === 1 &&
                            <div className="cwt_form">
                              <Row className="mt-4 mb-3">
                                <Col sm={4}>
                                  <Form.Group controlId="minimumrate">
                                    <FloatingLabel controlId="floatingInput" label="CWT Minimum Rate" >
                                      <Form.Control type="text" placeholder="CWT Minimum Rate" name="MinimumRate" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.MinimumRate || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                {/* <Col sm={4}>
                                  <Form.Group controlId="CWTunitrate">
                                    <FloatingLabel controlId="floatingInput" label="CWT Unit Rate NO FUNCTIONALITY ON THIS" >
                                      <Form.Control type="text" placeholder="CWT Unit Rate" name="UnitRate" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col> */}
                                <Col sm={4}>
                                  <Form.Group controlId="FTL Rate">
                                    <FloatingLabel controlId="floatingInput" label="FTL Rate" >
                                      <Form.Control type="text" placeholder="FTL Rate" name="FTLRate" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.FTLRate || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="mt-2 range_label">
                                <Col sm={2}>
                                  <h6>Range from (0-500) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP0TOLTL" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.UnitRateP0TOLTL || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <h6>Range from (500-1000) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP500TO1000" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.UnitRateP500TO1000 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <h6>Range from (1000-2000) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP1000TO2000" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.UnitRateP1000TO2000 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <h6>Range from (2000-5000) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP2000TO5000" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.UnitRateP2000TO5000 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <h6>Range from (5000-10000) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP5000TO10000" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.UnitRateP5000TO10000 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <h6>Range from (10000-20000) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP10000TO20000" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.UnitRateP10000TO20000 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          }
                          {cwtStatus === 2 &&
                            <Form className="cwt_form">
                              <Row>
                                {/* <Col sm={4}>
                                  <Form.Group controlId="PalletMinimumRate">
                                    <FloatingLabel controlId="floatingInput" label="Pallet Minimum Rate" >
                                      <Form.Control type="number" placeholder="Pallet Minimum Rate" name="MinimumRate" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.MinimumRate || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col> */}
                                <Col sm={6}>
                                  <Form.Group controlId="palletaverageweight">
                                    <FloatingLabel controlId="floatingInput" label="Pallet Average Weight" >
                                      <Form.Control type="text" placeholder="Pallet Average Weight" name="AverageWeight" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.AverageWeight || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={6}>
                                  <Form.Group controlId="ftlrate">
                                    <FloatingLabel controlId="floatingInput" label="FTL Rate" >
                                      <Form.Control type="number" placeholder="FTL Rate" name="FTLRate" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.FTLRate || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="mt-3 range_label">
                                <Col sm={1}>
                                  <h6>1</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate1" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate1 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>2</h6>
                                  <Form.Group controlId="UnitRate2">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate2" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate2 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>3</h6>
                                  <Form.Group controlId="UnitRate3">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate3" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate3 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>4</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate4" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate4 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>5</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate5" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate5 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>6</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate6" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate6 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>7</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate7" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate7 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>8</h6>
                                  <Form.Group controlId="UnitRate8">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate8" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate8 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>9</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate9" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate9 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>10</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate10" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate10 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>11</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate11" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate11 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>12</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate12" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate12 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="mt-3 range_label">
                                <Col sm={1}>
                                  <h6>13</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate13" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate13 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>14</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate14" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate14 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>15</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate15" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate15 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>16</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate16" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate16 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>17</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate17" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate17 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>18</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate18" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate18 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>19</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate19" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate19 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>20</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate20" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate20 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>21</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate21" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate21 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>22</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate22" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate22 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>23</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate23" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate23 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>24</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate24" onChange={e => {cwtRatecardHandler(e);}} value={cwtRatecardData?.UnitRate24 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col sm={12}>
                                  <fieldset className="pb-2">
                                    <label className="label-heading">Pallet OverWeight Charge Section</label>
                                    <Row className="range_label">
                                      <Col sm={2}>
                                        <h6>Range From (0-100)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP0TO100" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.ChargeP0TO100 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={2}>
                                        <h6>Range From (100-200)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP100TO200" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.ChargeP100TO200 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={2}>
                                        <h6>Range From (200-300)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP200TO300" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.ChargeP200TO300 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={2}>
                                        <h6>Range From (300-400)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP300TO400" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.ChargeP300TO400 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={2}>
                                        <h6>Range From (400-500)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP400TO500" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.ChargeP400TO500 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={2}>
                                        <h6>Range From (500-600)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP500TO600" onChange={e => cwtRatecardHandler(e)} value={cwtRatecardData?.ChargeP500TO600 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </fieldset>
                                </Col>
                              </Row>
                            </Form>
                          }
                        </Row>
                      </Col>
                      </Row>
                      </Form>
                    </fieldset>
                  </Col>
                </Row>
              </>
              : null}
            {/*  */}
            {/* table */}
            {cwtStatus === 1 &&
            <>
            <div className="datatable_section">
              <DataGrid rows={testRows} columns={CWTColumns} pageSize={10} pagination components={{ Toolbar: GridToolbar }}  />
            </div> </>}
            {cwtStatus === 2 &&
            <>
            <div className="datatable_section">
              <DataGrid rows={testRows} columns={palletColumns} pageSize={10} pagination components={{ Toolbar: GridToolbar }}  />
            </div> </>}
          </fieldset>
        </Col>
      </Row>
    </>
  );
}
export default CwtRatecard;