import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";


export const lessSlice = apiSlice.injectEndpoints({
    endpoints:builder => ({
      addFuelCost: builder.mutation({
      query(body) {
        return {
          url: `Admin/AddFuelCost`,
          method: "POST",
          body
        };
      },
      }),
      getAllFuelList : builder.query({
        query:() => ({
          url:`Admin/GetAllFuelCostList`,
          method:"GET",
        })
      }),
      updateFuelCost: builder.mutation({
        query: (body) => ({
          url: `Admin/UpdateFuelCost`,
          method: "POST",
          body
        }),
      }),
      deleteFuelCost: builder.mutation({
        query: (fuelCostID) => ({
          url: `Admin/DeleteFuelCost?fuelCostID=${fuelCostID}`,
          method: "POST",
        }),
      }),
    //   deleteLessFuel: builder.mutation({
    //     query : (fuelsurchargerateid) => ({
    //           url: `/Customer/DeleteFuelEffectiveDateSectionLTLFTLHTL?fuelsurchargerateid=${fuelsurchargerateid}`,
    //           method: "POST",
    //       }),
    //   }), 
    //   addCustomerFuelLtl: builder.mutation({
    //     query(body) {
    //       return {
    //         url: `Customer/AddCustomerAddFuelLTLHTLFTLafterEdit`,
    //         method: "POST",
    //         body
    //       };
    //     },
    //     }),
   
    }),
})

export const { useAddFuelCostMutation , useGetAllFuelListQuery, useAddCustomerFuelLtlMutation, useDeleteLessFuelMutation,
  useUpdateFuelCostMutation, useDeleteFuelCostMutation } = apiSlice


const initialState = {
  list: []
};

const fuelCostSlice = createSlice({
  name: "fuelCost",
  initialState,
  reducers: {
      addFuelCost: (state, action) => {
          const lists = {
            id: new Date().getTime().toString(),
            list: action.payload,
          };
          state.list.push(lists);
        },
      deleteFuelCost: (state,action) => {
          state.list = state.list.filter(
              (data) => data.id !== action.payload
          )
      },
      editFuelCost : (state, action) => {
          const currentLists = Array.from(state.list);
          const filterData = state.list.findIndex((list) => list.id === action.payload.id);
          currentLists[filterData] = {
              id:action.payload.id,
              list:action.payload
          }
          return { ...state, list: currentLists };
      },
  },
})

export const { addFuelCost, editFuelCost, deleteFuelCost } = fuelCostSlice.actions

export default fuelCostSlice.reducer