import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useState } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import useCustomer from "../../../../../context/CustomerContext";
import { useAddCustomerFuelLtlMutation, useEditLessFuelEffectiveMutation, useDeleteLessFuelMutation, editLessLoadAfter, addLessLoad } from "../../../../../../src/features/lessLoad/lessLoadSlice";
import { useAddCustomerFuelFtlMutation, useEditFullFuelEffectiveMutation,editFullLoadAfter,useDeleteFullFuelMutation, addFullLoad } from "../../../../../features/fullLoad/fullLoadSlice";
import { useAddCustomerFuelHtlMutation, useEditHeavyFuelEffectiveMutation,useDeleteHeavyFuelMutation,editHeavyLoadAfter, addHeavyLoad } from "../../../../../features/heavyLoad/heavyLoadSlice";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useDeleteData from "../../../../Customer/useDeleteData";

const EditRemoteLogin = (props) => {
  const dispatch = useDispatch();
  const { lessFuelLists, lessTruckLoad, fullFuelLists, heavyFuelLists, singleAccount,handleAccount,getDefaultCard,contractInfo } = useCustomer();
  const ratecardaccount = contractInfo && contractInfo[0]
  const [editLessLoads] = useEditLessFuelEffectiveMutation()
  const [editFullLoads] = useEditFullFuelEffectiveMutation()
  const [editHeavyLoads] = useEditHeavyFuelEffectiveMutation()
  const [addfuelLtl] = useAddCustomerFuelLtlMutation()
  const [addfuelFtl] = useAddCustomerFuelFtlMutation()
  const [addfuelHtl] = useAddCustomerFuelHtlMutation()
  const [isUpdate, setIsUpdate] = useState(false);
  const [lessFuel, setLessFuel] = useState({})
  const [fullFuel, setFullFuel] = useState({})
  const [heavyFuel, setHeavyFuel] = useState({})
  const [editId, setEditId] = useState('')
  const [deleteLessFuel] = useDeleteLessFuelMutation()
  const [deleteFullFuel] = useDeleteFullFuelMutation()
  const [deleteHeavyFuel] = useDeleteHeavyFuelMutation()
  const account = singleAccount && singleAccount[0];
  const [isDelete, setIsDelete] = useState()
  const [remoteOpen, setRemoteOpen] = useState(false);
  const handleRemoteOpen = (data) => {setRemoteOpen(true); setIsDelete(data);};
  const handleRemoteClose = () => {setRemoteOpen(false);};
  const [handleClose,handleOpen,open,deleteData] = useDeleteData(deleteLessFuel);


  const lessFuelhandler = (e) => { setLessFuel(lessFuel => ({ ...lessFuel, [e.target.name]: e.target.value })) }
  const fullFuelhandler = (e) => { setFullFuel(fullFuel => ({ ...fullFuel, [e.target.name]: e.target.value })) }
  const heavyFuelhandler = (e) => { setHeavyFuel(heavyFuel => ({ ...heavyFuel, [e.target.name]: e.target.value })) }

  // edit of LTL
  const addRecordLtl = async () => {
    try {
      const data = {
        "fuelsurchargerateid": editId,
        "rate": lessFuel?.ltlRate,
        "effectiveDate": lessFuel?.ltlEffectiveDate,
        "weghtType": lessFuel?.ltlWeghtType,
        "maxField": lessFuel?.ltlMaxField,
        "carbonTax": lessFuel?.ltlCarbonTax
      }
      const updateData = await editLessLoads(data)
      if (updateData?.data?.statusCode === 200) {
        const newData = {...data, ltlRate: data.rate, ltlEffectiveDate: data.effectiveDate, ltlWeghtType: data.weghtType, ltlMaxField: data.maxField, ltlCarbonTax:data.carbonTax,  id: editId}
        dispatch(editLessLoadAfter(newData));
        setLessFuel({});
        setIsUpdate(false);
      }
    }
    catch {
      toast.warning("An Error Occured!", {
        position: "top-center",
      })
    }
  }

  const editLtl = (fuelsurchargerateid) => {
    setIsUpdate(true);
    setEditId(fuelsurchargerateid)
    let newEditItem = lessFuelLists.find((data) => {
      return data?.fuelsurchargerateid === fuelsurchargerateid
    });
    setLessFuel(newEditItem)
  }

  // edit of FTL
  const addRecordFTL = async () => {
    try {
      const data = {
        "fuelsurchargerateid": editId,
        "rate": fullFuel?.ftlRate,
        "effectiveDate": fullFuel?.ftlEffectiveDate,
        "weghtType": fullFuel?.ftlWeghtType,
        "maxField": fullFuel?.ftlMaxField,
        "carbonTax": fullFuel?.ftlCarbonTax
      }
      const updateData = await editFullLoads(data)
      if (updateData?.data?.statusCode === 200) {
        //setFullFuel("")
        //handleAccount(account.account_No)
        const newData = {...data, ftlRate: data.rate, ftlEffectiveDate: data.effectiveDate, ftlWeghtType: data.weghtType,ftlMaxField:data.maxField, ftlCarbonTax:data.carbonTax,  id: editId}
        dispatch(editFullLoadAfter(newData));
        setFullFuel({});
        setIsUpdate(false);
      }
    }
    catch {
      toast.warning("An Error Occured!", {
        position: "top-center",
      })
    }
  }

  const editFTL = (id) => {
    setIsUpdate(true);
    setEditId(id)
    let newEditItem = fullFuelLists.find((data) => {
      return data?.fuelsurchargerateid === id
    });
    setFullFuel(newEditItem)
  }

  // edit of HTL
  const addRecordHTL = async () => {
    try {
      const data = {
        "fuelsurchargerateid": editId,
        "rate": heavyFuel?.htlRate,
        "effectiveDate": heavyFuel?.htlEffectiveDate,
        "weghtType": heavyFuel?.htlWeghtType,
        "maxField": heavyFuel?.htlMaxField,
        "carbonTax": heavyFuel?.htlCarbonTax
      }
      const updateData = await editHeavyLoads(data)
      if (updateData?.data?.statusCode === 200) {
        //setHeavyFuel("")
        //handleAccount(account.account_No)
        const newData = {...data, htlRate: data.rate, htlEffectiveDate: data.effectiveDate, htlWeghtType: data.weghtType,htlMaxField: data.maxField, htlCarbonTax: data.carbonTax,  id: editId}
        dispatch(editHeavyLoadAfter(newData));
        setHeavyFuel({});
        setIsUpdate(false);
      }
    }
    catch {
      toast.warning("An Error Occured!", {
        position: "top-center",
      })
    }
  }
  const editHTL = (id) => {
    setIsUpdate(true);
    setEditId(id)
    let newEditItem = heavyFuelLists.find((data) => {
      return data?.fuelsurchargerateid === id
    });
    setHeavyFuel(newEditItem)
  }

  //adding new record of LTL
  const addnewRecordLtl = async () => {
    const data = {
      "rate": lessFuel?.ltlRate,
      "effectiveDate": lessFuel?.ltlEffectiveDate,
      "weghtType": "LTL",
      "maxField": lessFuel?.ltlMaxField,
      "carbonTax": lessFuel?.ltlCarbonTax
    }


    const addnewLTL = await addfuelLtl({ rateCardId: props.selectedCard, ...data })
    if (addnewLTL?.data?.statusCode === 200) {
      setLessFuel("")
      const mapData = {...data, ltlRate: data.rate, ltlEffectiveDate: data.effectiveDate, ltlWeghtType: data.weghtType}
      dispatch(addLessLoad(mapData));
      getDefaultCard(props.selectedCard)
      //handleAccount(account.account_No)
    }
  }

  //adding new record of FTL
  const addNewRecordFTL = async () => {
    const data = {
      "rate": fullFuel?.ftlRate,
      "effectiveDate": fullFuel?.ftlEffectiveDate,
      "weghtType": "FTL",
      "maxField": fullFuel?.ftlMaxField,
      "carbonTax": fullFuel?.ftlCarbonTax
    }

    const addnewFTL = await addfuelFtl({ rateCardId: props.selectedCard, ...data })
    if (addnewFTL?.data?.statusCode === 200) {
      setFullFuel("")
      const mapData = {...data, ftlRate: data.rate, ftlEffectiveDate: data.effectiveDate, ftlWeghtType: data.weghtType}
      dispatch(addFullLoad(mapData));
      getDefaultCard(props.selectedCard)
      //handleAccount(account.account_No)
    }
  }

  //adding new record of HTL
  const addNewRecordHTL = async () => {
    const data = {
      "rate": heavyFuel?.htlRate,
      "effectiveDate": heavyFuel?.htlEffectiveDate,
      "weghtType": "HTL",
      "maxField": heavyFuel?.htlMaxField,
      "carbonTax": heavyFuel?.htlCarbonTax
    }

    const addnewHTL = await addfuelHtl({ rateCardId: props.selectedCard, ...data })
    if (addnewHTL?.data?.statusCode === 200) {
      setHeavyFuel("")
      const mapData = {...data, htlRate: data.rate, htlEffectiveDate: data.effectiveDate, htlWeghtType: data.weghtType}
      dispatch(addHeavyLoad(mapData));
      getDefaultCard(props.selectedCard)
      //handleAccount(account.account_No)
    }
  }

  // delete LTL

  const deleteLtl =  async () => {
    const deleteFuelRecord = await deleteLessFuel(isDelete)
        if (deleteFuelRecord.data.statusCode === 200) {
            setTimeout(function () {
                toast.success("Fuel record deleted successfully !", {
                    position: "top-right",
                })
            });
            getDefaultCard(props.selectedCard)
        }
        // handleAccount(account?.account_No)
        handleRemoteClose();
  }

  // delete FTL
  const deleteFtl =  async () => {
    const deleteFuelRecord = await deleteFullFuel(isDelete)
        if (deleteFuelRecord.data.statusCode === 200) {
            setTimeout(function () {
                toast.success("Fuel record deleted successfully !", {
                    position: "top-right",
                })
            });
            getDefaultCard(props.selectedCard)
        }
        // handleAccount(account?.account_No)
        handleRemoteClose();
  }

  // delete HTL
  const deleteHtl =  async () => {
    const deleteFuelRecord = await deleteHeavyFuel(isDelete)
        if (deleteFuelRecord.data.statusCode === 200) {
            setTimeout(function () {
                toast.success("Fuel record deleted successfully !", {
                    position: "top-right",
                })
            });
            getDefaultCard(props.selectedCard)
        }
        // handleAccount(account?.account_No)
        handleRemoteClose();
  }

  return (
    <>

      {/* Remote login info */}
      <Row className="mb-4 mt-4">
        <Col sm={12}>
          <fieldset className="mb-3">
            <label className="label-heading">Remote Login Info</label>
            <Row>
              <Col sm={4}>
                <fieldset className="less_load_table">
                  <label className="label-heading">Effective date on less load (LTL)</label>
                  <Row>
                     <Col sm={5} className="justify-content-between">
                          <Form.Group controldId="MaxField">
                            <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                              <Form.Control type="maxField" placeholder="Max Field" name="ltlMaxField"  onChange={e => lessFuelhandler(e)} value={lessFuel?.ltlMaxField || ''} />
                              
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="taxField" placeholder="Tax Field" name="ltlCarbonTax" onChange={e => lessFuelhandler(e)} value={lessFuel?.ltlCarbonTax || ''} />
                              
                            </FloatingLabel>
                          </Form.Group>
                        </Col> 
                        
                      <Col sm={3} className="justify-content-between">
                        <div className="section_action_btn">
                        {isUpdate ?
                        <div className="save_btn"><button type="button" className="btn" onClick={addRecordLtl}>Update record</button></div>
                            
                            :
                            <div className="add_btn"><button type="button" className="btn" onClick={addnewRecordLtl}>Add new record</button></div>
                          }
                        </div>
                      </Col>
                      </Row>
                  <>
                    <Row>
                          <Row className="mt-2 mb-3">
                            <Col sm={12} className="mb-2">
                              <Form.Group controlId="Date">
                                <FloatingLabel controlId="floatingInput" label="Date" >
                                  <Form.Control type="date" placeholder="Date" name="ltlEffectiveDate" onChange={e => lessFuelhandler(e)} value={lessFuel?.ltlEffectiveDate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                            <Col sm={12}>
                              <Form.Group controlId="ToCost">
                                <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                                  <Form.Control type="number" placeholder="Fuel Charge" name="ltlRate" onChange={e => lessFuelhandler(e)} value={lessFuel?.ltlRate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                          </Row>
                  
                      <Col sm={12}>
                        <Row>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Date</th>
                                <th>Fuel Charge</th>
                                 <th>Max Field</th>
                                <th>Carbon Tax</th> 
                              </tr>
                            </thead>
                            <tbody>
                              {lessFuelLists?.length > 0  ? (
                                   lessFuelLists?.map((data, index) => {
                                    return(
                                    <tr key={index}>
                                      <td>
                                        <div className="action_btn">
                                        <span className="edit_btn" onClick={() => { editLtl(data?.fuelsurchargerateid) }}><Edit /></span>
                                        <span className="del_btn" onClick={() => handleRemoteOpen(data?.fuelsurchargerateid)}><Delete /></span>
                                        </div>
                                        <Dialog open={remoteOpen} onClose={handleRemoteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                          <DialogTitle id="alert-dialog-title">
                                            Delete Confirmation
                                          </DialogTitle>
                                          <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                              Are you sure you want to delete this row?
                                            </DialogContentText>
                                          </DialogContent>
                                          <DialogActions >
                                            <Button className="btn-secondary" onClick={handleRemoteClose}>Cancel</Button>
                                            <Button className="btn-primary" onClick={() => { deleteLtl()}} autoFocus> Confirm</Button>
                                          </DialogActions>
                                        </Dialog>
                                      </td>
                                      <td>{data.ltlEffectiveDate}</td>
                                      <td>{data.ltlRate}</td>
                                      <td>{data?.ltlMaxField}</td>
                                      <td>{data?.ltlCarbonTax}</td>
                                    </tr>
                                 )})
                                 ) : (
                                   <p>No data found </p>
                                    )}
                            </tbody>
                          </Table>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
              <Col sm={4}>
                <fieldset className="full_load_table">
                  <label className="label-heading">Effective date on full load (FTL)</label>
                  <Row>
                    <Col sm={5} className="justify-content-between">
                          <Form.Group controldId="MaxField">
                            <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                              <Form.Control type="maxField" placeholder="Max Field" name="ftlMaxField" onChange={e => fullFuelhandler(e)} value={fullFuel?.ftlMaxField || ''} />
                              
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="taxField" placeholder="Tax Field"  name="ftlCarbonTax" onChange={e => fullFuelhandler(e)} value={fullFuel?.ftlCarbonTax || ''} />
                              
                            </FloatingLabel>
                          </Form.Group>
                        </Col> 
                  <Col sm={3} className="justify-content-between">
                  <div className="section_action_btn">
                      {isUpdate ?
                      <div className="save_btn"><button type="button" className="btn" onClick={addRecordFTL}>Update record</button></div>
                        
                        :
                        <div className="add_btn"><button type="button" className="btn" onClick={addNewRecordFTL}>Add new record</button></div>
                      }
                    </div>
                  </Col>
                  </Row>
                  <>
                    <Row>
             
                          <Row className="mt-2 mb-3">
                            <Col sm={12} className="mb-2">
                              <Form.Group controlId="Date">
                                <FloatingLabel controlId="floatingInput" label="Date" >
                                  <Form.Control type="date" placeholder="Date" name="ftlEffectiveDate" onChange={e => fullFuelhandler(e)} value={fullFuel?.ftlEffectiveDate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                            <Col sm={12}>
                              <Form.Group controlId="ToCost">
                                <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                                  <Form.Control type="number" placeholder="Fuel Charge" name="ftlRate" onChange={e => fullFuelhandler(e)} value={fullFuel?.ftlRate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                          </Row>
                 
                      <Col sm={12}>
                        <Row>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Date</th>
                                <th>Fuel Charge</th>
                                <th>Max Field</th>
                                  <th>Carbon Tax</th> 
                              </tr>
                            </thead>
                            <tbody>
                              {fullFuelLists?.length > 0 ? (
                              fullFuelLists?.map((data, index) => {
                                return(
                                <tr key={index}>
                                  <td>
                                    <div className="action_btn">
                                    <span className="edit_btn" onClick={() => editFTL(data?.fuelsurchargerateid)}><Edit /></span>
                                    <span className="del_btn" onClick={() => handleRemoteOpen(data?.fuelsurchargerateid)}><Delete /></span>
                                    </div>
                                    <Dialog open={remoteOpen} onClose={handleRemoteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                          <DialogTitle id="alert-dialog-title">
                                            Delete Confirmation
                                          </DialogTitle>
                                          <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                              Are you sure you want to delete this row?
                                            </DialogContentText>
                                          </DialogContent>
                                          <DialogActions >
                                            <Button className="btn-secondary" onClick={handleRemoteClose}>Cancel</Button>
                                            <Button className="btn-primary" onClick={() => { deleteFtl()}} autoFocus> Confirm</Button>
                                          </DialogActions>
                                        </Dialog>
                                  </td>
                                  <td>{data.ftlEffectiveDate}</td>
                                  <td>{data.ftlRate}</td>
                                  <td>{data?.ftlMaxField}</td>
                                      <td>{data?.ftlCarbonTax}</td>
                                </tr>
                                )})
                              ):(
                                <p>No data found</p>
                              )}
                            </tbody>
                          </Table>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
              <Col sm={4}>
                <fieldset className="heavy_load_table">
                  <label className="label-heading">Effective date on heavy equipment load (HTL)</label>
                  <Row>
                    <Col sm={5} className="justify-content-between">
                          <Form.Group controldId="MaxField">
                            <FloatingLabel controlId="floatingInput" label="Max Field" className="max-field-label">
                              <Form.Control type="maxField" placeholder="Max Field" name="htlMaxField" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.htlMaxField || ''} /> 
                              
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={4} className="justify-content-between">
                          <Form.Group controldId="TaxField">
                            <FloatingLabel controlId="floatingInput" label="Carbon Tax" className="tax-field-label">
                              <Form.Control type="taxField" placeholder="Tax Field" name="htlCarbonTax" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.htlCarbonTax || ''} />
                              
                            </FloatingLabel>
                          </Form.Group>
                        </Col> 
                  <Col sm={3} className="justify-content-between">
                    <div className="section_action_btn">
                    {isUpdate ?
                    <div className="save_btn"><button type="button" className="btn" onClick={addRecordHTL}>Update record</button></div>
                         :
                         <div className="add_btn"><button type="button" className="btn" onClick={addNewRecordHTL}  >Add new record</button></div>
                      }
                    </div>
                  </Col>
                  </Row>
                  <>
                    <Row>
                     
                          <Row className="mt-2 mb-3">
                            <Col sm={12} className="mb-2">
                              <Form.Group controlId="Date">
                                <FloatingLabel controlId="floatingInput" label="Date" >
                                  <Form.Control type="date" placeholder="Date" name="htlEffectiveDate" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.htlEffectiveDate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                            <Col sm={12}>
                              <Form.Group controlId="ToCost">
                                <FloatingLabel controlId="floatingInput" label="Fuel Charge (%)" >
                                  <Form.Control type="number" placeholder="Fuel Charge" name="htlRate" onChange={e => heavyFuelhandler(e)} value={heavyFuel?.htlRate || ''} />
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                          </Row>
                
                      <Col sm={12}>
                        <Row>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Date</th>
                                <th>Fuel Charge</th>
                                <th>Max Field</th>
                                  <th>Carbon Tax</th> 
                              </tr>
                            </thead>
                            <tbody>
                              {heavyFuelLists?.length > 0 ? ( 
                              heavyFuelLists?.map((data, index) => {
                                return(
                                <tr key={index}>
                                  <td>
                                    <div className="action_btn">
                                    <span className="edit_btn" onClick={() => editHTL(data?.fuelsurchargerateid)}><Edit /></span>
                                    <span className="del_btn" onClick={() => handleRemoteOpen(data?.fuelsurchargerateid)}><Delete /></span>
                                    </div>
                                    <Dialog open={remoteOpen} onClose={handleRemoteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                          <DialogTitle id="alert-dialog-title">
                                            Delete Confirmation
                                          </DialogTitle>
                                          <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                              Are you sure you want to delete this row?
                                            </DialogContentText>
                                          </DialogContent>
                                          <DialogActions >
                                            <Button className="btn-secondary" onClick={handleRemoteClose}>Cancel</Button>
                                            <Button className="btn-primary" onClick={() => { deleteHtl()}} autoFocus> Confirm</Button>
                                          </DialogActions>
                                        </Dialog>
                                  </td>
                                  <td>{data.htlEffectiveDate}</td>
                                  <td>{data.htlRate}</td>
                                  <td>{data?.htlMaxField}</td>
                                      <td>{data?.htlCarbonTax}</td>
                                </tr>
                                )})
                              ):(
                                <p>No data found</p>
                                )}
                            </tbody>
                          </Table>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </fieldset>
              </Col>
            </Row>
          </fieldset>
        </Col>
      </Row>
    </>

  );
}
export default EditRemoteLogin;