import { Col, Row, Form, FloatingLabel, } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import VehicleSurcharge from "./Vehicle/VehicleSurcharge";
import ServiceSurcharge from "./Service/ServiceSurcharge";
import AccessorialSurcharge from "./Accessorial/AccessorialSurcharge";
import CwtRatecard from "./CwtRateCard/CwtRatecard";
import CityDistance from "./FuelSurcharge/CityDistance";
import DistanceFuel from "./FuelSurcharge/DistanceFuel";
import RemoteLogin from "./FuelSurcharge/RemoteLogin";
import ContractData from "./Contract/ContractData";
import Specialaddress from "./Specialaddress";
import { useState } from "react";
import EditContractdata from "./Contract/EditContractData";
import SaveContractdata from "./Contract/SaveContractData";
import useCustomer from "../../context/CustomerContext";
import EditVehicleSurcharge from "./Vehicle/EditVehicleSurcharge";
import EditServiceSurcharge from "./Service/EditServiceSurcharge";
import EditAccessorialSurcharge from "./Accessorial/EditAccessorial";
import EditCwtRatecard from "./CwtRateCard/EditCwtRatecard";
import EditCityDistance from "./FuelSurcharge/EditCitydistance";
import EditDistanceFuel from "./FuelSurcharge/EditDistanceFuel";
import EditRemoteLogin from "./FuelSurcharge/EditRemoteLogin";
import { useCustomData } from "../Sidebar/Permission";
import { ToastContainer, toast } from 'react-toastify';
import ServiceSurchargeSection from "./Service/ServiceSurchargeSection";
import { Add } from "@material-ui/icons";
import Spinner from "../Spinner";
import FuelCostTable from "./FuelSurcharge/FuelCostTable";
import { useGetDefaultPricingRateCardListQuery } from "../../features/cwtRateCard/cwtRateCardSlice";

const Contract = () =>{
  const [permission] = useCustomData()
  const {errors, selected, contractInfo, validateContractPricing, multiplier, setMultiplier, getDefaultCard} = useCustomer();
  const rateCard = contractInfo? contractInfo.length > 0: false;
  const ratecardaccount = contractInfo && contractInfo[0]
  const [showEditRate, setShowEditRate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(0);
  const { data: rateCards} = useGetDefaultPricingRateCardListQuery();
  const combinations = {1: 'Zone-CWT', 2: 'Region-CWT', 3: 'Zone-Pallet', 4: 'Region-Pallet'}
  const showedithandler =() =>{
    setShowEditRate(!showEditRate);}
    return(
      <>
      {(selected && !rateCard) && 
        <Col style={{marginBottom: '15px'}}>
          <div className="button-section">
            <button className="add-btn btn btn-primary" onClick={() => validateContractPricing(selectedCard)}  >
            {!loading ? <> Add Rate Card<span> <Add /></span> </> : <Spinner />}
            </button>
          </div>
        </Col>
        }
   <Accordion defaultActiveKey="0">
  <Accordion.Item eventKey="0">
    <Accordion.Header>Rate Card</Accordion.Header>
    <Accordion.Body>
    <>
      {/* Contract Info */}
      <Row className="mb-4 justify-content-between">
        {(selected && !rateCard) &&
        <> 
        <Col sm={3}>
          { /* {(selected && !rateCard) &&  */}
          <Form.Select aria-label="Default select example" 
            onChange={(e) => {
                if (e.target.value !== '0') {
                    getDefaultCard(e.target.value)
                }
                setSelectedCard(e.target.value);
            }}
          > 
            <option value='0'>Choose a Default Rate Card</option>
            {rateCards?.data && rateCards.data.map((rateCard) => (
                <option value={rateCard.rateCardId}>{combinations[rateCard.rateCardId]}</option>
                ))
            }
          </Form.Select>   
        </Col>
        
        <Col sm={2}>
          <div className="form-floating">
            <Form.Control type="number" placeholder="Multiplier" name="Multiplier" onChange={(e) => { setMultiplier(e.target.value); }}
              className={`input ${errors.Multiplier && 'is-danger'}`} value={multiplier} required />
            <label htmlFor="multiplier">Multiplier (%)</label>
            {/* {errors.Multiplier && (<p className="help is-danger">{errors.Multiplier}</p>)} */}
          </div>
        </Col> </>}       {/* <Col sm={4}>
          <Form.Group controlId="Remotelogin">
            <FloatingLabel controlId="floatingInput" label="Rate Card ID" >
              <Form.Control type="text" placeholder="Rate Card ID" name="ReferenceRateCardId" value={ratecardaccount?.rateCardId} required />
            </FloatingLabel>
          </Form.Group>
        </Col> */}
      </Row>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Contract Info Section</Accordion.Header>
          <Accordion.Body>
            {showEditRate ? 
            <>{selected && rateCard ? 
              <> {permission?.find(event => event.moduleId === 40)? <EditContractdata showedithandler={showedithandler}/>:<p className="no-access-msg">No Access Permission</p> }</> :
           <> {permission?.find(event => event.moduleId === 40)? <ContractData /> : <p className="no-access-msg">No Access Permission</p> }</>
            }</>
            :<SaveContractdata showedithandler={showedithandler} />}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Vehicle Surcharge Section</Accordion.Header>
          <Accordion.Body>
            {/* Vehicle surcharge section*/}
            {selected && rateCard? 
             <> {permission?.find(event => event.moduleId === 41)?<EditVehicleSurcharge /> : <p className="no-access-msg">No Access Permission</p>}</>: 
          <> {permission?.find(event => event.moduleId === 41)? <VehicleSurcharge />: <p className="no-access-msg">No Access Permission</p>}</>
            }
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Service Surcharge Section</Accordion.Header>
          <Accordion.Body>
            {/* Service surcharge section*/}
            {selected && rateCard?
             <> {permission?.find(event => event.moduleId === 42)? <EditServiceSurcharge /> : <p className="no-access-msg">No Access Permission</p>}</>:
           <> {permission?.find(event => event.moduleId === 42)? <ServiceSurchargeSection />: <p className="no-access-msg">No Access Permission</p>}</>
             }
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Accessorial Charge Section</Accordion.Header>
          <Accordion.Body>
            {/* Accessorial Charge section*/}
            {selected && rateCard?
             <> {permission?.find(event => event.moduleId === 43)? <EditAccessorialSurcharge /> : <p className="no-access-msg">No Access Permission</p>}</> :
             <> {permission?.find(event => event.moduleId === 43)?<AccessorialSurcharge /> : <p className="no-access-msg">No Access Permission</p>}</>}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>CWT Rate Card Section</Accordion.Header>
          <Accordion.Body>
            {/* CWT Rate Card Section*/}
            {selected && rateCard? 
            <> {permission?.find(event => event.moduleId === 44)?<EditCwtRatecard /> : <p className="no-access-msg">No Access Permission</p>}</>:
            <> {permission?.find(event => event.moduleId === 44)?<CwtRatecard />: <p className="no-access-msg">No Access Permission</p>}</>
           }
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Fuel Surcharge Section</Accordion.Header>
          <Accordion.Body>
            {((!contractInfo.FuelSurchargeMethod || contractInfo.FuelSurchargeMethod === 'Select') && (Array.isArray(contractInfo)) && contractInfo.length === 0) &&
              <Row className="mb-4 mt-4"> 
                <p>Please choose a Fuel Surcharge Method in Contract Info Section </p>
              </Row>
            }
            {(contractInfo.FuelSurchargeMethod === 2 || contractInfo.FuelSurchargeMethod === '2' || (selected && contractInfo.length > 0 && contractInfo[0].fuelSurchargeMethod === 2)) && <>
              {selected && rateCard? 
                <> {permission?.find(event => event.moduleId === 45)? <EditCityDistance />: <p className="no-access-msg">No Access Permission</p>}</> : 
              <> {permission?.find(event => event.moduleId === 45)? <CityDistance />: <p className="no-access-msg">No Access Permission</p>}</>
              }
              {selected && rateCard? 
                <> {permission?.find(event => event.moduleId === 45)?<EditDistanceFuel /> : <p className="no-access-msg">No Access Permission</p>}</> : 
              <> {permission?.find(event => event.moduleId === 45)? <DistanceFuel />: <p className="no-access-msg">No Access Permission</p>}</>
              } 
              <FuelCostTable /> </>
            }
            {(contractInfo.FuelSurchargeMethod === 1 || contractInfo.FuelSurchargeMethod === '1' || (selected && contractInfo.length > 0 && contractInfo[0].fuelSurchargeMethod === 1)) && <>
              {selected && rateCard? 
              <> {permission?.find(event => event.moduleId === 45)?<EditRemoteLogin /> : <p className="no-access-msg">No Access Permission</p>}</> : 
              <> {permission?.find(event => event.moduleId === 45)? <RemoteLogin />: <p className="no-access-msg">No Access Permission</p>}</>
              } </>
            }
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Special Address</Accordion.Header>
    <Accordion.Body>
      <Specialaddress />
    </Accordion.Body>
  </Accordion.Item>
</Accordion>
</>

    )
}
export default Contract;
