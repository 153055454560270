import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Delete, Edit, Save, AddCircle } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../../../context/CustomerContext";
import { useGetCityQuery } from "../../../../../features/city/citySlice";
import { useGetCountryQuery, useLazyGetStateQuery } from "../../../../../features/countryState/countryStateSlice";
import { useUpdateCityDistanceDataMutation, useDeleteCityDistanceDataMutation,useAddCustomerCityDistanceAfterEditMutation, addCityDistance } from "../../../../../features/cityDistance/cityDistanceSlice";
import { useModifyHistoryMutation } from "../../../../../features/customer/history/historySlice";
import { useRef } from "react";
import { useCustomData } from "../../../../Sidebar/Permission";
import CountryProvince from "../../../../CountryProvince";
import { Typeahead } from 'react-bootstrap-typeahead';


const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
const EditCityDistance = (props) => {
    const [permission] = useCustomData()
    const { city, province, handleProvince, newHandleCountry } = CountryProvince();
    const [fetchState] = useLazyGetStateQuery()
    const [fetchToState] = useLazyGetStateQuery()
    const cityList = city?.data.map((a) => { return {name: a.city, id: a.id}});
    const [updateCityDistance] = useUpdateCityDistanceDataMutation()
    const [deleteCityDistance] = useDeleteCityDistanceDataMutation()
    const [cityAfterEdit] = useAddCustomerCityDistanceAfterEditMutation();
    const [modifyHistory] = useModifyHistoryMutation()
    const { handleAccount, singleAccount, cityDistanceLists,getDefaultCard } = useCustomer();
    const account = singleAccount && singleAccount[0];
    const [message, setMessage] = useState("Modified the city distance section")
    const [isEditItem, setIsEditItem] = useState(null);
    const [cityDistance, setCityDistance] = useState({})
    const [showDistanceSecondFields, setShowDistanceSecondFields] = useState(false);
    const [editId, setEditId] = useState('');
    const [isDelete, setIsDelete] = useState();
    const [selectState, setSelectState] = useState([]);
    const [selectToState, setSelectToState] = useState([]);
    const [distanceOpen, setDistanceOpen] = useState(false);
    const handleDistanceOpen = (data) => { setDistanceOpen(true); setIsDelete(data) };
    const handleDistanceClose = () => { setDistanceOpen(false); };
    const prevCount = usePrevious(cityDistance);
    const [isUpdate, setIsUpdate] = useState(false);
    const dispatch = useDispatch();
    // const cityList = city?.data.map((a) => { return {name: a.city, id: a.id}});
    const toCityList = cityList;
    const fromCityList = cityList;
    const [toCountryList, setToCountryList] = useState([]);
    const [fromCountryList, setFromCountryList] = useState([]);
    const [toSelection, setToSelection] = useState([]);
    const [fromSelection, setFromSelection] = useState([]);

    const [toProvince, setToProvince] = useState([]);
    const [fromProvince, setFromProvince] = useState([]);
    const [toCountry, setToCountry] = useState([]);
    const [fromCountry, setFromCountry] = useState([]);
    
    const addCitydata = {
        rateCardID: props?.selectedCard,
        fromCountryId: cityDistance?.FromCountry,
        toCountryId: cityDistance?.ToCountry,
        fromCityId: cityDistance?.FromCity,
        toCityId: cityDistance?.ToCity,
        fromProvinceId: cityDistance?.FromProvince,
        toProvinceId: cityDistance?.ToProvince,
        miles: cityDistance?.Miles,
        timeInMinutes: cityDistance?.TimeInMinutes
    }
    const updateCitydata = {
        citydistanceID: cityDistance?.CityDistanceId,
        fromCountry: cityDistance?.FromCountry,
        toCountry: cityDistance?.ToCountry,
        fromCity: cityDistance?.FromCity,
        toCity: cityDistance?.ToCity,
        fromProvince: cityDistance?.FromProvince,
        toProvince: cityDistance?.ToProvince,
        miles: cityDistance?.Miles,
        timeInMinutes: cityDistance?.TimeInMinutes
    }

    const addNewSection = async () => {
        setShowDistanceSecondFields(true);
        if (Object.keys(cityDistance).length === 0) {
            setShowDistanceSecondFields(true);
            return;
        }
        else {
            console.log(addCitydata);
            console.log(cityDistance);
            const cityDistanceAfterEdit = await cityAfterEdit(addCitydata);
            console.log(cityDistanceAfterEdit);
            setCityDistance("");
            //handleAccount(account?.account_No)
            if (cityDistanceAfterEdit?.data?.statusCode === 200) {
                toast.success("City Distance record added successfully !", {
                    position: "top-right",
                })
                dispatch(addCityDistance(addCitydata));
                getDefaultCard(props.selectedCard)
                setFromSelection([]);
                setToSelection([]);
            }
        }    
    }

    
      // hook for To City
    useEffect(async () => {
        if (toSelection.length > 0) {
            const pList = await handleProvince(toSelection[0].id);
            setCityDistance(cityDistance => ({ ...cityDistance, ToCity: toSelection[0].id }))
            setToProvince(pList.data);
        }
        else {
            setToProvince([]);
            setToCountry([]);
            setCityDistance(cityDistance => ({ ...cityDistance, ToCity: "" }))
        }
    }, [toSelection])
    // hook for From City
    useEffect(async () => {
        if (fromSelection.length > 0) {
            const pList = await handleProvince(fromSelection[0].id);
            setCityDistance(cityDistance => ({ ...cityDistance, FromCity: fromSelection[0].id }))
            setFromProvince(pList.data);
        }
        else {
            setFromProvince([]);
            setFromCountry([]);
            setCityDistance(cityDistance => ({ ...cityDistance, FromCity: "" }))
        }
}   , [fromSelection])

const setCountry = async (e) => {
    if (e.target.value !== 'Select') {
        let cList;
        if (e.target.name === "FromProvince") {
            cList = await newHandleCountry(fromProvince.data[0].provineStateID);
            setFromCountryList(cList.data);
        }
        else if (e.target.name === "ToProvince") {
            cList = await newHandleCountry(toProvince.data[0].provineStateID);
            setToCountryList(cList.data);
        }
    }
    else {
        if (e.target.name === "FromProvince") {
            setFromCountryList([]);
        }
        else {
            setToCountryList([]);
        }
    }
}

    const getCityName = (id) => { const city = cityList.find(d => d.id == id); return city?.name; }

    const getProvinceName = (id) => { return province.data[parseInt(id) - 1]?.provineSateName }

    const getData = async (selectState) => {
        fetchState(selectState)
    }
    const getStateData = async (selectToState) => {
        fetchToState(selectToState)
    }
    const handlecountry = async (e) => {
        const addressData = e.target.value;
        const selectedState = await getData(addressData)
        setSelectState(selectedState)
    }

    const handletocountry = async (e) => {
        const addressData = e.target.value;
        const selectedState = await getStateData(addressData)
        setSelectToState(selectedState)
    }

    const Cityupdate = (e) => { setCityDistance(cityDistance => ({ ...cityDistance, [e.target.name]: e.target.value })) }

    const EditcityItem = (citydistanceID) => {
        setIsUpdate(true);
        setEditId(citydistanceID)
        let newEditItem = cityDistanceLists.find((data) => {
            return data.CityDistanceId === citydistanceID
        });
        setCityDistance(newEditItem);
        setShowDistanceSecondFields(showDistanceSecondFields => !showDistanceSecondFields)
        setIsEditItem(citydistanceID);
        setFromSelection(newEditItem.FromCity? [{name: newEditItem.FromCityName, id: newEditItem.FromCity}]: []);
        setToSelection(newEditItem.ToCity? [{name: newEditItem.ToCityName, id: newEditItem.ToCity}]: []);
        setToCountry(newEditItem.ToCountry? [{countryId: newEditItem.ToCountry, countryname: newEditItem.ToCountryName}]: []);
        setFromCountry(newEditItem.FromCountry? [{countryId: newEditItem.FromCountry, countryname: newEditItem.FromCountryName}]: []);
    }

    const editCityCustomerList = async () => {
        if (JSON?.stringify(prevCount) === JSON?.stringify(cityDistance)) {
            setTimeout(function () {
                toast.info("No Changes found on this City distance section !", {
                    position: "top-right",
                })
            });
        }
        else {
            //const newData = {...cityDistance, citydistanceID: cityDistance.CityDistanceId}
            console.log(updateCitydata);
            const updateCityDistanceData = await updateCityDistance(updateCitydata)
            setCityDistance({})
            setFromSelection([])
            setToSelection([])
            setFromCountryList([]);
            setToCountryList([]);
            // handleAccount(account.account_No)
            if (updateCityDistanceData.data.statusCode === 200) {
                setTimeout(function () {
                  toast.success("City distance updated successfully !", {
                    position: "top-right",
                  })
                });
            }
            getDefaultCard(props.selectedCard)
        }
        // if (JSON?.stringify(prevCount) !== JSON?.stringify(cityDistance)) {
        //     const modifieddata = {
        //         AccountNo: account.account_No,
        //         CustomerName: account.customer,
        //         Modifiedsection: message
        //     }
        //     await modifyHistory(modifieddata)
        // }
    }

    const deleteCityCustomerList = async () => {
        const deleteCityDistanceRecord = await deleteCityDistance(isDelete)
        if (deleteCityDistanceRecord.data.statusCode === 200) {
            setTimeout(function () {
                toast.success("City distance record deleted successfully !", {
                    position: "top-right",
                })
            });
            getDefaultCard(props.selectedCard)
        }
        // handleAccount(account?.account_No)
        handleDistanceClose();
    }

    useEffect(() => {
        async function fetchData() {
            let list = await newHandleCountry(fromProvince.data[0].provineStateID);
            setFromCountryList(list.data);
            list = await newHandleCountry(toProvince.data[0].provineStateID);
            setToCountryList(list.data);
        }   
        fetchData();
    }, [fromProvince,toProvince]);

    return (
        <>
            {/* City Distance section */}
            <Row className="mb-4 mt-4">
                <Col sm={12}>
                    <fieldset className="mb-3">
                        <label className="label-heading">City Distance Table</label>
                        <Row className="mb-2 mt-2">
                            <Col sm={12}>
                                <fieldset className="pb-2">
                                    <div className="section_action_btn">
                                    { cityDistance?.CityDistanceId ?
                                    <>
                                    {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ? 
                                        <div className="save_btn"><button type="button" className="btn" onClick={e => { editCityCustomerList(e); }}>Update record</button></div>
                                        : <div className="save_btn disable"><button type="button" className="btn">Update record</button></div> }
                                     </> : 
                                     <>
                                     {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                                         <div className="add_btn"><button type="button" className="btn" onClick={e => { addNewSection(e); }}>Add new record</button></div>
                                         : <div className="save_btn disable"><button type="button" className="btn">Add new record</button></div>}
                                    </>
                                     }
                                    </div>
                                    {showDistanceSecondFields ?
                                        <>
                                            <Row className="mt-2 mb-3">
                                                <Col sm={3}>
                                                    <Form.Group controlId="from" className="mb-2">
                                                        {/* <FloatingLabel controlId="floatingSelectGrid" label="From City">
                                                            <Form.Select aria-label="Floating label select example" name="FromCity" onChange={e => Citydistancehandler(e)} value={cityDistance.FromCity || ''} required> */}
                                                                <Typeahead 
                                                                id='cityAutocomplete'
                                                                labelKey="name"
                                                                onChange={setFromSelection}
                                                                options={fromCityList}
                                                                placeholder="Choose from city..."
                                                                selected={fromSelection}
                                                            />                                                                
                                                            {/* </Form.Select>
                                                        </FloatingLabel> */}
                                                    </Form.Group>

                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Province/State" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="From Province/State">
                                                            <Form.Select aria-label="Floating label select example" name="FromProvince" onChange={(e) => { setSelectState(e.target.value); Cityupdate(e); setCountry(e) }} value={cityDistance?.FromProvince || ""} required>

                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        fromProvince?.data?.map((data, index) => (
                                                                            <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                                                        ))

                                                                    }
                                                                </>

                                                            </Form.Select>

                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="country" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="From Country">
                                                            <Form.Select aria-label="Floating label select example" onChange={e => { handlecountry(e); Cityupdate(e) }} placeholder="From Country" name="FromCountry" value={cityDistance?.FromCountry || ""} required>

                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        fromCountryList?.data?.map((data, index) => (
                                                                            <option key={index} value={data.countryId}>{data.countryname}</option>
                                                                        ))

                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Miles">
                                                        <FloatingLabel controlId="floatingInput" label="Miles" >
                                                            <Form.Control type="text" placeholder="Miles" name="Miles" onChange={e => Cityupdate(e)} value={cityDistance?.Miles || ""} min='0'/>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {/* 2nd Row */}

                                            <Row className="mt-2 mb-3">
                                                <Col sm={3}>
                                                    <Form.Group controlId="from" className="mb-2">
                                                        {/* <FloatingLabel controlId="floatingSelectGrid" label="To City">
                                                            <Form.Select aria-label="Floating label select example" name="ToCity" onChange={e => Cityupdate(e)} value={cityDistance?.ToCity || ""} placeholder="To City" required> */}
                                                                <Typeahead 
                                                                    id='cityAutocomplete'
                                                                    labelKey="name"
                                                                    onChange={setToSelection}
                                                                    options={toCityList}
                                                                    placeholder="Choose to city..."
                                                                    selected={toSelection}
                                                                />
                                                            {/* </Form.Select>
                                                        </FloatingLabel> */}
                                                    </Form.Group>

                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Province/State" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="To Province/State">
                                                            <Form.Select aria-label="Floating label select example" name="ToProvince" placeholder="To Province/Statey" onChange={(e) => { setSelectState(e.target.value); Cityupdate(e); setCountry(e) }} value={cityDistance?.ToProvince || ""} required>
                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        toProvince?.data?.map((data, index) => (
                                                                            <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                                                        ))

                                                                    }
                                                                </>

                                                            </Form.Select>

                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="country" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="To Country">
                                                            <Form.Select aria-label="Floating label select example" onChange={e => { handletocountry(e); Cityupdate(e) }} placeholder="To Country" name="ToCountry" value={cityDistance?.ToCountry || ""} required>
                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        toCountryList?.data?.map((data, index) => (
                                                                            <option key={index} value={data.countryId}>{data.countryname}</option>
                                                                        ))

                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="In Minutes">
                                                        <FloatingLabel controlId="floatingInput" label="In Minutes" >
                                                            <Form.Control type="text" placeholder="In Minutes" name="TimeInMinutes" onChange={e => Cityupdate(e)} value={cityDistance?.TimeInMinutes || ""} required min='0'/>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                        : null}
                                    {/* table */}
                                    <Row>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>From Country</th>
                                                    <th>To Country</th>
                                                    <th>From Province</th>
                                                    <th>To Province</th>
                                                    <th>From City</th>
                                                    <th>To City</th>
                                                    <th>Miles</th>
                                                    <th>In Minutes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cityDistanceLists?.length > 0 ? (
                                                    cityDistanceLists?.map((data) => {
                                                        return (
                                                            <tr key={data.citydistanceID}>
                                                                <td>
                                                                    <div className="action_btn">
                                                                    {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ? 
                                                                        <span className="edit_btn" onClick={() => EditcityItem(data?.CityDistanceId)}><Edit /></span>
                                                                        :  <span className="edit_btn disable"><Edit /></span> }
                                                                        {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Delete') !== -1 ? 
                                                                        <span className="del_btn" onClick={() => handleDistanceOpen(data?.CityDistanceId)}><Delete /></span>
                                                                        :           <span className="del_btn disable" ><Delete /></span> }
                                                                        <div>
                                                                            <Dialog open={distanceOpen} onClose={handleDistanceClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                                <DialogTitle id="alert-dialog-title">
                                                                                    Delete Confirmation
                                                                                </DialogTitle>
                                                                                <DialogContent>
                                                                                    <DialogContentText id="alert-dialog-description">
                                                                                        Are you sure you want to delete this row?
                                                                                    </DialogContentText>
                                                                                </DialogContent>
                                                                                <DialogActions>
                                                                                    <Button className="btn-secondary" onClick={handleDistanceClose}>Cancel</Button>
                                                                                    <Button className="btn-primary" onClick={() => { deleteCityCustomerList();  }} autoFocus> Confirm</Button>
                                                                                </DialogActions>
                                                                            </Dialog>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{data.FromCountry === 1 ? "CAN" : "USA"}</td>
                                                                <td>{data.ToCountry === 1 ? "CAN" : "USA"}</td>
                                                                <td>{getProvinceName(data?.FromProvince) || ""}</td>
                                                                <td>{getProvinceName(data?.ToProvince) || ""}</td>
                                                                <td>{getCityName(data.FromCity) || ""}</td>
                                                                <td>{getCityName(data?.ToCity) || ""}</td>
                                                                <td>{data.Miles}</td>
                                                                <td>{data.TimeInMinutes}</td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : (
                                                    <p>No data found </p>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </fieldset>
                            </Col>
                        </Row>



                    </fieldset>
                </Col>
            </Row>


        </>
    );
}
export default EditCityDistance;