import React from "react";
import { Form, Row, Col, FloatingLabel, Button } from "react-bootstrap";
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import useOrder from "../../../context/OrderContext";
import { ArrowRightAlt } from "@material-ui/icons";
import CachedIcon from '@mui/icons-material/Cached';
import DropLocationNotes from "./DroplocationNotes";
import CountryProvince from "../../CountryProvince";
import { useGetConsignorQuery } from "../../../features/pickupInfo/pickupInfoSlice";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import phoneNumberMasking from "../../Customer/PhoneNumberMasking";
import postalCodeMasking from "../../Customer/PostalCodeMasking";
import { useState, useEffect, useRef } from "react";
import LocationSearch from "./LocationSearch";
import { Typeahead } from 'react-bootstrap-typeahead';
import LocationAutoComplete from "./LocationAutocomplete";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import timeConverter from "./timeConverter";


const Dropupinfo = () => {
  const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
  const { dropInfo, setDropInfo, fetchDropupInfo, Dropuphandler, populateAccount, dropDisable, reloadHandler, dropupData, dropErrors,
    pList, cList, country, setSelectState, handlecountry, handleCity, setSelectCity, setAutoDropup, dropAccessorial, setDropAccessorial, accessorial, dropSelection, setDropSelection } = useOrder();
  const { city, handleProvince, newHandleCountry, handleUniqueCity } = CountryProvince();
  // const { country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity } = CountryProvince()
  const { data: pickupInfo } = useGetConsignorQuery()
  const listofaccount = pickupInfo?.data?.map((item, index) => ({ ...item, id: index + new Date().getTime().toString() }))
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [postal, setPostal] = useState("")
  const [autocomplete, setAutocomplete] = useState({});
  const [province, setProvince] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const cityList = city?.data.map((a) => { return {name: a.city, id: a.id}});
  // const [selection, setSelection] = useState([]); this is old, added dropSelection to the context

  const getAccountInfo1 = async (dropInfo) => {
    fetchDropupInfo(dropInfo)
  }
  const handleOnSelect1 = async (item) => {
    const dropInfo = item?.consignorId;
    const selectedAccount = await getAccountInfo1(dropInfo)
    setDropInfo(selectedAccount)
    setAutoDropup(true)
  };

  const handleSelect = (e) => {
    const mapData = e?.map((item) => item?.value
    )
    if (mapData && mapData.length > 0) {
      setDropAccessorial(mapData)
    }
    else {
      setDropAccessorial([])
    }
  }
  const arr = accessorial?.data?.data?.map((item) => ({ value: item?.accessorialID, label: item?.accessorialDescription })) || [];


  const getCityID = async (cityName, provinceName) => {
    const result = await handleUniqueCity({cityName, provinceName});
    return result?.data?.data;
  }

  useEffect( async () => {
    if (dropSelection.length > 0) {
      setDropInfo({...dropInfo, city: dropSelection[0].id, cityName: dropSelection[0].name})
      const provList = await handleProvince(dropSelection[0].id)
      setProvince(provList.data.data);
      if (provList.data.data.length === 1) {
        const cList = await newHandleCountry(provList.data.data[0].provineStateID);
        setCountryList(cList.data.data)
        setDropInfo(dropInfo => ({...dropInfo, provineStateID: provList.data.data[0].provineStateID, 
          provineSateName: provList.data.data[0].provineSateName, countryId: cList.data.data[0].countryId, countryname: cList.data.data[0].countryname}))
      }
    }
    else {
      setDropInfo({...dropInfo, city: null, cityName: null, provineStateID: null, provineSateName: null, countryId: null, countryname: null})
      setProvince([]);
      setCountryList([]);
    }
  }, [dropSelection])

  

  const setCountry = async (e) => {
    if (e.target.value !== 'Select') {
      const cList = await newHandleCountry(e.target.value);
      setCountryList(cList.data.data)
      setDropInfo(dropInfo => ({...dropInfo, countryId: cList.data.data[0].countryId, countryname: cList.data.data[0].countryname}))
    } 
    else {
      setCountryList([]);
    }
  }

  useEffect(async () => {
    if (Object.keys(autocomplete).length !== 0) {
      const id = await getCityID(autocomplete.city, autocomplete.province)
      setDropSelection([{name: autocomplete.city, id: id}]);
      const lst = await handleProvince(id)
      const prov = lst.data.data.find((ele) => ele.provineSateName === autocomplete.province)
      setProvince([prov]);
      const country = autocomplete.country === "CAN"? {countryId: 1, countryname: 'CAN'}: {countryId: 2, countryname: 'USA'}
      const countryID = autocomplete.country === "CAN"? 1: 2;
      setDropInfo({...dropInfo, streetName: autocomplete.streetName, street: autocomplete.streetNum, unit: autocomplete.unit, 
        provineStateID: prov.provineStateID, provineSateName: autocomplete.province, city: id, cityName: autocomplete.city, 
        countryId: countryID, countryname: autocomplete.country, postalCode: autocomplete.postalCode});
      setCountryList([country]);
      setPostal(autocomplete.postalCode)
    }
    else {
      setDropInfo({...dropInfo, streetName: "", street: "", unit: ""});
      setPostal("");
    }
  }, [autocomplete])

  const handleOnClear1 = () => {
    setDropInfo("")
    setPostal("");
    setDropSelection([]);
    setCountryList([]);
    setDropInfo(prevState => ({ ...prevState, appointment: "" }));
    setDropAccessorial([]);
  }

  const formatResult1 = (item) => {
    return (
      <div className="result-wrapper">
        <span className="result-span">{item.address} </span>
      </div>
    );
  };

  useEffect(()=>{
    if(dropupData?.provineStateID){
     let target = dropupData?.provineStateID
     handleCity({target:{value:target}})
   }
   if (dropupData?.cityId && dropupData?.cityName) {
     setDropSelection([{name: dropupData?.cityName, id: dropupData?.cityId}]);
   }
   if(dropupData?.countryId){
     let target;
     if (dropupData?.countryId === 1) {
       target = {countryId: 1, countryname: 'CAN'};
     } else {
       target = {countryId: 2, countryname: 'USA'};
     }
     setCountryList([target]);
   }
 },[dropupData])

  useEffect(() => {
    setPhoneNumber(dropupData?.phone)
    setContactNumber(dropupData?.contact)
    setPostal(dropupData?.postalCode)
  }, [dropupData])

  const handleInput = e => {
    const formattedPhoneNumber = phoneNumberMasking(e.target.value);
    const formattedPostalCode = postalCodeMasking(e.target.value)
    if (e.target.name === "phone") {
      setPhoneNumber(formattedPhoneNumber);
    }
    // else if (e.target.name === "contact") {
    //   setContactNumber(formattedPhoneNumber)
    // }
    else if (e.target.name === "postalCode") {
      setPostal(formattedPostalCode)
    }
  };
  const [splicedDefault , setSplicedDefault] = useState([]);

  // useEffect(() => {
  //   if (dropInfo?.cityName) {
  //     const id = dropInfo?.cityName;
  //     let lo = 0, hi = cityList.length - 1;
  //     let mid = Math.floor((lo + hi) / 2);
  //     while (lo < hi) {
  //       if (cityList[mid].name === id) {
  //         setSplicedDefault(cityList.splice(mid, 1));
  //         break;
  //       }
  //       else if (cityList[mid].name < id) {
  //         lo = mid + 1;
  //       }
  //       else {
  //         hi = mid - 1;
  //       }
  //     }
  //     console.log(splicedDefault);
  //   }
  // }, [dropInfo])

  const setValues = () => {
    setDropSelection([{name: dropInfoRef.current.cityName, id: dropInfoRef.current.city}]);
  }

  const dropInfoRef = useRef();
  dropInfoRef.current = dropInfo;
  // console.log(dropInfoRef);

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          {/* <DropupInfo/> */}
          <ReactSearchAutocomplete
            className="form-control11"
            placeholder={dropInfo?.consignorId ? dropInfo?.consignorId : "Select"}
            items={listofaccount}
            fuseOptions={{ keys: ["address"] }} // Search on both fields
            resultStringKeyName="address" // String to display in the results
            onSelect={handleOnSelect1}
            onFocus={formatResult1}
            onClear={handleOnClear1}
            onInputChange={handleOnClear1}
            onSearch={handleOnClear1}
            formatResult={formatResult1}
            showItemsOnFocus={formatResult1}
            inputDebounce={100}
            showIcon={false}
            // value={pickInfo}
            styling={{
              height: "48px",
              border: "1px solid #cbcbcb",
              borderRadius: "4px",
              backgroundColor: "white",
              boxShadow: "none",
              hoverBackgroundColor: "#cbcbcb",
              iconColor: "#000",
              lineColor: "#cbcbcb",
              placeholderColor: "#000",
              clearIconMargin: "3px 8px 0 0",
              zIndex: 2,
            }}
          />
        </Col>
        <Col sm={6}>
          <div className="search-form">
            <LocationAutoComplete autocomplete={autocomplete} setAutocomplete={setAutocomplete}/> 
            {/* <span><i className="fa fa-search" aria-hidden="true"></i></span> */}
          </div>
        </Col>
      </Row>
      <fieldset className="mt-4 mb-3">
        <label className="label-heading">Address Info</label>
        <Row className="fieldset-row mb-3">
          <Col sm={3}>
            <Form.Group controlId="st">
              <FloatingLabel controlId="floatingInput" label="Consignee/Receiver" >
                <Form.Control type="text" placeholder="Consignee/Receiver" name="customer" value={dropInfo?.customer || ""} onChange={e => { Dropuphandler(e); }} className={`input ${dropErrors.customer && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {dropErrors.customer && (<p className="help is-danger">{dropErrors.customer}</p>)}
          </Col>
          <Col sm={3}>
            <Form.Group controlId="st">
              <FloatingLabel controlId="floatingInput" label="Street No." >
                <Form.Control type="text" placeholder="Street No." name="street" value={dropInfo?.street ? dropInfo?.street : ""} onChange={e => { Dropuphandler(e); }} className={`input ${dropErrors.street && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {dropErrors.street && (<p className="help is-danger">{dropErrors.street}</p>)}
          </Col>
          <Col sm={3}>
            <Form.Group controlId="Street">
              <FloatingLabel controlId="floatingInput" label="Street Name" >
                <Form.Control type="text" placeholder="Street Name" name="streetName" value={dropInfo?.streetName ? dropInfo?.streetName : ""} onChange={e => { Dropuphandler(e); }} className={`input ${dropErrors.streetName && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {dropErrors.streetName && (<p className="help is-danger">{dropErrors.streetName}</p>)}
          </Col>
          <Col sm={3}>
            <Form.Group controlId="Unit">
              <FloatingLabel controlId="floatingInput" label="Unit" >
                <Form.Control type="text" autoComplete="off" placeholder="Unit" name="unit" onChange={(e) => { Dropuphandler(e); }} value={(dropInfo?.unit && dropInfo?.unit !== "null") ? dropInfo?.unit : ""} className={`input ${dropErrors.unit && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {/* {dropErrors.unit && (<p className="help is-danger">{dropErrors.unit}</p>)} */}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={3}>
            <Form.Group controlId="city">
              {/* <FloatingLabel controlId="floatingInput" label="City" >
                <Form.Select aria-label="Floating label select example" name="city" onChange={e => { Dropuphandler(e); setSelectCity(e.target.value) }} value={dropInfo?.city || "Select"} className={`input ${dropErrors.city && 'is-danger'}`}> */}
                <Typeahead
                  id='cityAutocomplete'
                  labelKey="name"
                  onChange={setDropSelection}
                  options={cityList}
                  placeholder="Choose a city..."
                  defaultSelected={splicedDefault}
                  selected={
                    dropInfo?.city
                      ? cityList.filter(city => city.id === dropInfo.city)
                      : dropSelection
                  }
                  />
                {/* </Form.Select>
              </FloatingLabel> */}
            </Form.Group>
            {dropErrors.city && (<p className="help is-danger">{dropErrors.city}</p>)}
          </Col>
          <Col sm={3}>
            <Form.Group controlId="pr">
              <FloatingLabel controlId="floatingInput" label="Province" >
                <Form.Select aria-label="Floating label select example" name="provineStateID" onChange={(e) => { setSelectState(e.target.value); Dropuphandler(e); handleCity(e); setCountry(e); }}
                  value={dropInfo?.provineStateID ? dropInfo?.provineStateID : "Select"} className={`input ${dropErrors.provineStateID && 'is-danger'}`}>
                  <option>Select</option>:
                  {
                    province?.map((data, index) => (
                      <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                    ))

                  }
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            {dropErrors.provineStateID && (<p className="help is-danger">{dropErrors.provineStateID}</p>)}
          </Col>
          <Col sm={3}>
            <Form.Group controlId="Country">
              <FloatingLabel controlId="floatingSelectGrid" label="Country">
                <Form.Select aria-label="Floating label select example" name="countryId" onChange={e => { handlecountry(e); Dropuphandler(e) }} value={dropInfo?.countryId ? dropInfo?.countryname : "Select"} className={`input ${dropErrors.countryId && 'is-danger'}`}>
                  { countryList.length === 0 &&
                  <option>Select</option>
                  }
                  <>
                    {
                      countryList?.map((data, index) => (
                        <option key={index} value={data?.countryId}>{data?.countryname}</option>
                      ))

                    }
                  </>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            {dropErrors.countryId && (<p className="help is-danger">{dropErrors.countryId}</p>)}
          </Col>
          <Col sm={3}>
            <Form.Group controlId="postal/zip">
              <FloatingLabel controlId="floatingInput" label="Postal Code" >
                <Form.Control type="text" autoComplete="off" placeholder="Postal code" name="postalCode" onChange={(e) => { Dropuphandler(e); handleInput(e) }} value={dropInfo?.postalCode || ""} className={`input ${dropErrors.postalCode && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {dropErrors.postalCode && (<p className="help is-danger">{dropErrors.postalCode}</p>)}
          </Col>
        </Row>
      </fieldset>

      <fieldset className="mt-4">
        <label className="label-heading">Personal Info</label>
        <Row className="mb-1 fieldset-row">
          <Col sm={4}>
            <Form.Group controlId="Contact">
              <FloatingLabel controlId="floatingInput" label="Contact Name" >
                <Form.Control type="text" autoComplete="off" placeholder="Contact" name="contact" value={dropInfo?.contact || ""} onChange={e => { Dropuphandler(e); }} className={`input ${dropErrors.contact && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {dropErrors.contact && (<p className="help is-danger">{dropErrors.contact}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="phone">
              <FloatingLabel controlId="floatingInput" label="Phone" >
                <Form.Control type="text" autoComplete="off" placeholder="Phone" name="phone" value={dropInfo?.phone || ""} onChange={e => { Dropuphandler(e); handleInput(e) }} className={`input ${dropErrors.phone && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {dropErrors.phone && (<p className="help is-danger">{dropErrors.phone}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="email">
              <FloatingLabel controlId="floatingInput" label="Email" >
                <Form.Control type="text" autoComplete="off" placeholder="Email" name="emailAddress" onChange={(e) => { Dropuphandler(e); }} value={dropInfo?.emailAddress ? dropInfo?.emailAddress : ""} className={`input ${dropErrors.emailAddress && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {dropErrors.emailAddress && (<p className="help is-danger">{dropErrors.emailAddress}</p>)}
          </Col>
        </Row>
      </fieldset>
      <fieldset className="mt-4">
        <label className="label-heading">Extra Info</label>
        <Row className="fieldset-row mb-4">
          <Col sm={4}>
            <Form.Group controlId="Dropupref">
              <FloatingLabel controlId="floatingInput" label="Dropup(Ref/info)" >
                <Form.Control type="text" placeholder="Dropup(Ref/info)" name="dropoffRefence" value={dropInfo?.dropoffRefence ? dropInfo?.dropoffRefence : ""} onChange={e => { Dropuphandler(e) }} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col sm={8}>
                <Controller
                  control={control}
                  name="accessorialID"
                  // defaultValue={}
                  //value={orderInfo.accessorialID || ""}
                  //onChange={(e) => { OrderHandler(e); }}
                  // {...register('VehicleTypes')}

                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: "47px",
                      }),
                    }}
                      isMulti
                      placeholder="Accessorial"
                      name={name}
                      // defaultValue={selectedData}
                      ref={ref}
                      options={arr}
                      // value={arr.find((c) => c.value === value)}
                      //value={dropAccessorial ? dropAccessorial ?.find((e) => e === value) : ""}
                      value={arr.filter(option => dropAccessorial.includes(option.value))}
                      onChange={(e) => handleSelect(e)}
                    />
                  )}
                />
              </Col>
              </Row>
              <Row>
          <Col sm={4}>
            <Form.Group controlId="Appointment">
              <FloatingLabel controlId="floatingInput" label="Appointment" >
                <Form.Control type="datetime-local" placeholder="Appointment" name="appointment" value={dropInfo?.appointment} onChange={e => { Dropuphandler(e) }} />
              </FloatingLabel>
            </Form.Group>
            {dropErrors.appointment && (<p className="help is-danger">{dropErrors.appointment}</p>)}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="Opening Time">
              <FloatingLabel controlId="floatingInput" label="Opening Time" >
                <Form.Control type="time" placeholder="Opening Time" onChange={e => Dropuphandler(e)} name="openingTime" value={timeConverter(dropInfo?.openingTime) || ""} className={`input ${dropErrors.openingTime && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {/* {dropErrors.openingTime && (<p className="help is-danger">{dropErrors.openingTime}</p>)} */}
          </Col>
          <Col sm={4}>
            <Form.Group controlId="Closing Time">
              <FloatingLabel controlId="floatingInput" label="Closing Time" >
                <Form.Control type="time" placeholder="Closing Time" onChange={e => Dropuphandler(e)} name="closeTime" value={timeConverter(dropInfo?.closeTime) || ""} className={`input ${dropErrors.closeTime && 'is-danger'}`} />
              </FloatingLabel>
            </Form.Group>
            {/* {dropErrors.closeTime && (<p className="help is-danger">{dropErrors.closeTime}</p>)} */}
          </Col>
        </Row>
      </fieldset>
      <Row className="mt-3">
        {/* Why do these exist? */}
        <Col sm={6}>
          <div className="populate-btn d-flex">
            <Button className="populate-account" onClick={async(e) => {  await populateAccount(e); setValues() }} disabled={dropDisable} value="populatedrop">Populate account <ArrowRightAlt /></Button>
            {/* <Button className="populate-account btn-secondary" value="dropdisable" onClick={e => { reloadHandler(e) }}>Restore Data <CachedIcon /></Button> */}
            <Button className="notes"><DropLocationNotes /></Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default Dropupinfo;