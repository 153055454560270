import { Col, Row, Form, FloatingLabel, Table, InputGroup, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Delete, Edit, Save, AddCircle } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../../../context/CustomerContext";
import { useGetServiceQuery, useGetAllServiceQuery } from "../../../../../features/service/serviceDetailSlice";
import { useUpdateServiceDataMutation, useDeleteServiceDataMutation, editService, useAddServiceSurchargeAfterEditMutation, addService } from "../../../../../features/service/serviceDetailSlice";
import { useModifyHistoryMutation } from "../../../../../features/customer/history/historySlice";
import { useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useCustomData } from "../../../../Sidebar/Permission";
import Select from 'react-select';
import useDeleteData from "../../../../Customer/useDeleteData";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};


const EditServiceSurcharge = (props) => {
    const [permission] = useCustomData()
    const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
    let subscribe = watch();
    const { data: serviceList } = useGetAllServiceQuery()
    const [updateService] = useUpdateServiceDataMutation()
    const [deleteService] = useDeleteServiceDataMutation()
    const [AddServiceSurchargeAfterEdit] = useAddServiceSurchargeAfterEditMutation()
    const [modifyHistory] = useModifyHistoryMutation()
    const [isDelete, setIsDelete] = useState()
    const { handleAccount, singleAccount, defaultCardData, vehicleLists, serviceLists,getDefaultCard  } = useCustomer();
    const account = singleAccount && singleAccount[0];
    const [message, setMessage] = useState("Modified the service surcharge section")
    const [serviceData, setServiceData] = useState({});
    const [serviceOpen, setServiceOpen] = useState(false);
    const [showServiceSecondFields, setServiceShowSecondFields] = useState(false);
    const [editId, setEditId] = useState('')
    const [selectedData, setSelectedData] = useState([])
    const [isEditItem, setIsEditItem] = useState(null);
    const handleServiceOpen = (data) => { setServiceOpen(true); setIsDelete(data)  };
    const handleServiceClose = () => { setServiceOpen(false); };
    const prevCount = usePrevious(serviceData);
    // const serviceSurchargeList = defaultCardData?.mlserviceSurchargeList;    
    const dispatch = useDispatch();
    const [selectedEditData, setSelectedEditData] = useState([])

    const [isUpdate, setIsUpdate] = useState(false);
    const [serviceRateValue, setServiceRateValue] = useState(0);
    const [hasPallet, setHasPallet] = useState(false);
    const [hasCWT, setHasCWT] = useState(false);
    const rateTypeMap = { 1: 'LOCAL', 2: 'CWT', 3: 'PALLET', 4: "DISTANCE-BASED" }
    const rateTypeMapReverse = { 'LOCAL': 1, 'CWT': 2, 'PALLET': 3, "DISTANCE-BASED": 4 }

    const [vehicles, setVehicles] = useState([]);

    const [handleClose, handleOpen, open, deleteData] = useDeleteData(deleteService)

    useEffect(() => {
        setServiceData(serviceData)
        reset(serviceData)
    }, [serviceData])

    useEffect(() => {
        const vals = vehicleLists.filter(
          item =>{
            const rateType = item.VehicleType.split(" ").pop();
            return rateType.slice(1,-1) === rateTypeMap[serviceRateValue];
          }).map(item => {return {value: item.VehicleType, label: item.VehicleType}})
        setVehicles(vals);
      }, [serviceRateValue])
    
    const handleSelect = (e) => {
        // if (isUpdate) {
        //     const mapData = e?.map((item) => item?.value
        //     )
        //     if (mapData && mapData?.length > 0) {
        //         setSelectedData(mapData)
        //     }
        // }
        const mapData = e?.map((item) => item?.value
        )
        if (mapData && mapData?.length > 0) {
            setSelectedData(mapData)
        }
    }

    const Serviceupdate = (e) => {
        setServiceData(serviceData => ({ ...serviceData, [e.target.name]: e.target.value }));
    }

    const EditserviceItem = (rateCardServiceID) => {
        setIsUpdate(true);
        setEditId(rateCardServiceID)
        const obj = [];
        let newEditItem = serviceLists.find((data) => {
            return data.RateCardServiceID === rateCardServiceID
        });
        newEditItem.VehicleTypes.forEach((elem, i) => {
            obj.push({ value: elem, label: elem })
        })
        setServiceRateValue(rateTypeMapReverse[newEditItem.RateType]);
        setSelectedData(obj)
        setSelectedEditData(newEditItem?.VehicleTypes)
        setServiceData(newEditItem);
        setServiceShowSecondFields(showServiceSecondFields => !showServiceSecondFields)
        setIsEditItem(rateCardServiceID);
        reset(serviceData)
    }

    const editServiceCustomerList = async (serviceData) => {
        serviceData.vehicleTypes = selectedData
        if (JSON?.stringify(prevCount) === JSON?.stringify(serviceData)) {
            setTimeout(function () {
                toast.info("No Changes found on this service surcharge section !", {
                    position: "top-right",
                })
            });
        }
        else {
            delete serviceData.vehicleTypes
            serviceData.VehicleTypes = selectedEditData
            const updateServiceData = await updateService({ selectedEditData, ...serviceData, rateCardServiceID: serviceData.RateCardServiceID })
            setServiceData("");
            // handleAccount(account.account_No)
            if (updateServiceData.data.statusCode === 200) {
                setTimeout(function () {
                    toast.success("Service surcharge section updated successfully !", {
                        position: "top-right",
                    })
                });
                const service = serviceList.data.find(service => service.serviceId == serviceData.ServiceID)
                dispatch(editService({ ...serviceData, ServiceDescription: service.description }));
                setSelectedData('')
                setIsUpdate(false);
            }
        }
        reset({
            ServiceID: "", AfterHourPercent: "", CostPerKM: "", BaseRateType: '', BaseRate: '', PieceInlcuded: "", BaseRateType: "", CrossoverRateType: "", CrossoverRate: "", PieceRate: "", WeightRate: "",
            WeightIncluded: "", WeightRange: "", WeightCalcType: "", waitingTimeUnitRate: "", waitingtimeUnit: "", freeWaitingTime: "", PieceRateType: "", WeightRateType: "", waitingTimeUnitRateType: ""
        });
        setServiceRateValue(0);
    }

    // Add New Record
    const addNewService = async (data) => {
        if (Object.keys(data).length === 0) {
            setServiceShowSecondFields(true)
        }
        else {
            if (serviceRateValue === 0) {
                toast.error("Please select a rate type", {
                    position: "top-center",
                })
                return;
            }
            delete data.vehicleTypes;
            const newData = { VehicleTypes: selectedData, ...data, rateCardID: props.selectedCard, RateType: rateTypeMap[serviceRateValue] }
            const addDataOnUpdate = await AddServiceSurchargeAfterEdit(newData)
            // handleAccount(account.account_No)
            if (addDataOnUpdate?.data?.statusCode === 200) {
                setTimeout(function () {
                    toast.success("Service surcharge section Added successfully !", {
                        position: "top-right",
                    })
                });
                const service = serviceList.data.find(service => service.serviceId == data.ServiceID)
                dispatch(addService({...newData, ServiceDescription: service.description}));
                getDefaultCard(props.selectedCard)
                reset({
                    ServiceID: "", AfterHourPercent: "", CostPerKM: "", BaseRateType: '', vehicleTypes: "", BaseRate: '', PieceInlcuded: "", BaseRateType: "", CrossoverRateType: "", CrossoverRate: "", PieceRate: "", WeightRate: "",
                    WeightIncluded: "", WeightCalcType: "", WeightRange: "", waitingTimeUnitRate: "", waitingtimeUnit: "", freeWaitingTime: "", PieceRateType: "", WeightRateType: "", waitingTimeUnitRateType: ""
                });
                setServiceData("");
                setSelectedData('')
                setServiceRateValue(0);
            }
        }
    }

    const deleteServiceCustomerList = async () => {
        const deleteServiceRecord = await deleteService(isDelete)
        if (deleteServiceRecord.data.statusCode === 200) {
            setTimeout(function () {
                toast.success("Service surcharge record deleted successfully !", {
                    position: "top-right",
                })
            });
            getDefaultCard(props.selectedCard)
        }
        handleServiceClose();

    }


    const [statusS, setStatusS] = useState(0);
    const [statusSc, setStatusSc] = useState(0);
    const [statusSp, setStatusSp] = useState(0);
    const [statusSw, setStatusSw] = useState(0);
    const radioHandlerS = (statusS) => { setStatusS(statusS) };
    const radioHandlerSc = (statusSc) => { setStatusSc(statusSc) };
    const radioHandlerSp = (statusSp) => { setStatusSp(statusSp) };
    const radioHandlerSw = (statusSw) => { setStatusSw(statusSw) };

     // Delete service
     const deleteDService =  () => {
        deleteData(); 
        getDefaultCard(props.selectedCard); 
        handleClose();
      }


    return (
        <>
            {/* Radio Options */}
            <Form>
                <Col sm={12} className="mb-3">
                    <Row>
                        <Col sm={4}><h6>Select service rate type</h6></Col>
                        <Col sm={8}>
                            <div className="floating-checkbox" name="RateType">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineCheckbox1" value="LOCAL" name="RateType" checked={serviceRateValue === 1} onClick={() => setServiceRateValue(1)} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">LOCAL</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineCheckbox2" disabled={hasPallet} value="CWT" name="RateType" checked={serviceRateValue === 2} onClick={() => setServiceRateValue(2)} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox2">CWT</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineCheckbox3" disabled={hasCWT} value="PALLET" name="RateType" checked={serviceRateValue === 3} onClick={() => setServiceRateValue(3)} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox3">PALLET</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="inlineCheckbox4" value="DISTANCE-BASED" name="RateType" checked={serviceRateValue === 4} onClick={() => setServiceRateValue(4)} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox4">DISTANCE-BASED</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <ServiceSurchargeOptions serviceRateValue={serviceRateValue} setServiceRateValue={setServiceRateValue} setHasCWT={setHasCWT} setHasPallet={setHasPallet}/> */}
                </Col>
            </Form>
            {/* Service surcharge section*/}
            <Row className="mb-2 mt-2">
                <Col sm={12}>
                    <fieldset className="pb-2">
                        {isUpdate ?
                            <div className="section_action_btn">
                                {permission.find(event => event.moduleId === 42)?.permissionType?.indexOf('Update') !== -1 ?
                                    <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(editServiceCustomerList)}><Save /></button></div>
                                    : <div className="save_btn disable"><button type="button" className="btn"><Save /></button></div>}
                            </div> :
                            <div className="section_action_btn">
                                <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(addNewService)}><AddCircle /></button></div>
                            </div>}
                        {showServiceSecondFields ?
                            <>
                                <Row className="mt-2 mb-3">
                                    <Col sm={3}>
                                        <Form.Group controlId="Serviceid">
                                            <FloatingLabel controlId="floatingSelectGrid" label="Service">
                                                <Form.Select aria-label="Floating label select example" name="ServiceID" {...register('ServiceID', { required: 'Field is required' })}>
                                                    <option value="">Select</option>
                                                    <>

                                                        {
                                                            serviceList?.data?.map((data, index) => (
                                                                rateTypeMapReverse[data.rateType] === serviceRateValue && <option key={index} value={data.serviceId}>{data.description}</option>
                                                            ))

                                                        }
                                                    </>

                                                </Form.Select>
                                            </FloatingLabel>
                                            <p className="error-message">{errors.ServiceID?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Controller
                                            control={control}
                                            name="vehicleTypes"
                                            defaultValue={selectedData}
                                            {...register('vehicleTypes')}
                                            render={({ field: { onChange, value, name, ref } }) => (
                                                <Select
                                                    isMulti
                                                    placeholder="Select Vehicle"
                                                    name={name}
                                                    defaultValue={selectedData}
                                                    ref={ref}
                                                    options={vehicles}
                                                    // value = {serviceData.vehicleTypes?.map((d)=>d.value)?.join(' , ')}
                                                    value={selectedData ? selectedData?.find((e) => e.value === value) : ""}
                                                    onChange={(e) => handleSelect(e)}
                                                />
                                            )}
                                        />
                                        {errors.vehicleTypes?.message && (<p className="error-message">{" * " + errors.vehicleTypes?.message}</p>)}
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId="Afterhour">
                                            <FloatingLabel controlId="floatingInput" label="After Hour Percent (%)" >
                                                <Form.Control type="number" placeholder="After Hour Percent" name="AfterHourPercent" {...register('AfterHourPercent', { required: 'Field is required' })} min='0' />
                                            </FloatingLabel>
                                            <p className="error-message">{errors.AfterHourPercent?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    {serviceRateValue === 4 &&
                                        <Col sm={3}>
                                            <div className="form-floating">
                                                <Form.Control type="number" placeholder="Cost Per KM" name="CostPerKM" {...register('CostPerKM', { required: 'Field is required' })} min='0' />
                                                <label>Cost Per KM</label>
                                            </div>
                                            <p className="error-message">{errors.CostPerKM?.message}</p>
                                        </Col>}
                                </Row>
                                {serviceRateValue === 1 &&
                                    <Row className="mt-4 mb-3">
                                        <Col sm={6}>
                                            <fieldset className="mb-3">
                                                <label className="label-heading">Base Rate Section</label>
                                                <Row>
                                                    <Col sm={6}>
                                                        <div className="fieldset-inner">
                                                            <label className="label-heading">Base Rate Type</label>
                                                            <div className="floating-checbox">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="BaseRateType" {...register("BaseRateType", { required: 'Field is required' })} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="BaseRateType" {...register("BaseRateType", { required: 'Field is required' })} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {errors.BaseRateType?.message && (<p className="error-message">{" * " + errors.BaseRateType?.message}</p>)}
                                                    </Col>
                                                    <Col sm={6}>
                                                        {subscribe.BaseRateType === "A" ? (
                                                            <div className="btn-status">
                                                                <div className="form-floating">
                                                                    <InputGroup.Text >$</InputGroup.Text>
                                                                    <Form.Control type="number" placeholder="Base Rate" name="BaseRate"  {...register('BaseRate', { required: 'Field is required' })} min='0' />
                                                                    <label>Base Rate ($)</label>
                                                                </div>
                                                                {errors.BaseRate?.message && (<p className="error-message">{" * " + errors.BaseRate?.message}</p>)}
                                                            </div>
                                                        ) : subscribe.BaseRateType === "P" ? (
                                                            <div className="btn-status">
                                                                <div className="form-floating">
                                                                    <InputGroup.Text >%</InputGroup.Text>
                                                                    <Form.Control type="number" placeholder="Base Rate" name="BaseRate"  {...register('BaseRate', { required: 'Field is required' })} min='0' />
                                                                    <label>Base Rate (%)</label>
                                                                </div>
                                                                {errors.BaseRate?.message && (<p className="error-message">{" * " + errors.BaseRate?.message}</p>)}
                                                            </div>
                                                        ) : (
                                                            <div className="btn-status">
                                                                <div className="form-floating">
                                                                    <InputGroup.Text >$</InputGroup.Text>
                                                                    <Form.Control type="number" placeholder="Base Rate" name="BaseRate" {...register('BaseRate', { required: 'Field is required' })} min='0' />
                                                                    <label>Base Rate ($)</label>
                                                                </div>
                                                                {errors.BaseRate?.message && (<p className="error-message">{" * " + errors.BaseRate?.message}</p>)}
                                                            </div>
                                                        )
                                                        }

                                                    </Col>
                                                </Row>
                                            </fieldset>
                                        </Col>
                                        <Col sm={6}>
                                            <fieldset className="mb-3">
                                                <label className="label-heading">Crossover Rate Section</label>
                                                <Row>
                                                    <Col sm={6}>
                                                        <div className="fieldset-inner">
                                                            <label className="label-heading">Choose the crossover rate type</label>
                                                            <div className="floating-checbox">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="CrossoverRateType" {...register("CrossoverRateType", { required: 'Field is required' })} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="CrossoverRateType" {...register("CrossoverRateType", { required: 'Field is required' })} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6}>
                                                        {subscribe.CrossoverRateType === "A" &&
                                                            <div className="btn-status">
                                                                <div className="form-floating">
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                    <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRate" {...register('CrossoverRate', { required: 'Field is required' })} min='0' />
                                                                    <label>Crossover Rate ($)</label>
                                                                </div>
                                                                <p className="error-message">{errors.CrossoverRate?.message}</p>
                                                            </div>
                                                        }
                                                        {subscribe.CrossoverRateType === "P" &&
                                                            <div className="btn-status">
                                                                <div className="form-floating">
                                                                    <InputGroup.Text >%</InputGroup.Text>
                                                                    <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRate" {...register('CrossoverRate', { required: 'Field is required' })} min='0' />
                                                                    <label>Crossover Rate (%)</label>
                                                                </div>
                                                                <p className="error-message">{errors.CrossoverRate?.message}</p>
                                                            </div>

                                                        }
                                                        {subscribe.CrossoverRateType === "T" &&
                                                            <div className="btn-status">
                                                                <div className="form-floating">
                                                                    <InputGroup.Text >$</InputGroup.Text>
                                                                    <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRate" {...register('CrossoverRate', { required: 'Field is required' })} min='0' />
                                                                    <label>Crossover Rate ($)</label>
                                                                </div>
                                                                <p className="error-message">{errors.CrossoverRate?.message}</p>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                </Row>
                                            </fieldset>
                                        </Col>
                                    </Row>}
                                {serviceRateValue === 1 &&
                                    <Row className="mt-4 mb-3">
                                        <fieldset className="mb-3">
                                            <label className="label-heading">Piece Rate Section</label>
                                            <Row>
                                                <Col sm={4}>
                                                    <div className="fieldset-inner">
                                                        <label className="label-heading">Piece Rate Type</label>
                                                        <div className="floating-checbox" >
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="PieceRateType" {...register("PieceRateType")} onChange={e => radioHandlerSp(1)} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="PieceRateType" {...register("PieceRateType")} onChange={e => radioHandlerSp(2)} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={4}>
                                                    {statusSp === 0 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text >$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Piece Rate" name="PieceRate" {...register('PieceRate', { required: 'Field is required' })} min='0' />
                                                                <label>Piece Rate ($)</label>
                                                            </div>
                                                            <p className="error-message">{errors.PieceRate?.message}</p>
                                                        </div>
                                                    }
                                                    {statusSp === 1 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text >$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Piece Rate" name="PieceRate" {...register('PieceRate', { required: 'Field is required' })} min='0' />
                                                                <label>Piece Rate ($)</label>
                                                            </div>
                                                            <p className="error-message">{errors.PieceRate?.message}</p>
                                                        </div>
                                                    }
                                                    {statusSp === 2 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text>%</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Piece Rate" name="PieceRate" {...register('PieceRate', { required: 'Field is required' })} min='0' />
                                                                <label>Piece Rate (%)</label>
                                                            </div>
                                                            <p className="error-message">{errors.PieceRate?.message}</p>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col sm={4}>
                                                    <div className="form-floating">
                                                        <Form.Control type="number" placeholder="Piece Included" name="PieceInlcuded" {...register('PieceInlcuded', { required: 'Field is required' })} min='0' />
                                                        <label>Piece Included</label>
                                                    </div>
                                                    <p className="error-message">{errors.PieceInlcuded?.message}</p>
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </Row>}
                                {serviceRateValue === 1 &&
                                    <Row className="mt-4 mb-3">
                                        <fieldset className="mb-3">
                                            <label className="label-heading">Weight Rate Section</label>
                                            <Row>
                                                <Col sm={3}>
                                                    <div className="fieldset-inner">
                                                        <label className="label-heading">Weight Rate Type</label>
                                                        <div className="floating-checbox">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="WeightRateType" {...register("WeightRateType")} onChange={e => radioHandlerSw(1)} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="WeightRateType" {...register("WeightRateType")} onChange={e => radioHandlerSw(2)} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={3}>
                                                    {statusSw === 0 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Weight Rate" name="WeightRate" {...register('WeightRate', { required: 'Field is required' })} min='0' />
                                                                <label>Weight Rate ($)</label>
                                                            </div>
                                                            <p className="error-message">{errors.WeightRate?.message}</p>
                                                        </div>
                                                    }
                                                    {statusSw === 1 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Weight Rate" name="WeightRate" {...register('WeightRate', { required: 'Field is required' })} min='0' />
                                                                <label>Weight Rate ($)</label>
                                                            </div>
                                                            <p className="error-message">{errors.WeightRate?.message}</p>
                                                        </div>
                                                    }
                                                    {statusSw === 2 &&
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text>%</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Weight Rate" name="WeightRate" {...register('WeightRate', { required: 'Field is required' })} min='0' />
                                                                <label>Weight Rate (%)</label>
                                                            </div>
                                                            <p className="error-message">{errors.WeightRate?.message}</p>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col sm={2}>
                                                    <div className="form-floating">
                                                        <Form.Control type="number" placeholder="Weight Included" name="WeightIncluded" {...register('WeightIncluded', { required: 'Field is required' })} required min='0' />
                                                        <label>Weight Included</label>
                                                    </div>
                                                    <p className="error-message">{errors.WeightIncluded?.message}</p>
                                                </Col>
                                                <Col sm={2}>
                                                    <div className="form-floating">
                                                        <Form.Control type="number" placeholder="Weight Range" name="WeightRange"
                                                            {...register('WeightRange', { required: 'Field is required' })} required min='0' />
                                                        <label htmlFor="WeightRange">Weight Range</label>
                                                    </div>
                                                    {errors.WeightRange?.message && (<p className="error-message">{" * " + errors.WeightRange?.message}</p>)}
                                                </Col>
                                                <Col sm={2}>
                                                    <div className="form-floating">
                                                        <Form.Select aria-label="Floating label select example" name="WeightCalcType"  {...register('WeightCalcType', { required: 'Field is required' })}>
                                                            <option>Select</option>
                                                            <option value="Total">Total</option>
                                                            <option value="Average">Average</option>
                                                        </Form.Select>
                                                        <label htmlFor="WeightCalcType">Weight Calc Type</label>
                                                    </div>
                                                    {errors.WeightCalcType?.message && (<p className="error-message">{" * " + errors.WeightCalcType?.message}</p>)}
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </Row>}
                            </>
                            : null}
                        {/* table */}
                        <Row>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>Service Rate Type</th>
                                        <th>Service</th>
                                        <th>Vehicle Type</th>
                                        <th>After Hour Percent</th>
                                        <th>Cost Per KM</th>
                                        <th>Base Rate Type</th>
                                        <th>Base Rate</th>
                                        <th>Crossover Rate Type</th>
                                        <th>Crossover Rate</th>
                                        <th>Piece Rate Type</th>
                                        <th>Piece Rate</th>
                                        <th>Piece included</th>
                                        <th>Weight Rate Type</th>
                                        <th>Weight Rate</th>
                                        <th>Weight Included</th>
                                        <th>Weight Range</th>
                                        <th>Weight Calc Type</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {serviceLists?.length > 0 ? (

                                        serviceLists?.map((data, index) => {
                                            return (
                                                <tr key={data.rateCardServiceID}>
                                                    <td>
                                                        <div className="action_btn">
                                                            {permission.find(event => event.moduleId === 42)?.permissionType?.indexOf('Update') !== -1 ?
                                                                <span className="edit_btn" data-ind={data.RateCardServiceID} onClick={() => EditserviceItem(data.RateCardServiceID)}><Edit /></span>
                                                                : <span className="edit_btn disable" data-ind={data.rateCardServiceID}><Edit /></span>}
                                                            {permission.find(event => event.moduleId === 42)?.permissionType?.indexOf('Delete') !== -1 ?
                                                                <span className="del_btn" onClick={() => handleServiceOpen(data?.RateCardServiceID)}><Delete /></span>
                                                                : <span className="del_btn disable"><Delete /></span>}
                                                            <div>
                                                                <Dialog open={serviceOpen} onClose={handleServiceClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                    <DialogTitle id="alert-dialog-title">
                                                                        Delete Confirmation
                                                                    </DialogTitle>
                                                                    <DialogContent>
                                                                        <DialogContentText id="alert-dialog-description">
                                                                            Are you sure you want to delete this row?
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button className="btn-secondary" onClick={handleServiceClose}>Cancel</Button>
                                                                        <Button className="btn-primary" onClick={() => { deleteServiceCustomerList()}} autoFocus> Confirm</Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{data.RateType}</td>
                                                    <td>{data.ServiceDescription}</td>
                                                    <td>{data.VehicleTypes}</td>
                                                    <td>{data.AfterHourPercent}</td>
                                                    <td>{data.CostPerKM}</td>
                                                    <td>{data.BaseRateType}</td>
                                                    <td>{data.BaseRate ? (data.BaseRateType === 'A' ? (parseFloat(data.BaseRate)).toFixed(2) : data.BaseRateType === 'P'? Math.round(data.BaseRate) :data.BaseRate):""}</td>
                                                    <td>{data.CrossoverRateType}</td>
                                                    <td>{data.CrossoverRate ? (data.CrossoverRateType === 'A' ? (parseFloat(data.CrossoverRate)).toFixed(2) : data.CrossoverRateType === 'P'? Math.round(data.CrossoverRate) :data.CrossoverRate):""}</td>
                                                    <td>{data.PieceRateType}</td>
                                                    <td>{data.PieceRate ? (data.PieceRateType === 'A' ? (parseFloat(data.PieceRate)).toFixed(2) : data.PieceRateType === 'P'? Math.round(data.PieceRate) :data.PieceRate):""}</td>
                                                    <td>{data.PieceInlcuded}</td>
                                                    <td>{data.WeightRateType}</td>
                                                    <td>{data.WeightRate ? (data.WeightRateType === 'A' ? (parseFloat(data.WeightRate)).toFixed(2) : data.WeightRateType === 'P'? Math.round(data.WeightRate) :data.WeightRate):""}</td>
                                                    <td>{data.WeightIncluded}</td>
                                                    <td>{data.WeightRange}</td>
                                                    <td>{data.WeightCalcType}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <p>No data found </p>
                                    )}
                                </tbody>
                            </Table>
                        </Row>
                    </fieldset>
                </Col>
            </Row>

            <ToastContainer />
        </>
    );
}
export default EditServiceSurcharge;