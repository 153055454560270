import Wrapper from "../../Wrapper";
import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NowLater from "./NowLater";
import DriverDetails from "./DriverDetails";
import Routing from "./Routing";
import OrderDetails from "./OrderDetails/OrderDetails";
import Draggable from 'react-draggable';
import DriverList from "../TripDispatch/DriverList";
import { useState } from "react";
import { useGetDivisionListQuery, useLazyGetDriversFromBoardQuery, useLazyGetDriverNumberListQuery, useAddDriverToOpenOrderMutation, useLazyGetAssignedOrdersToDriverQuery, useLazyGetAllSelectedDriverDetailsQuery, useLazyGetAllActiveOpenOrdersQuery } from "../../../features/Dispatch/DispatchSlice";
import { FloatingLabel , Form} from "react-bootstrap";
import OpenOrder from "./OpenOrder"
import DispatchedTo  from "./DispatchedTo"
import OnBoardVehicle from "./OnboardVehicle"
import OrdersTable from "./OrdersTable";
import { Rnd } from "react-rnd";
import OrderDetailsMin from "./OrderDetails/OrderDetailsMin";

const LocalDispatch = () => {
    const [open, setOpen] = useState(false);
    const {data: divisionList} = useGetDivisionListQuery()
    const [divisionId, setDivisionId] = useState()
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [ fetchDrivers, result ] = useLazyGetDriversFromBoardQuery();
    const [getDriverList, driverListResult] = useLazyGetDriverNumberListQuery()
    const [getAssignedOrdersToDriver] = useLazyGetAssignedOrdersToDriverQuery();
    const [addDriverToOpenOrder] = useAddDriverToOpenOrderMutation();
    const [fetchRegionalOrders, ordersResult] = useLazyGetAllActiveOpenOrdersQuery()
    const [selectedDriverOrders, setSelectedDriverOrders] = useState([]);
    const [onBoardOrders, setOnBoardOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [getSelectedDriverDetails] = useLazyGetAllSelectedDriverDetailsQuery();
    const [nowLater, setNowLater] = useState('now');
    const [ordersTable, setOrdersTable] = useState([]);
    const [assignedOrders, setAssignedOrders] = useState([]);
    const [showAssignedOrders, setShowAssignedOrders] = useState(false);    

    useEffect(() => {
        getDriverList();
    }, []);

    const assignDriverToOrder = async (type, ID, orderNumber) => {
        if (!selectedDriver) {
            return;
        }
        let dropOffDriverNumber = null;
        if (type === 'DDrive') {
            dropOffDriverNumber = selectedDriver?.driverNumber;
        }
        await addDriverToOpenOrder({divisionId: divisionId, dropOffDriverNumber: dropOffDriverNumber, orderId: ID, orderNumber: orderNumber, pickUpDriverNumber: selectedDriver?.driverNumber});
        const result = await getSelectedDriverDetails({divisionId: divisionId, driverNumber: selectedDriver?.driverNumber});
        setSelectedDriver(result?.data?.data);
        setSelectedDriverOrders(result?.data?.data?.dispatchedOrderDetails);
        const orders = await getAssignedOrdersToDriver({divisionId: divisionId, driverNumber: selectedDriver?.driverNumber});
        setAssignedOrders(orders.data.data);
    }

    

    return (
        <Wrapper>
            <Container fluid>
                <Row className="">
                    <Col md={7} lg={5} className="mb-2 dispatch-main"><h3>Local Dispatch </h3></Col>
                    <Col md={7} lg={2} className="mt-2">
                    <Form.Group controlId="drivernumber">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select division">
                                    <Form.Select aria-label="Floating label select example" name="divisionId" 
                                        onChange={(e) => {
                                            setDivisionId(e.target.value);
                                            getDriverList(e.target.value)
                                            setSelectedOrder(null)
                                            }
                                        }>
                                        <option value="">Select</option>
                                        {divisionList?.data?.map((data,index) =>(
                                            <option key={index} value={data?.divisionId} >{data?.divisionName}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Form.Group>
                    </Col>
                    <Col md={5} lg={2} className="mb-2"><NowLater nowLater={nowLater} setNowLater={setNowLater} /></Col>
                    <Col md={5} lg={3} className="mb-2"><Routing fetchDrivers={fetchDrivers} divisionId={divisionId} getDriverList={getDriverList} driverListResult={driverListResult}/></Col>
                </Row>
                <Row className="contain-order-flex">
                    <Col className="left-flex">
                    <Draggable>
                    <div className="mt-2">
                                {selectedOrder && <OrderDetailsMin selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} divisionId={divisionId} />}
                                {open && <OrderDetails setSelectedOrder={setSelectedOrder} setOpen={setOpen} />}
                                </div>
                                </Draggable>

                                {showAssignedOrders &&
                                    <Draggable>
                                        <div classname="mt-2">
                                            <OrdersTable 
                                                selectedDriver={selectedDriver}
                                                nowLater={nowLater}
                                                assignedOrders={assignedOrders}
                                                setSelectedOrder={setSelectedOrder}
                                                divisionId={divisionId}
                                                ordersTable={ordersTable}
                                                setShowAssignedOrders={setShowAssignedOrders}
                                            />
                                        </div>
                                    </Draggable>
                                }

                                <Draggable>
                                <div>
                                <OpenOrder 
                                    assignDriverToOrder={assignDriverToOrder} 
                                    setSelectedOrder={setSelectedOrder} 
                                    setOpen={setOpen} 
                                    divisionId={divisionId} 
                                    nowLater={nowLater}
                                    fetchRegionalOrders={fetchRegionalOrders}
                                    ordersResult={ordersResult} />                        
                                </div>
                            </Draggable>
                        <Row>
                                <Col sm={6} className="mt-3">
                                <Rnd default={{x: 10, y: 500,width:600,height: 100}} bounds="window" >
                                    <div>
                                        <DispatchedTo 
                                            setSelectedDriverOrders={setSelectedDriverOrders} 
                                            selectedDriver={selectedDriver} 
                                            selectedDriverOrders={selectedDriverOrders} 
                                            setSelectedOrder={setSelectedOrder} 
                                            setOpen={setOpen} 
                                            divisionId={divisionId}
                                            getSelectedDriverDetails={getSelectedDriverDetails}
                                            setOnBoardOrders={setOnBoardOrders} 
                                            fetchDrivers={fetchDrivers}
                                            fetchRegionalOrders={fetchRegionalOrders} 
                                            setSelectedDriver={setSelectedDriver}
                                            setShowAssignedOrders={setShowAssignedOrders}
                                            setOrdersTable={setOrdersTable} 
                                            assignedOrders={assignedOrders}
                                        />                                       
                                    </div>
                                    </Rnd>
                                </Col>
                                <Col sm={6} className="mt-3">
                                <Rnd default={{x: 10, y: 500,width:600,height: 100}} bounds="window" >
                                    <OnBoardVehicle 
                                        onBoardOrders={onBoardOrders} 
                                        setSelectedOrder={setSelectedOrder} 
                                        setOpen={setOpen} 
                                        divisionId={divisionId}
                                        selectedDriver={selectedDriver}
                                        getSelectedDriverDetails={getSelectedDriverDetails}
                                        fetchDrivers={fetchDrivers}
                                        setOnBoardOrders={setOnBoardOrders}
                                        fetchRegionalOrders={fetchRegionalOrders} 
                                        setSelectedDriver={setSelectedDriver}
                                        setShowAssignedOrders={setShowAssignedOrders}
                                        setOrdersTable={setOrdersTable}
                                        assignedOrders={assignedOrders}  
                                    />
                                    </Rnd>
                                </Col>
                        </Row>
                    </Col>
                    <Draggable className="draggable-width">
                        <Col lg={5} >
                            <DriverDetails selectedDriver={selectedDriver} setSelectedDriver={setSelectedDriver} />
                            <DriverList 
                                fetchDrivers={fetchDrivers} 
                                result={result} 
                                selectedDriver={selectedDriver} 
                                setSelectedDriver={setSelectedDriver} 
                                divisionId={divisionId}
                                getAssignedOrdersToDriver={getAssignedOrdersToDriver}
                                setSelectedDriverOrders={setSelectedDriverOrders}
                                setOnBoardOrders={setOnBoardOrders}
                                getDriverList={getDriverList}
                                setAssignedOrders={setAssignedOrders}
                            />
                        </Col>
                    </Draggable>
                </Row>

            </Container>
        </Wrapper>
    )
}
export default LocalDispatch;