import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../context/CustomerContext";
import { addCityDistance, editCityDistance, deleteCityDistance } from "../../../features/cityDistance/cityDistanceSlice";
import { useGetCountryQuery, useLazyGetStateQuery, useLazyGetCityListQuery } from "../../../features/countryState/countryStateSlice";
import { useCustomData } from "../../Sidebar/Permission";
import CountryProvince from "../../CountryProvince";
import { Typeahead } from 'react-bootstrap-typeahead';


const CityDistance = () => {
  const { country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity, handleProvince, newHandleCountry } = CountryProvince()
  const [permission] = useCustomData()
  const { data: toCountry } = useGetCountryQuery()
  const [toProvince, setToProvince] = useState([]);
  const [fromProvince, setFromProvince] = useState([]);
  const toCity = city;
  const cityList = city?.data.map((a) => { return {name: a.city, id: a.id}});
  const toCityList = cityList;
  const fromCityList = cityList;
  const [toSelection, setToSelection] = useState([]);
  const [fromSelection, setFromSelection] = useState([]);
  //const [toCountryList, setToCountryList] = useState(toCountry);
  const dispatch = useDispatch();
  const { cityDistanceLists,tempDisplay, setTempDisplay } = useCustomer();
  const [open, setOpen] = useState(false);
  const [selectToState, setSelectToState] = useState([]);
  const [selectToCity, setSelectToCity] = useState("Select")
  const [isShow, setIsShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [cityDistance, setCityDistance] = useState({})
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [isDelete, setIsDelete] = useState()
  const [tempidvalue , setTempIdValue] = useState({
    fromprovinceValue : "",
    fromCityValue : ""
  })
  const [tempidvalueTo , setTempIdValueTo] = useState({
    toProvinceValue : "",
    toCityValue : ""
  })
  const handleClickOpen = (data) => {
    setOpen(true);
    setIsDelete(data)
  };

  const getCityName = (id) => { const city = cityList.find(d => d.id == id); return city.name; }

  const getProvinceName = (id) => { return province.data[parseInt(id) - 1].provineSateName }

  // new
  const [countryList, setCountryList] = useState([]);
  const setCountry = async (e) => {
    if (e.target.value !== 'Select') {
        const cList = await newHandleCountry(e.target.value);
        setCountryList(cList.data);
    }
    else {
        setCountryList([]);
    }
  }

  // added on july 25 to replace above function
  const [toCountryList, setToCountryList] = useState([]);
  const [fromCountryList, setFromCountryList] = useState([]);
  const setCountrys = async (e) => {
    if (e.target.value !== 'Select') {
        let cList;
        if (e.target.name === "FromProvince") {
            cList = await newHandleCountry(e.target.value);
            setFromCountryList(cList.data);
        }
        else if (e.target.name === "ToProvince") {
            cList = await newHandleCountry(e.target.value);
            setToCountryList(cList.data);
        }
    }
    else {
        if (e.target.name === "FromProvince") {
            setFromCountryList([]);
        }
        else {
            setToCountryList([]);
        }
    }
}

  // hook for To City
  useEffect(async () => {
    if (toSelection.length > 0) {
        const pList = await handleProvince(toSelection[0].id);
        setCityDistance(cityDistance => ({ ...cityDistance, ToCity: toSelection[0].id }))
        setToProvince(pList.data);
    }
    else {
        setToProvince([]);
        setCountryList([]);
    }
}, [toSelection])

  // hook for From City
  useEffect(async () => {
    if (fromSelection.length > 0) {
        const pList = await handleProvince(fromSelection[0].id);
        setFromProvince(pList.data);
        setCityDistance(cityDistance => ({ ...cityDistance, FromCity: fromSelection[0].id }))
    }
    else {
        setFromProvince([]);
        setCountryList([]);
    }
}, [fromSelection])

  const [finaldata,setFinalData] = useState([])
  const handleClose = () => { setOpen(false); };


  useEffect(() => {
      if(cityDistanceLists.length > 0){
        let temp_value = {
          id: "",
          FromCountry: "",
          FromProvince: "",
          FromCity: "",
          ToCountry: "",
          ToProvince: "",
          ToCity: "",
          Miles: "",
          TimeInMinutes: ""
      }
        let toCityVal;
        let fromCityVal;
        if (toSelection.length > 0) {
          toCityVal = toSelection[0].name;
        }
        if (fromSelection.length > 0) {
          fromCityVal = fromSelection[0].name;
        }
        cityDistanceLists.map(element => {
          return (
            temp_value = {
              id: element.id,
              FromCountry: element.FromCountry,
              FromProvince: tempidvalue.fromprovinceValue,
              FromCity: fromCityVal || "",
              ToCountry: element.ToCountry,
              ToProvince: tempidvalueTo.toProvinceValue,
              ToCity: toCityVal || "",
              Miles: element.Miles,
              TimeInMinutes:element.TimeInMinutes
          }
          )
      })
        setFinalData([...finaldata , temp_value])
    }

  },[cityDistanceLists])

  const getStateData = toProvince;
  const getToCity = toCity;
  const handletocountry = async (e) => {
    const addressData = e.target.value;
    const selectedState = await getStateData(addressData)
    setSelectToState(selectedState)
  }
  const handleToCity = async (e) => {
    const addressData = e.target.value;
    await getToCity(addressData)
    setSelectToCity("Select")
  }
  const Citydistancehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value
    if (name === "FromProvince") {
      let provinceData = province?.data
      let province_temp = provinceData?.find(d => {
        if (d.provineStateID == value) {
          return d?.provineSateName
        }
      })
      setTempIdValue({...tempidvalue , fromprovinceValue : province_temp.provineSateName })
    }

    if (name === "FromCity") {
      let citiesData = city?.data
      let city_temp = citiesData?.find(d => {
        if (d.id == value) {
          return d?.city
        }
      })
      setTempIdValue({...tempidvalue , fromCityValue : city_temp.city })
    }


    if (name === "ToProvince") {
      let toProvinceData = toProvince?.data
      let province_temp = toProvinceData?.find(d => {
        if (d.provineStateID == value) {
          return d?.provineSateName
        }
      })
      setTempIdValueTo({...tempidvalueTo , toProvinceValue : province_temp.provineSateName })
    }
    if (name === "ToCity") {
      let toCityData = toCity?.data
      let toCity_temp = toCityData?.find(d => {
        if (d.id == value) {
          return d?.city
        }
      })
      setTempIdValueTo({...tempidvalueTo , toCityValue : toCity_temp.city })
    }

    setCityDistance(cityDistance => ({ ...cityDistance, [e.target.name]: e.target.value }))
  }

  // Add and update vehicle list 
  const addCityDistanceList = (e) => {
    e.preventDefault();
    if (Object.keys(cityDistance).length === 0) {
      setIsShow(isShow => !isShow);
    }
    else if (editingTaskIndex === null) {
      setTempDisplay(true)
      dispatch(addCityDistance(cityDistance));
    } else {
      let editingItem = cityDistanceLists[editingTaskIndex];
      editingItem = cityDistance;
      dispatch(editCityDistance(editingItem));
    }
    setCityDistance("");
    setEditingTaskIndex(null);
    setIsUpdate(false);
    setToSelection([]);
    setFromSelection([]);
    // setTempIdValue("")
    // setTempIdValueTo("")
  };

  // Edit vehicle list
  const editCityDistanceList = (id) => {
    let newEditItem = cityDistanceLists.find((data) => {
      return data.id === id
    });
    setIsUpdate(true);
    //setIsShow(isShow => !isShow);
    setIsShow(true);
    setCityDistance(newEditItem);
    setFromSelection(getCityName(newEditItem.FromCity) ? [{ name: getCityName(newEditItem.FromCity), id: newEditItem.FromCity }] : []);
    setToSelection(getCityName(newEditItem.ToCity) ? [{ name: getCityName(newEditItem.ToCity), id: newEditItem.ToCity }] : []);
    setEditingTaskIndex(id);
  }

  const deleteHandler = () => {
    dispatch(deleteCityDistance(isDelete));
    handleClose();
    setFinalData([])
  }

  return (
    <>
      <Row className="mb-4 mt-4">
        <Col sm={12}>
          <fieldset className="mb-3">
            <label className="label-heading">City Distance Table</label>
            <Row className="mb-2 mt-2">
              <Col sm={12}>
                <fieldset className="pb-2">
                  <div className="section_action_btn">
                    {!isUpdate ?
                      <>
                        {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Add') !== -1 ?
                          <div className="add_btn"><button type="button" className="btn" value="addcitydistance" onClick={e => { addCityDistanceList(e); }}><AddCircle /></button></div>
                          : <div className="add_btn disable"><button type="button" className="btn" value="addcitydistance"><AddCircle /></button></div>}
                      </>

                      :
                      <div className="save_btn"><button type="button" className="btn" value="addcitydistance" onClick={e => { addCityDistanceList(e); }}><Save /></button></div>
                    }
                  </div>
                  {isShow ?
                    <>
                      <Row className="mt-2 mb-3">
                      <Col sm={3}>
                          <Form.Group controlId="from">
                            {/* <FloatingLabel controlId="floatingSelectGrid" label="To City">
                              <Form.Select aria-label="Floating label select example" name="ToCity" onChange={e => { Citydistancehandler(e); setSelectToCity(e.target.value) }} value={cityDistance.ToCity || ''} required> */ }
                            <Typeahead 
                              id='cityAutocomplete'
                              labelKey="name"
                              onChange={setFromSelection}
                              options={fromCityList}
                              placeholder="Choose from city..."
                              selected={fromSelection}
                            />
                              {/* </Form.Select>
                            </FloatingLabel> */}
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="Province/State">
                            <FloatingLabel controlId="floatingSelectGrid" label="From Province/State">
                              <Form.Select aria-label="Floating label select example" name="FromProvince" onChange={(e) => { setSelectState(e.target.value); Citydistancehandler(e); handleCity(e); setCountrys(e); }} value={cityDistance.FromProvince || ''} required>

                                <option>Select</option>:
                                <>
                                  {
                                    fromProvince?.data?.map((data, index) => (
                                      <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                    ))

                                  }
                                </>

                              </Form.Select>

                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="country">
                            <FloatingLabel controlId="floatingSelectGrid" label="From Country">
                              <Form.Select aria-label="Floating label select example" name="FromCountry" onChange={e => { handlecountry(e); Citydistancehandler(e) }} value={cityDistance.FromCountry || ''} required>

                                <option>Select</option>:
                                <>
                                  {
                                    fromCountryList?.data?.map((data, index) => (
                                      <option key={index} value={data.countryId}>{data.countryname}</option>
                                    ))

                                  }
                                </>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="Miles">
                            <FloatingLabel controlId="floatingInput" label="Miles" >
                              <Form.Control type="text" placeholder="Miles" name="Miles" onChange={e => Citydistancehandler(e)} value={cityDistance.Miles || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* 2nd Row */}
                      <Row className="mt-2 mb-3">
                        <Col sm={3}>
                          <Form.Group controlId="to">
                            {/* <FloatingLabel controlId="floatingSelectGrid" label="To City">
                              <Form.Select aria-label="Floating label select example" name="ToCity" onChange={e => { Citydistancehandler(e); setSelectToCity(e.target.value) }} value={cityDistance.ToCity || ''} required> */ }
                            <Typeahead 
                              id='cityAutocomplete'
                              labelKey="name"
                              onChange={setToSelection}
                              options={toCityList}
                              placeholder="Choose to city..."
                              selected={toSelection}
                            />
                              {/* </Form.Select>
                            </FloatingLabel> */}
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="Province/State">
                            <FloatingLabel controlId="floatingSelectGrid" label="To Province/State">
                              <Form.Select aria-label="Floating label select example" name="ToProvince" onChange={(e) => { setSelectToState(e.target.value); Citydistancehandler(e); handleToCity(e); setCountry(e); setCountrys(e); }} value={cityDistance.ToProvince || ''} required>

                                <option>Select</option>:
                                <>
                                  {
                                    toProvince?.data?.map((data, index) => (
                                      <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                    ))

                                  }
                                </>

                              </Form.Select>

                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="country">
                            <FloatingLabel controlId="floatingSelectGrid" label="To Country">
                              <Form.Select aria-label="Floating label select example" name="ToCountry" onChange={e => { handletocountry(e); Citydistancehandler(e) }} value={cityDistance.ToCountry || ''} required>

                                <option>Select</option>:
                                <>
                                  {
                                    toCountryList?.data?.map((data, index) => (
                                      <option key={index} value={data.countryId}>{data.countryname}</option>
                                    ))

                                  }
                                </>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="In TimeInMinutes">
                            <FloatingLabel controlId="floatingInput" label="In TimeInMinutes" >
                              <Form.Control type="text" placeholder="In TimeInMinutes" name="TimeInMinutes" onChange={e => Citydistancehandler(e)} value={cityDistance.TimeInMinutes || ''} required />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                    : null}
                  {/* table */}
                  <Row className="mt-3">
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>From Country</th>
                          <th>To Country</th>
                          <th>From Province</th>
                          <th>To Province</th>
                          <th>From City</th>
                          <th>To City</th>
                          <th>Miles</th>
                          <th>TimeInMinutes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cityDistanceLists?.map((data) => (
                          <tr key={data?.id}>
                            <td>
                              <div className="action_btn">
                               {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                                  <span className="edit_btn" onClick={() => editCityDistanceList(data?.id)}><Edit /></span> : <span className="edit_btn disable" ><Edit /></span>} 
                                {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Delete') !== -1 ?
                                  <span className="del_btn" onClick={() => handleClickOpen(data?.id)}><Delete /></span> : <span className="del_btn disable" onClick={() => handleClickOpen(data?.id)}><Delete /></span>}
                                <div>
                                  <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogTitle id="alert-dialog-title">
                                      Delete Confirmation
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to delete this row?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                      <Button className="btn-primary" onClick={() => { deleteHandler() }} autoFocus> Confirm</Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              </div>
                            </td>
                            <td>{data?.FromCountry === "1" ? "CAN" : "USA"}</td>
                            <td>{data?.ToCountry === "1" ? "CAN" : "USA"}</td>
                            <td>{getProvinceName(data?.FromProvince) || ""}</td>
                            <td>{getProvinceName(data?.ToProvince) || ""}</td>
                            <td>{getCityName(data.FromCity) || ""}</td>
                            <td>{getCityName(data?.ToCity) || ""}</td>
                            <td>{data?.Miles}</td>
                            <td>{data?.TimeInMinutes}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                </fieldset>
              </Col>
            </Row>
          </fieldset>
        </Col>
      </Row>
    </>

  );
}
export default CityDistance;